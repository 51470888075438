import { ImageAsset } from '../images/image-asset-model';

export class Album
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            if (json['Thumbnail'] != null) this.Thumbnail = new ImageAsset(json['Thumbnail']);
            if (json['ImageAssets'])
            {
                this.ImageAssets = [];
                json['ImageAssets'].forEach(image => {
                    this.ImageAssets.push(new ImageAsset(image));
                });
            }
            if (json['ChildAlbums'])
            {
                this.ChildAlbums = [];
                json['ChildAlbums'].forEach(album => {
                    this.ChildAlbums.push(new Album(album));
                });
            }

            // Eliminate stuff we don't want
            delete this['Breadcrumbs'];
            delete this['Order'];
        }
    }

    Id: number;
    Name: string;
    Description: string;
    IsPublished: boolean;
    ParentId: number;
    ChildAlbums: Album[];
    IsFolder = false;
    CategoryIds: number[] = [];
    PhotoId = -1;
    PhotoAutoAssigned = false;
    Photo: ImageAsset;
    Thumbnail: ImageAsset;
    PhotosCount: number;
    VideosCount: number;
    ImageAssets: ImageAsset[];
}
