import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/internal/operators';

import { MyApp } from '../common/enumerations';
import { ServiceManagerBase } from '../common/service-manager-base';
import { OrganizationApiService } from './organization-api.service';
import { BroadcastService } from '../../core/services/broadcast.service';
import { ItemsCollection } from '../common/items-collection.model';
import { CachedCollection } from '../common/cached-collection.model';
import { OrganizationListItem } from './organization-list-Item.model';
import { Organization, TimeZone } from './organization.model';
import { MembershipTypeEditModel } from '../../sections/members/api/member-edit.model';
import { MembershipType } from './membership-type-item.model';
import { CategoryItem } from './category-item.model';
import { ImageAsset } from '../images/image-asset-model';
import { OrganizationEditModel } from '../../sections/organization-info/api/organization-edit.model';
import { Location } from '../location/location.model';
import { ImageManagerService } from '../images/image-manager.service';
import { LocationEditModel } from '../../sections/locations/api/location-edit.model';
import { ThemeManagerService } from '../../sections/themes/api/theme-manager.service';
import { OrganizationAdminEditModel, OrganizationCreateModel } from '../../sections/organization-info/api/organization-create.model';
import { OrganizationAdmin } from './organization-admin.model';
import { OrganizationPhotoLimitResult } from '../../sections/organization-info/api/organization-photo-limit.model';

@Injectable({
    providedIn: 'root',
})
export class OrganizationManagerService extends ServiceManagerBase
{
    // Cache Containers
    private _organizationsCollection: CachedCollection;
    private _currentOrganization: Organization;

    constructor(private organizationApiService: OrganizationApiService,
                protected broadcastService: BroadcastService,
                private themeManager: ThemeManagerService,
                private imageManager: ImageManagerService)
    {
        super(broadcastService);
    }

    get currentOrganization(): Observable<Organization>
    {
        if (this._currentOrganization)
        {
            return of(this._currentOrganization);
        }

        return this.organizationApiService.getCurrentOrganization()
            .pipe(
                tap(org => this._currentOrganization = org )
            );
    }

    getCurrentOrganizationImages(): Observable<Organization>
    {
        if (this._currentOrganization == null || this._currentOrganization.Logo != null || this._currentOrganization.Photo != null || this._currentOrganization.WelcomeImage != null) return of(this._currentOrganization);
        return this.organizationApiService.getCurrentOrganizationImages().pipe(map(json =>
        {
            if (json['Logo'] != null) this._currentOrganization.Logo = new ImageAsset(json['Logo']);
            if (json['Photo'] != null) this._currentOrganization.Photo = new ImageAsset(json['Photo']);
            if (json['WelcomeImage'] != null) this._currentOrganization.WelcomeImage = new ImageAsset(json['WelcomeImage']);
            return this._currentOrganization;
        }));
    }

    switchingOrganizations()
    {
        this._currentOrganization = null;
    }

    protected clearCache()
    {
        // Clean-up any cached collections here.
    }

    getOrganizations(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = this.defaultPageSize, bypassCache = false): Observable<ItemsCollection<OrganizationListItem>>
    {
        if (!bypassCache && this._organizationsCollection && !this._organizationsCollection.isExpired)
        {
            return of(this._organizationsCollection.collection);
        }

        return this.organizationApiService.getOrganizations(sortField, sortOrder, pageIndex, pageSize)
            .pipe(
                tap(response => this._organizationsCollection = new CachedCollection(response))
            );
    }

    getTimezonesList(): Observable<TimeZone[]>
    {
        return this.organizationApiService.getTimezonesList();
    }

    createOrganization(model: OrganizationCreateModel)
    {
        return this.organizationApiService.createOrganization(model).pipe(tap(json =>
        {
            this._organizationsCollection.addItem(new Organization(json));
            this.broadcastService.organizationListChanged.next();
        }));
    }

    getOrganizationAdmins(): Observable<OrganizationAdmin[]>
    {
        return this.organizationApiService.getOrganizationAdmins();
    }

    createOrganizationAdmin(model: OrganizationAdminEditModel)
    {
        return this.organizationApiService.createOrganizationAdmin(model);
    }

    updateOrganizationAdmin(model: OrganizationAdminEditModel)
    {
        return this.organizationApiService.updateOrganizationAdmin(model);
    }

    saveOrganization(model: OrganizationEditModel,
                     previousLogo: ImageAsset = null, logoFile: File = null,
                     previousPhoto: ImageAsset = null, photoFile: File = null,
                     previousWelcomeImage: ImageAsset = null, welcomeImageFile: File = null): Observable<Organization>
    {
        return Observable.create(observer =>
        {
            this.updateLogo(previousLogo, logoFile, model.LogoId)
                .pipe(take(1), switchMap(() => this.updatePhoto(previousPhoto, photoFile, model.PhotoId)))
                .pipe(take(1), switchMap(() => this.updateWelcomeImage(previousWelcomeImage, welcomeImageFile, model.WelcomeImageId)))
                .pipe(switchMap(() => this.organizationApiService.updateInfo(model)))
                .subscribe(org =>
                {
                    org.Logo = this._currentOrganization.Logo;
                    org.Photo = this._currentOrganization.Photo;
                    org.WelcomeImage = this._currentOrganization.WelcomeImage;
                    this._currentOrganization = org;
                    observer.next(this._currentOrganization);
                    observer.complete();
                },
                error => observer.error(error));
        });
    }

    updateRestrictedSettings(org: Organization): Observable<boolean>
    {
        return this.organizationApiService.updateRestrictedSettings(org);
    }

    setThemeForCurrentOrganization(themeId: number)
    {
        return this.organizationApiService.setTheme(themeId)
            .pipe(
                switchMap(() =>
                {
                    return this.themeManager.getThemes().pipe(tap(themes =>
                    {
                        this._currentOrganization.Theme = themes.Items.find(t => t.Id === themeId);
                    }));
                }));
    }

    private updateLogo(previousLogo: ImageAsset, logoFile: File, logoId: number): Observable<boolean>
    {
        if (previousLogo != null && logoFile == null && logoId === -1) return this.organizationApiService.deleteLogo().pipe(tap(() => this._currentOrganization.Logo = null));

        if (logoFile == null) return of(true);
        return this.imageManager.uploadFile(logoFile, true)
            .pipe(switchMap(result => this.organizationApiService.setLogo(result.fileOrUrl)
                .pipe(tap(json => this._currentOrganization.Logo = new ImageAsset(json)), map(() => true))));
    }

    private updatePhoto(previousPhoto: ImageAsset, photoFile: File, photoId: number): Observable<boolean>
    {
        if (previousPhoto != null && photoFile == null && photoId === -1) return this.organizationApiService.deletePhoto().pipe(tap(() => this._currentOrganization.Photo = null));

        if (photoFile == null) return of(true);
        return this.imageManager.uploadFile(photoFile, true)
            .pipe(switchMap(result => this.organizationApiService.setPhoto(result.fileOrUrl)
                .pipe(tap(json => this._currentOrganization.Photo = new ImageAsset(json)), map(() => true))));
    }

    private updateWelcomeImage(previousWelcomeImage: ImageAsset, welcomeImageFile: File, welcomeImageId: number): Observable<boolean>
    {
        if (previousWelcomeImage != null && welcomeImageFile == null && welcomeImageId === -1) return this.organizationApiService.deleteWelcomeImage().pipe(tap(() => this._currentOrganization.WelcomeImage = null));

        if (welcomeImageFile == null) return of(true);
        return this.imageManager.uploadFile(welcomeImageFile)
            .pipe(switchMap(result => this.organizationApiService.setWelcomeImage(result.fileOrUrl)
                .pipe(tap(json => this._currentOrganization.WelcomeImage = new ImageAsset(json)), map(() => true))));
    }

    saveMembershipType(model: MembershipTypeEditModel)
    {
        if (model.Id === 0)
        {
            return this.organizationApiService.createMembershipType(model)
                .pipe(
                    map(newMemberType =>
                    {
                        model.Id = newMemberType.Id;
                        const memberType = new MembershipType(model);
                        this._currentOrganization.MembershipTypes.push(memberType);
                        return memberType;
                    }));
        }

        return this.organizationApiService.updateMembershipType(model)
            .pipe(
                map(() =>
                {
                    const memberType: MembershipType = this._currentOrganization.MembershipTypes.find(mt => mt.Id === model.Id);
                    memberType.Name = model.Name;
                    memberType.CanUploadMedia = model.CanUploadMedia;
                    memberType.UploadAlbumId = model.UploadAlbumId;

                    return memberType;
                }));
    }

    deleteMembershipType(id: number)
    {
        return this.organizationApiService.deleteMembershipType(id).pipe(tap(() =>
        {
            this._currentOrganization.MembershipTypes.splice(this._currentOrganization.MembershipTypes.findIndex(mt => mt.Id === id), 1);
        }));
    }

    checkPhotoLimit(sendEmail: boolean): Observable<OrganizationPhotoLimitResult>
    {
        return this.organizationApiService.checkPhotoLimit(sendEmail);
    }

    categoryCreated(category: CategoryItem)
    {
        this._currentOrganization.CategoryItems.push(category);
        this._currentOrganization.CategoryItems.sort((cat1, cat2) => cat1.Name.localeCompare(cat2.Name));
        this._currentOrganization.MembershipTypes.filter(mt => category.MemberTypeIds.find(mtId => mt.Id === mtId) != null)
            .forEach(mt => {
                mt.CategoryItems.push(category);
                mt.CategoryItems.sort((cat1, cat2) => cat1.Name.localeCompare(cat2.Name));
            });
    }

    categoryUpdated(): CategoryItem
    {
        this._currentOrganization.CategoryItems.sort((cat1, cat2) => cat1.Name.localeCompare(cat2.Name));
        // Too many things might have happened with categories and member types. Let's reload
        this._currentOrganization = null;
        // noinspection JSUnusedLocalSymbols
        const _ = this.currentOrganization;
        return null;
    }

    categoryDeleted(category: CategoryItem)
    {
        this._currentOrganization.CategoryItems.splice(this._currentOrganization.CategoryItems.findIndex(ci => ci.Id === category.Id), 1);
        this._currentOrganization.MembershipTypes.filter(mt => category.MemberTypeIds.find(mtId => mt.Id === mtId) != null)
            .forEach(mt =>
            {
                const categoryIndex = mt.CategoryItems.findIndex(ci => ci.Id === category.Id);
                if (categoryIndex !== -1)
                {
                    mt.CategoryItems.splice(categoryIndex, 1);
                }
            });
    }

    locationCreated(location: Location)
    {
        this._currentOrganization.Locations.push(location);
    }

    locationUpdated(model: LocationEditModel): Location
    {
        const location = this._currentOrganization.Locations.find(l => l.Id === model.Id);
        location.updateFromModel(model);
        return location;
    }

    locationDeleted(location: Location)
    {
        this._currentOrganization.Locations.splice(this._currentOrganization.Locations.findIndex(l => l.Id === location.Id), 1);
    }
}
