import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceDetectorModule } from 'ngx-device-detector';


@NgModule({
    imports: [
        CommonModule,
        DeviceDetectorModule.forRoot(),
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
    ]
})
export class CoreModule {}
