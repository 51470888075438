/* tslint:disable:member-ordering */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthManagerService } from '../../shared-api/auth/auth-manager.service';
import { AuthenticatedUser } from '../../shared-api/auth/authenticated-user.model';

@Injectable({
    providedIn: 'root'
})
export class LoginGuardService implements CanActivate, CanActivateChild
{
    private static navElements = [
        { route: 'news', property: 'News' },
        { route: 'events', property: 'Events' },
        { route: 'notifications', property: 'Notifications' },
        { route: 'members', property: 'Members' },
        { route: 'photos', property: 'Photos' },
        { route: 'contacts', property: 'Contacts' },
        { route: 'categories', property: 'Categories' },
        { route: 'organizationInfo', property: 'OrganizationInfo' },
        { route: 'locations', property: 'Locations' },
        { route: 'sponsors', property: 'Sponsors' },
        { route: 'reports', property: 'Reports' },
    ];

    constructor(private router: Router,
                private authManager: AuthManagerService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.checkLogin(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.canActivate(route, state);
    }

    private checkLogin(url: string): boolean
    {
        if (!this.authManager.authenticatedUser || !this.authManager.authenticatedUser.isLoggedIn()) {
            this.router.navigate(['/account/sign-in']);
            return false;
        }

        if (url.indexOf('/dashboard') === 0)
        {
            const route = url.split('/')[2];
            if (!LoginGuardService.isNavAccessible(this.authManager.authenticatedUser, route))
            {
                this.router.navigate(['/account/sign-in']);
                return false;
            }
        }

        return true;
    }

    private static isNavAccessible(authenticatedUser: AuthenticatedUser, route: string): boolean
    {
        if (authenticatedUser.accessInfo == null) return true;
        const navElement = LoginGuardService.navElements.find(ne => ne.route === route);
        return navElement == null || authenticatedUser.accessInfo[navElement.property] === true;
    }

    static firstAvailableTab(authenticatedUser: AuthenticatedUser): string
    {
        if (authenticatedUser.accessInfo == null) return LoginGuardService.navElements[0].route;

        for (const navElement of LoginGuardService.navElements)
        {
            if (LoginGuardService.isNavAccessible(authenticatedUser, navElement.route)) return navElement.route;
        }

        return null;
    }
}
