
export class ThemeColors
{
    navbar = '';
    icon = '';
    accent = '';
    mainLight = '';
    mainDark = '';
    mutedButton = '';
    attention = '';
    overlay = '';
    lightText = '';
    mediumText = '';
    darkText = '';
    lightBG = '';
    mediumBG = '';
    darkBG = '';

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }
    }

    static descriptionForColor(color: string): string
    {
        if (color === 'navbar') return 'Navigation bar at the top of the program.';
        if (color === 'mainLight') return 'Sub-tabs (Events/Contacts) unselected color.';
        if (color === 'mainDark') return 'Sub-tabs (Events/Contacts) selected color.';
        if (color === 'accent') return 'General color for theming app elements.'; // separating news items, event calendar colors, etc.
        if (color === 'icon') return 'Bottom bar selected icon color';
        if (color === 'mutedButton') return 'RSVP for Event background color.';
        if (color === 'attention') return 'Special buttons and messages like Logout button.';
        if (color === 'overlay') return 'Semi-transparent overlay on Logged out and Welcome screens.';
        if (color === 'lightText') return 'Light text for dark/colored backgrounds (photos caption, unselected icons, sub-tab text, etc.)';
        if (color === 'mediumText') return 'Secondary text color (Event end time, News time posted)';
        if (color === 'darkText') return 'Primary text color for light backgrounds (many places).';
        if (color === 'lightBG') return 'Light background color (Events calendar, More screen items).';
        if (color === 'mediumBG') return 'Medium background color used on most screens as distant background.';
        if (color === 'darkBG') return 'Dark background color (Photo info area, Event calendar and date headers, etc.)';
        return '';
    }
}
