import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Folder } from '../../shared-api/folders/folder.model';
import { PdfAsset } from '../../shared-api/pdfs/pdf-asset-model';
import { Organization } from '../../shared-api/organization/organization.model';
import { finalize } from 'rxjs/operators';
import { FolderManagerService } from '../../shared-api/folders/folder-manager.service';
import { FolderBreadcrumb } from '../../shared-api/folders/folder-breadcrumb.model';

@Component({
    selector: 'myapp-select-folder-or-pdf',
    templateUrl: './select-folder-or-pdf.component.html',
    styleUrls: [ './select-folder-or-pdf.component.less' ]
})
export class SelectFolderOrPdfComponent implements OnInit
{
    @Input() selectFolderMode = true;

    @Output() folderSelected = new EventEmitter<Folder>();
    @Output() pdfSelected = new EventEmitter<PdfAsset>();

    busy = false;
    folder: Folder;
    selectedFolder: Folder;
    selectedPdf: PdfAsset;
    visible = true;

    constructor(private folderManager: FolderManagerService) { }

    ngOnInit()
    {
        this.loadFolder(0);
    }

    onHide()
    {
        if (this.selectFolderMode) { this.folderSelected.emit(null); }
        else { this.pdfSelected.emit(null); }
    }

    onDone()
    {
        if (this.selectFolderMode) { this.folderSelected.emit(this.selectedFolder); }
        else { this.pdfSelected.emit(this.selectedPdf); }
    }

    onBreadcrumbSelected(folderId: number)
    {
        // See if "Home" was selected
        if (folderId === this.folder.Id && this.folder.ParentId == null)
        {
            this.onFolderSelected(this.folder);
            return;
        }
        this.loadFolder(folderId);
    }

    onFolderDoubleClicked(folder: Folder): void
    {
        this.loadFolder(folder.Id);
    }

    onFolderSelected(folder: Folder): void
    {
        if (!this.selectFolderMode) return;

        this.folder.Folders.forEach(f => f.Selected = false);
        this.selectedFolder = folder;
        folder.Selected = true;
    }

    onPdfSelected(pdf: PdfAsset): void
    {
        if (this.selectFolderMode) return;

        this.folder.Pdfs.forEach(f => f.Selected = false);
        this.selectedPdf = pdf;
        pdf.Selected = true;
    }

    private loadFolder(id: number): void
    {
        this.selectedFolder = null;
        this.selectedPdf = null;
        this.busy = true;
        this.folderManager.getFolder(id).pipe(finalize(() => this.busy = false)).subscribe(folder => this.folder = folder);
    }
}
