import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';

import { ApiService, HttpMethod } from '../common/api.service';
import { ApiUrlService } from '../common/api-url.service';
import { AuthenticatedUser } from './authenticated-user.model';
import { LocalStorageManagerService } from '../../core/managers/local-storage-manager.service';
import { UserLoginResult } from './user-login-result.model';
import { UserRegistrationInfo } from './user-registration-info.model';
import { UserLoginInfo } from './user-login-info.model';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService extends ApiService
{
    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService,
                private localCacheManager: LocalStorageManagerService)
    {
        super(http);
    }

    signIn(email: string, password: string): Observable<AuthenticatedUser>
    {
        const userLoginInfo = new UserLoginInfo(email, password);
        return this.apiRequest<AuthenticatedUser>(this.apiUrlService.loginUrl, HttpMethod.Post, JSON.stringify(userLoginInfo))
            .pipe(
                tap(response => this.localCacheManager.userLoginResult = new UserLoginResult(response)),
                map(response => new AuthenticatedUser(response))
            );
    }

    signInWithRefreshToken(refreshToken: string, organizationId?: number)
    {
        let httpBody = `{ "grantType": "refresh_token", "refreshToken": "${refreshToken}", "LocationId": 1 }`;
        if (organizationId)
        {
            httpBody = `{ "grantType": "refresh_token", "RefreshToken": "${refreshToken}", "organizationId": ${organizationId} }`;
        }

        return this.apiRequest<AuthenticatedUser>(
            this.apiUrlService.loginUrl, HttpMethod.Post, httpBody)
            .pipe(
                map(response =>
                {
                    return new AuthenticatedUser(response);
                })
            );
    }

    changePassword(oldPassword: string, newPassword: string)
    {
        return this.apiRequest(
            this.apiUrlService.changePasswordUrl, HttpMethod.Post, `{ "oldPassword": "${oldPassword}", "newPassword": "${newPassword}" }`);
    }

    forgotPassword(userName: string)
    {
        return this.apiRequest(this.apiUrlService.forgotPasswordUrl, HttpMethod.Post, `{ "username": "${userName}" }`);
    }

    resetPassword(userId: string, token: string, password: string)
    {
        const httpBody = `{ "userId": "${userId}", "token": "${token}", "password": "${password}" }`;
        return this.apiRequest(this.apiUrlService.resetPasswordUrl, HttpMethod.Post, httpBody);
    }

    registerNewUser(userRegistrationInfo: UserRegistrationInfo)
    {
        return this.apiRequest(this.apiUrlService.registerUserUrl, HttpMethod.Post, JSON.stringify(userRegistrationInfo));
    }

    confirmEmail(confirmInfo: { userId: string, token: string })
    {
        return this.apiRequest(this.apiUrlService.confirmEmailUrl, HttpMethod.Post, JSON.stringify(confirmInfo));
    }

    confirmUserEmail(email: string): Observable<any>
    {
        return this.apiRequest(this.apiUrlService.confirmUserEmailUrl, HttpMethod.Post, JSON.stringify({ email }));
    }

    setUserPassword(email: string, password): Observable<any>
    {
        return this.apiRequest(this.apiUrlService.setUserPasswordUrl, HttpMethod.Post, JSON.stringify({ email, password }));
    }
}
