export class SelectItemOption
{
    constructor(label: string, value: any, extraInfo = null)
    {
        this.label = label;
        this.value = value;
        this.extraInfo = extraInfo;
    }

    label: string;
    value: any;
    extraInfo: any;
}
