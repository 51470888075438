import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { StartComponent } from './start/start.component';

const appRoutes: Routes = [
    { path: '', pathMatch: 'full', component: StartComponent },
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            // { preloadingStrategy: PreloadAllModules }
            // { enableTracing: true } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
