import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { CoreUiRoutingModule } from './core-ui-routing.module';
import { SectionsModule } from '../sections/sections.module';

import { DashboardComponent } from './dashboard/dashboard.component';

import { DocumentViewerComponent } from './dashboard/document-viewer/document-viewer.component';
import { PrivacyComponent } from './dashboard/legal/privacy/privacy.component';
import { TermsComponent } from './dashboard/legal/terms/terms.component';
import { FaqComponent } from './dashboard/legal/faq/faq.component';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MenubarModule } from 'primeng/menubar';
import { DropdownModule, MultiSelectModule, TooltipModule } from 'primeng/primeng';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        CoreUiRoutingModule,
        SectionsModule,

        ButtonModule,
        DialogModule,
        MenubarModule,
        DropdownModule,
        MultiSelectModule,
        TooltipModule,
    ],
    declarations: [
        DashboardComponent,
        DocumentViewerComponent,
        PrivacyComponent,
        TermsComponent,
        FaqComponent,
    ],
    exports: [
    ],
    providers: [
    ]
})
export class CoreUiModule {}
