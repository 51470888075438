import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { MyApp } from '../shared-api/common/enumerations';
import { BroadcastService } from '../core/services/broadcast.service';
import { Observable} from 'rxjs';
import { Constants } from '../shared-api/common/constants';
import { ICanComponentDeactivate } from './sections-edit-guard.service';
import { TenantManagerService } from '../shared-api/tenant/tenant-manager.service';
import { untilDestroyed } from 'ngx-take-until-destroy';


@Component({
    selector: 'myapp-sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.less']
})
export class SectionsComponent implements OnInit, OnDestroy
{
    @ViewChild('sectionDetail', {static: false}) sectionDetail: ElementRef;

    // Constants
    AppSection = MyApp.AppSection;

    // Member Vars
    appSection = MyApp.AppSection.Unknown;
    sectionName = '';
    sectionDetailData: any;
    showRefreshButton = false;
    isRefreshingData = false;
    isDetailPaneDisplayed = false;
    isDualPane = false;
    isRightPaneOnly = false;

    detailsComponentBusy = false;
    membersLabel = 'Members';
    orgType = 'Organization';

    private currentDetailsComponent?: ICanComponentDeactivate;


    constructor(private route: ActivatedRoute,
                private broadcastService: BroadcastService,
                private tenantManager: TenantManagerService)
    {
    }

    ngOnInit()
    {
        this.route.data.subscribe(data =>
        {
            this.appSection = data.AppSection;
            this.sectionName = this.getSectionName();
            this.currentDetailsComponent = null;
        });

        this.broadcastService.dataRefreshComplete.pipe(untilDestroyed(this)).subscribe(section =>
        {
            if (section !== this.appSection) { return; }

            // Ensure the refresh animation has some time to animate.
            setTimeout(() => this.isRefreshingData, 500);
        });

        this.broadcastService.organizationChanged.pipe(untilDestroyed(this)).subscribe(() =>
        {
            if (this.isDetailPaneDisplayed) { this.finishClose(); }
        });

        this.broadcastService.tryShowSectionDetailPane.pipe(untilDestroyed(this)).subscribe(sectionInfo =>
        {
            if (!this.currentDetailsComponent || !this.currentDetailsComponent.canDeactivate)
            {
                if (this.sectionDetail != null) { this.sectionDetail.nativeElement.scrollTop = 0; }
                this.broadcastService.showSectionDetailPane.next(sectionInfo);
                return;
            }

            this.currentDetailsComponent.canDeactivate()
                .subscribe((canNavigate: boolean) =>
                {
                    if (canNavigate)
                    {
                        if (this.sectionDetail != null) { this.sectionDetail.nativeElement.scrollTop = 0; }
                        this.broadcastService.showSectionDetailPane.next(sectionInfo);
                    }
                });
        });

        this.broadcastService.showSectionDetailPane.pipe(untilDestroyed(this)).subscribe(sectionInfo =>
        {
            if (sectionInfo.section !== this.appSection) { return; }
            this.sectionDetailData = sectionInfo.data;
            this.setDetailDisplay(window.innerWidth);
        });

        this.broadcastService.windowResize.pipe(untilDestroyed(this)).subscribe((sizes: any) =>
        {
            if (!this.isDetailPaneDisplayed) { return; }

            this.setDetailDisplay(sizes.width);
        });

        this.broadcastService.componentDetail.pipe(untilDestroyed(this)).subscribe(component => this.currentDetailsComponent = component);
        this.broadcastService.closeDetailPaneRequested.pipe(untilDestroyed(this)).subscribe(() => this.finishClose());
        this.broadcastService.showDetailPaneBusy.pipe(untilDestroyed(this)).subscribe((busy: boolean) =>
        {
            this.detailsComponentBusy = busy;
        });

        this.tenantManager.currentProduct.pipe(untilDestroyed(this)).subscribe(product =>
        {
            this.membersLabel = product.getFeatureValue('MemberType') || 'Members';
            this.orgType = product.getFeatureValue('OrganizationType') || 'Organization';
        });
    }

    ngOnDestroy()
    {
    }

    onRefreshData()
    {
        this.isRefreshingData = true;
        this.broadcastService.dataRefreshRequest.next(this.appSection);
    }

    onCloseDetailPane()
    {
        if (!this.currentDetailsComponent || !this.currentDetailsComponent.canDeactivate) { this.finishClose(); }

        this.currentDetailsComponent.canDeactivate()
            .subscribe((shouldClose: boolean) =>
            {
                if (shouldClose)
                {
                    this.finishClose();
                }
            });
    }

    private finishClose()
    {
        this.isDualPane = false;
        this.isRightPaneOnly = false;
        this.isDetailPaneDisplayed = false;
        this.currentDetailsComponent = null;

        this.broadcastService.closedDetailPane.next(this.sectionDetailData);
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean
    {
        if (!this.currentDetailsComponent || !this.currentDetailsComponent.canDeactivate) { return true; }
        return this.currentDetailsComponent.canDeactivate();
    }

    // Helper Methods

    private getSectionName()
    {
        switch (this.appSection)
        {
            case MyApp.AppSection.News:
                this.showRefreshButton = true;
                return 'News';
            case MyApp.AppSection.Events:
                return 'Events';
            case MyApp.AppSection.Notifications:
                return 'Notifications';
            case MyApp.AppSection.Members:
                return this.membersLabel;
            case MyApp.AppSection.Albums:
                return 'Photos';
            case MyApp.AppSection.Contacts:
                return 'Contacts';
            case MyApp.AppSection.Categories:
                return 'Categories';
            case MyApp.AppSection.OrganizationInfo:
                return this.orgType + ' Info';
            case MyApp.AppSection.Locations:
                return 'Locations';
            case MyApp.AppSection.Sponsors:
                return 'Sponsors';
            case MyApp.AppSection.Themes:
                return 'Themes';
            default:
                return 'Ah Snap! Something went wrong.';
        }
    }

    private setDetailDisplay(width: number)
    {
        this.isDualPane = width >= Constants.MinWidthForDualPaneEdit;
        this.isRightPaneOnly = !this.isDualPane;
        this.isDetailPaneDisplayed = true;
    }
}
