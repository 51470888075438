import { Injectable } from '@angular/core';
import { ApiEndpointInfo } from './api-endpoint-info.model';
import { MyApp } from './enumerations';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import UserActionType = MyApp.UserActionType;

@Injectable({
    providedIn: 'root'
})
export class ApiUrlService
{
    readonly defaultPageSize = 1000;

    defaultEndpointIndex = environment.defaultApiEndpoint;

    apiEndpoints: ApiEndpointInfo[] = [
        new ApiEndpointInfo(MyApp.Environment.Prod, 'https://apiv2.my1218app.com'),
        new ApiEndpointInfo(MyApp.Environment.Staging, 'https://apiv2staging.my1218app.com'),
        new ApiEndpointInfo(MyApp.Environment.Dev, 'https://apiv2test.my1218app.com'),
        new ApiEndpointInfo(MyApp.Environment.Test, 'https://apiv2test.my1218app.com'),
        new ApiEndpointInfo(MyApp.Environment.MacDev, 'https://localhost:5001')
    ];

    constructor() { }

    baseUrl = this.apiEndpoints.find( e => e.environment === this.defaultEndpointIndex).baseUrl;

    // =========================================================================================================================================================
    // Base URLs
    // =========================================================================================================================================================
    private get baseApiUrl(): string { return this.baseUrl + '/api/'; }
    private get baseAuthApiUrl(): string { return `${this.baseApiUrl}auth`; }
    private get baseTenantsApiUrl(): string { return `${this.baseApiUrl}tenants`; }

    // =========================================================================================================================================================
    // Authorization
    // =========================================================================================================================================================
    get loginUrl(): string { return `${this.baseAuthApiUrl}/token`; }
    get changePasswordUrl(): string { return `${this.baseAuthApiUrl}/changepassword`; }
    get forgotPasswordUrl(): string { return `${this.baseAuthApiUrl}/forgotpassword`; }
    get registerUserUrl(): string { return `${this.baseAuthApiUrl}/register`; }
    get confirmEmailUrl(): string { return `${this.baseAuthApiUrl}/confirm`; }
    get confirmUserEmailUrl(): string { return `${this.baseAuthApiUrl}/confirmUserEmail`; }
    get setUserPasswordUrl(): string { return `${this.baseAuthApiUrl}/setUserPassword`; }
    get resetPasswordUrl(): string { return `${this.baseAuthApiUrl}/resetpassword`; }

    // =========================================================================================================================================================
    // Albums
    // =========================================================================================================================================================
    private get baseAlbumsApiUrl(): string { return `${this.baseApiUrl}albums`; }
    getAlbumsUrl(id: number): string { return `${this.baseAlbumsApiUrl}/${id}`; }
    createAlbumUrl() { return this.baseAlbumsApiUrl; }
    updateOrDeleteAlbumUrl(id: number) { return `${this.baseAlbumsApiUrl}/${id}`; }
    setOrDeleteAlbumImageUrl(id: number) { return `${this.baseAlbumsApiUrl}/${id}/thumbnail`; }
    moveAlbumUrl() { return `${this.baseAlbumsApiUrl}/moveAlbum`; }
    addMediaToAlbumUrl(id: number) { return `${this.baseAlbumsApiUrl}/${id}/addMedia`; }
    deleteMediaFromAlbumUrl(id: number) { return `${this.baseAlbumsApiUrl}/${id}/deleteMedia`; }
    moveMediaTolbumUrl() { return `${this.baseAlbumsApiUrl}/moveMedia`; }

    // =========================================================================================================================================================
    // Categories
    // =========================================================================================================================================================
    private get baseCategoriesApiUrl(): string { return `${this.baseApiUrl}organizations/categories`; }
    createCategoryUrl() { return this.baseCategoriesApiUrl; }
    updateOrDeleteCategoryUrl(id: number) { return `${this.baseCategoriesApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // Contacts
    // =========================================================================================================================================================
    private get baseContactsApiUrl(): string { return `${this.baseApiUrl}contacts`; }
    getContactUrl(id: number): string { return `${this.baseContactsApiUrl}/${id}`; }
    createContactUrl() { return this.baseContactsApiUrl; }
    updateOrDeleteContactUrl(id: number) { return `${this.baseContactsApiUrl}/${id}`; }
    get updateContactOrderUrl(): string { return `${this.baseContactsApiUrl}/updateOrder`; }
    setOrDeleteContactImageUrl(id: number) { return `${this.baseContactsApiUrl}/${id}/photo`; }

    // =========================================================================================================================================================
    // Departments
    // =========================================================================================================================================================
    private get baseDepartmentsApiUrl(): string { return `${this.baseApiUrl}departments`; }
    getDepartmentsUrl(includeContacts: boolean): string { return `${this.baseDepartmentsApiUrl}?includeContacts=${includeContacts}`; }
    getDepartmentUrl(id: number): string { return `${this.baseDepartmentsApiUrl}/${id}`; }
    createDepartmentUrl() { return this.baseDepartmentsApiUrl; }
    get updateDepartmentOrderUrl(): string { return `${this.baseDepartmentsApiUrl}/updateOrder`; }
    updateOrDeleteDepartmentUrl(id: number) { return `${this.baseDepartmentsApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // Events
    // =========================================================================================================================================================
    private get baseEventsApiUrl(): string { return `${this.baseApiUrl}events`; }
    getEventsUrl(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = this.defaultPageSize): string
    {
        return `${this.baseEventsApiUrl}?${this.getQueryParams('postdate', pageIndex, pageSize, sortField, sortOrder)}`;
    }
    getEventUrl(id: number) { return `${this.baseEventsApiUrl}/${id}`; }
    createEventUrl() { return this.baseEventsApiUrl; }
    updateOrDeleteEventUrl(id: number) { return `${this.baseEventsApiUrl}/${id}`; }
    setOrDeleteEventImageUrl(id: number) { return `${this.baseEventsApiUrl}/${id}/image`; }
    setOrDeleteEventPdfUrl(id: number) { return `${this.baseEventsApiUrl}/${id}/pdf`; }

    // =========================================================================================================================================================
    // Folders
    // =========================================================================================================================================================
    private get baseFoldersApiUrl(): string { return `${this.baseApiUrl}folders`; }
    getFolderUrl(id: number): string { return `${this.baseFoldersApiUrl}/${id}?includeBreadcrumbs=true`; }
    get createFolderUrl(): string { return `${this.baseFoldersApiUrl}`; }
    updateFolderUrl(id: number): string { return `${this.baseFoldersApiUrl}/${id}`; }
    get addPdfToFolderUrl(): string { return `${this.baseFoldersApiUrl}/addPdf`; }
    get deleteItemsUrl(): string { return `${this.baseFoldersApiUrl}/deleteItems`; }
    get moveItemsUrl(): string { return `${this.baseFoldersApiUrl}/moveItems`; }

    // =========================================================================================================================================================
    // Images
    // =========================================================================================================================================================
    private get baseImagesApiUrl(): string { return `${this.baseApiUrl}images`; }
    getSasTokenUrl(filename: string): string { return `${this.baseImagesApiUrl}/sas/${filename}`; }
    // getImageWithSizeUrl(imageId: number, width: number, height: number): string { return `${this.baseImagesApiUrl}/${imageId}/${width}/${height}/filename`; }
    getImageUrlWithSizeUrl(imageId: number, width: number, height: number): string { return `${this.baseImagesApiUrl}/url/${imageId}/${width}/${height}/filename`; }
    updateImageCaption(imageId: number): string { return `${this.baseImagesApiUrl}/${imageId}/updateCaption`; }

    // =========================================================================================================================================================
    // Locations
    // =========================================================================================================================================================
    private get baseLocationsApiUrl(): string { return `${this.baseApiUrl}locations`; }
    getLocationUrl(id: number) { return `${this.baseLocationsApiUrl}/${id}`; }
    createLocationUrl() { return this.baseLocationsApiUrl; }
    updateOrDeleteLocationUrl(id: number) { return `${this.baseLocationsApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // Members
    // =========================================================================================================================================================
    private get baseMembersApiUrl(): string { return `${this.baseApiUrl}members`; }
    getMembersUrl(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = this.defaultPageSize): string
    {
        return `${this.baseMembersApiUrl}/directory?${this.getQueryParams('postdate', pageIndex, pageSize, sortField, sortOrder)}`;
    }
    getMemberUrl(id: number) { return `${this.baseMembersApiUrl}/member/${id}`; }
    createMemberUrl() { return this.baseMembersApiUrl; }
    updateMemberFullMemberInfoUrl(id: number) { return `${this.baseMembersApiUrl}/${id}`; }
    updateBasicMemberInfoUrl(id: number) { return `${this.baseMembersApiUrl}/basic/${id}`; }
    deleteMemberUrl(id: number) { return `${this.baseMembersApiUrl}/${id}`; }
    get deleteMembersUrl() { return `${this.baseMembersApiUrl}/deleteMembers`; }
    setOrDeleteMemberImageUrl(id: number) { return `${this.baseMembersApiUrl}/${id}/image`; }
    uploadMemberListUrl(overwriteExistingMembers: boolean) { return `${this.baseMembersApiUrl}/upload?overwriteExistingMembers=${overwriteExistingMembers}`; }
    get downloadMemberListUrl() { return `${this.baseMembersApiUrl}/downloadMemberList`; }
    getMemberCountForCategoriesUrl() { return `${this.baseMembersApiUrl}/memberCountsForCategories`; }
    getConversationUrl(id: number) { return `${this.baseMembersApiUrl}/conversation/${id}`; }
    get sendSmsToMemberUrl() { return `${this.baseMembersApiUrl}/conversation/sendMessage`; }

    // =========================================================================================================================================================
    // Membership Types
    // =========================================================================================================================================================
    private get baseMembershipTypesApiUrl(): string { return `${this.baseOrganizationsApiUrl}/memberTypes`; }
    createMembershipTypeUrl() { return this.baseMembershipTypesApiUrl; }
    updateOrDeleteMembershipTypeUrl(id: number) { return `${this.baseMembershipTypesApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // News
    // =========================================================================================================================================================
    private get baseNewsApiUrl(): string { return `${this.baseApiUrl}news`; }
    getNewsUrl(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = this.defaultPageSize): string
    {
        return `${this.baseNewsApiUrl}?${this.getQueryParams('postdate', pageIndex, pageSize, sortField, sortOrder)}`;
    }
    getNewsItemUrl(id: number) { return `${this.baseNewsApiUrl}/${id}`; }
    createNewsUrl() { return this.baseNewsApiUrl; }
    updateOrDeleteNewsUrl(id: number) { return `${this.baseNewsApiUrl}/${id}`; }
    setOrDeleteNewsImageUrl(id: number) { return `${this.baseNewsApiUrl}/${id}/image`; }
    setOrDeleteNewsPdfUrl(id: number) { return `${this.baseNewsApiUrl}/${id}/pdf`; }

    // =========================================================================================================================================================
    // Notifications
    // =========================================================================================================================================================
    private get baseNotificationsApiUrl(): string { return `${this.baseApiUrl}notifications`; }
    getNotificationsUrl() { return this.baseNotificationsApiUrl; }
    getNotificationUrl(id: number) { return `${this.baseNotificationsApiUrl}/${id}`; }
    createNotificationUrl() { return this.baseNotificationsApiUrl; }
    updateOrDeleteNotificationUrl(id: number) { return `${this.baseNotificationsApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // Organizations
    // =========================================================================================================================================================
    private get baseOrganizationsApiUrl(): string { return `${this.baseApiUrl}organizations`; }
    getOrganizationsUrl(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = this.defaultPageSize): string
    {
        return `${this.baseOrganizationsApiUrl}?${this.getQueryParams('name', pageIndex, pageSize, sortField, sortOrder)}`;
    }
    currentOrganizationUrl(): string { return `${this.baseOrganizationsApiUrl}/current`; }
    currentOrganizationImagesUrl(): string { return `${this.baseOrganizationsApiUrl}/current/images`; }
    getTimezonesUrl(): string { return `${this.baseOrganizationsApiUrl}/timezones`; }
    get createOrganizationUrl(): string { return `${this.baseOrganizationsApiUrl}/create`; }
    get getOrganizationAdminsUrl(): string { return `${this.baseOrganizationsApiUrl}/admins`; }
    get createOrganizationAdminUrl(): string { return `${this.baseOrganizationsApiUrl}/createAdmin`; }
    get updateOrganizationAdminUrl(): string { return `${this.baseOrganizationsApiUrl}/updateAdmin`; }
    updateOrganizationInfoUrl(): string { return this.baseOrganizationsApiUrl; }
    updateOrganizationRestrictedSettingsUrl(): string { return `${this.baseOrganizationsApiUrl}/restricted`; }
    updateOrganizationLogoUrl(): string { return `${this.baseOrganizationsApiUrl}/logo`; }
    updateOrganizationPhotoUrl(): string { return `${this.baseOrganizationsApiUrl}/photo`; }
    updateOrganizationWelcomeImageUrl(): string { return `${this.baseOrganizationsApiUrl}/welcomeImage`; }
    updateOrganizationThemeUrl(): string { return `${this.baseOrganizationsApiUrl}/theme`; }
    checkPhotoLimitUrl(sendEmail: boolean): string { return `${this.baseOrganizationsApiUrl}/checkPhotoLimit?sendEmail=${sendEmail ? 'true' : 'false'}`; }

    // =========================================================================================================================================================
    // Reports
    // =========================================================================================================================================================
    private get baseReportsApiUrl(): string { return `${this.baseApiUrl}reports`; }
    getUserLoginReportUrl(startDate: Date, endDate: Date)
    {
        return `${this.baseReportsApiUrl}/userLoginReport?${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    getRegisteredUserReportUrl(startDate: Date, endDate: Date)
    {
        return `${this.baseReportsApiUrl}/registeredUserReport?${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    getUserActionReportUrl(startDate: Date, endDate: Date, actionType: UserActionType, additionalDataId: number)
    {
        return `${this.baseReportsApiUrl}/userActionReport?actionType=${actionType}&additionalDataId=${additionalDataId === 0 ? '' : additionalDataId}` +
            `&${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    getNotificationReportUrl(startDate: Date, endDate: Date)
    {
        return `${this.baseReportsApiUrl}/notificationReport?${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    getPhotosReportUrl(startDate: Date, endDate: Date)
    {
        return `${this.baseReportsApiUrl}/photosReport?${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    getUserLoginCsvUrl(startDate: Date, endDate: Date)
    {
        return `${this.baseReportsApiUrl}/userLoginCsv?${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    getRegisteredUserCsvUrl(startDate: Date, endDate: Date)
    {
        return `${this.baseReportsApiUrl}/registeredUserCsv?${this.getStartAndEndDateParams(startDate, endDate)}`;
    }
    private getStartAndEndDateParams(startDate: Date, endDate: Date): string {
        return `startDate=${startDate == null ? '' : moment(startDate).format('YYYY-MM-DD')}` +
            `&endDate=${endDate == null ? '' : moment(endDate).format('YYYY-MM-DD')}`;
    }

    // =========================================================================================================================================================
    // Sponsors
    // =========================================================================================================================================================
    private get baseSponsorsApiUrl(): string { return `${this.baseApiUrl}sponsors`; }
    getSponsorUrl(id: number): string { return `${this.baseSponsorsApiUrl}/${id}`; }
    createSponsorUrl() { return this.baseSponsorsApiUrl; }
    updateOrDeleteSponsorUrl(id: number) { return `${this.baseSponsorsApiUrl}/${id}`; }
    setOrDeleteSponsorLogoUrl(id: number) { return `${this.baseSponsorsApiUrl}/${id}/logo`; }
    setOrDeleteSponsorOfferImageUrl(id: number) { return `${this.baseSponsorsApiUrl}/${id}/offerImage`; }

    // =========================================================================================================================================================
    // SponsorCategories
    // =========================================================================================================================================================
    private get baseSponsorCategoriesApiUrl(): string { return `${this.baseApiUrl}sponsorCategories`; }
    getSponsorCategoriesUrl(includeContacts: boolean): string { return this.baseSponsorCategoriesApiUrl; }
    getSponsorCategoryUrl(id: number): string { return `${this.baseSponsorCategoriesApiUrl}/${id}`; }
    createSponsorCategoryUrl() { return this.baseSponsorCategoriesApiUrl; }
    updateOrDeleteSponsorCategoryUrl(id: number) { return `${this.baseSponsorCategoriesApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // Themes
    // =========================================================================================================================================================
    private get baseThemesApiUrl(): string { return `${this.baseApiUrl}themes`; }
    getThemesUrl() { return this.baseThemesApiUrl; }
    getThemeUrl(id: number) { return `${this.baseThemesApiUrl}/${id}`; }
    createThemeUrl() { return this.baseThemesApiUrl; }
    updateOrDeleteThemeUrl(id: number) { return `${this.baseThemesApiUrl}/${id}`; }

    // =========================================================================================================================================================
    // Tenants
    // =========================================================================================================================================================
    get getAllTenantsUrl(): string { return `${this.baseTenantsApiUrl}`; }
    get getAllProductsUrl(): string { return `${this.baseTenantsApiUrl}/products`; }
    get getProductUrl(): string { return `${this.baseTenantsApiUrl}/product`; }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================
    private getQueryParams(defaultSortField: string, pageIndex?: number, pageSize?: number, sortField?: string, sortOrder?: MyApp.SortOrder)
    {
        sortField = sortField ? sortField : defaultSortField;

        return `sortField=${sortField}&sortOrder=${this.getSortOrder(sortOrder)}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    private getSortOrder(sortOrder?: MyApp.SortOrder): string
    {
        sortOrder = sortOrder ? sortOrder : MyApp.SortOrder.Ascending;
        return (sortOrder === MyApp.SortOrder.Ascending) ? 'asc' : 'desc';
    }

}
