export class MemberUploadError
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }
    }

    LineNumber: number;
    InputLine: string;
    Error: string;
}

export class MemberUploadResult
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            if (json['UploadErrors'])
            {
                this.UploadErrors = [];
                json['UploadErrors'].forEach(item => this.UploadErrors.push(new MemberUploadError(item)));
            }
        }
    }

    NumMembersCreated: number;
    NumMembersUpdated: boolean;
    UploadErrors: MemberUploadError[] = [];
}
