import { PdfAsset } from '../pdfs/pdf-asset-model';
import { FolderBreadcrumb } from './folder-breadcrumb.model';

export class Folder
{
    Breadcrumbs: FolderBreadcrumb[];
    CategoryIds: number[] = [];
    Date: Date;
    Description: string;
    Folders: Folder[] = [];
    Id: number;
    IsPublished = false;
    Name: string;
    ParentId: number;
    Pdfs: PdfAsset[] = [];
    Selected = false; // For UI selection purposes only

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);

            if (json.Breadcrumbs != null) this.Breadcrumbs = json.Breadcrumbs.map(item => new FolderBreadcrumb(item));
            if (json.Folders != null) this.Folders = json.Folders.map(item => new Folder(item));
            if (json.Pdfs != null) this.Pdfs = json.Pdfs.map(item => new PdfAsset(item));
        }
    }

    get numFoldersSelected(): number { return this.Folders.filter(f => f.Selected).length; }
    get numItemsSelected(): number { return this.Folders.filter(f => f.Selected).length + this.Pdfs.filter(f => f.Selected).length; }
}
