export class Tenant
{
    Id: number;
    Name: string;

    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }
    }
}
