import {
    HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { throwError ,  BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { LocalStorageManagerService } from './core/managers/local-storage-manager.service';
import { AuthManagerService } from './shared-api/auth/auth-manager.service';
import { AuthenticatedUser } from './shared-api/auth/authenticated-user.model';
import { LoggingService } from './common/services/logging.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // It turns out that in an HttpInterceptor, you cannot inject anything that has a dependency on HttpClient into the constructor.
    // So to get around this, you simply need to inject the Injector Angular class into the HttpInterceptor constructor and then use
    // that in a JIT way within the injector.
    // userManager: UserManagerService;

    constructor(private localCacheManager: LocalStorageManagerService,
                private injector: Injector)
    {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>>
    {
        const accessToken = this.localCacheManager.accessToken;
        let copiedRequest = req;

        if ((accessToken && accessToken !== '') && !req.url.toLowerCase().includes('blob.core.windows.net')) {
            copiedRequest = req.clone({headers: req.headers.append('Authorization', `bearer ${accessToken}`)});
        }

        return next.handle(copiedRequest)
            .pipe(
                catchError((error: Response, caught: Observable<any>) =>
                {
                    // noinspection SuspiciousInstanceOfGuard
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            // case 0:
                            //     return;
                            case 400:
                                return this.handle400Error(error);
                            case 401:
                                return this.handle401Error(req, next);
                            case 403:
                                LoggingService.error('User does not have access to requested resource');
                                return throwError(error);
                        }

                        return throwError(caught);
                    }
                    else {
                        return throwError(error);
                    }
                }));

        // return next.handle(req);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler)
    {
        if (!this.isRefreshingToken)
        {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authManager = this.injector.get(AuthManagerService);

            return authManager.signInWithRefreshToken()
                .pipe(
                    switchMap((user: AuthenticatedUser) =>
                    {
                        this.tokenSubject.next(user.token);
                        const copiedRequest = req.clone({headers: req.headers.append('Authorization', `bearer ${user.token}`)});

                        this.isRefreshingToken = false;
                        return next.handle(copiedRequest);
                    }),
                    catchError((error: any) =>
                    {
                        if (error instanceof HttpErrorResponse && (<HttpErrorResponse>error).status === 400) {
                            // If there is an exception calling 'refreshToken', bad news so logout.
                            return this.handle400Error(error);
                        }

                        return throwError(error);
                    })
                );
        }
        else
        {
            return this.tokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(token =>
                    {
                        const copiedRequest = req.clone({ params: req.params.append('Authentication', token) });
                        return next.handle(copiedRequest);
                    })
                );
        }
    }

    handle400Error(error)
    {
        if (error && error.status === 400 && AuthInterceptor.isRefreshTokenInvalid(error)) {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            const authManager = this.injector.get(AuthManagerService);
            authManager.signOut();
        }

        return throwError(error);
    }

    // noinspection TsLint
    private static isRefreshTokenInvalid(error: HttpErrorResponse)
    {
        // State when the refreshToken exists, but is expired. This will be thrown by the server.
        if (error.error && error.error['Error_1'] && error.error['Error_1'][0] && error.error['Error_1'][0].includes('token is expired')) {
            return true;
        }

        // State when the refreshToken does not exist. This will be thrown by userManager.loginWithRefreshToken()
        return error.statusText === 'no refresh token found';
    }

}
