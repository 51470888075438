import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../common/service-manager-base';
import { BroadcastService } from '../../core/services/broadcast.service';
import { Product } from './product.model';
import { Observable, of } from 'rxjs';
import { TenantApiService } from './tenant-api.service';
import { tap } from 'rxjs/operators';
import { Tenant } from './tenant.model';
import { ItemsCollection } from '../common/items-collection.model';

@Injectable({
    providedIn: 'root'
})
export class TenantManagerService extends ServiceManagerBase
{
    private _currentProduct: Product;

    constructor(private tenantApiService: TenantApiService,
                protected broadcastService: BroadcastService)
    {
        super(broadcastService);
    }

    switchingOrganizations()
    {
        this._currentProduct = null;
    }

    protected clearCache()
    {
        if (this.retrievingData) return;
        this._currentProduct = null;
    }

    getAllTenants(): Observable<ItemsCollection<Tenant>>
    {
        return this.tenantApiService.getAllTenants();
    }

    getAllProducts(): Observable<ItemsCollection<Product>>
    {
        return this.tenantApiService.getAllProducts();
    }

    get currentProduct(): Observable<Product>
    {
        if (this._currentProduct)
        {
            return of(this._currentProduct);
        }

        this.retrievingData = true;
        return this.tenantApiService.getProduct().pipe(tap(product =>
            {
                this._currentProduct = product;
                this.retrievingData = false;
            })
        );
    }
}
