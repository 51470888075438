import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '../core/services/broadcast.service';
import { Router } from '@angular/router';
import { AuthManagerService } from '../shared-api/auth/auth-manager.service';

@Component({
    selector: 'myapp-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.less']
})
export class StartComponent implements OnInit
{
    constructor(private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private router: Router)
    {
    }

    ngOnInit()
    {
        this.tryAutoSignIn();
    }

    private tryAutoSignIn()
    {
        const authenticatedUser = this.authManager.authenticatedUser;

        if (!authenticatedUser || !authenticatedUser.isLoggedIn())
        {
            this.router.navigate(['/account/sign-in']);
            return;
        }

        this.router.navigate(['/dashboard']);
    }
}
