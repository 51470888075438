import { AppTeamUtil } from '../../core/app-team-util';

export class OrganizationAdmin
{
    Id: number;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
    AccessInfo: any;
    CreatedOn: Date;
    IsEnabled: boolean;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            this.CreatedOn = AppTeamUtil.convertApiDateStringToDate(json['CreatedOn']);
            this.AccessInfo = json.AccessInfo == null ? null : JSON.parse(json.AccessInfo);
        }
    }

    get FullName(): string { return `${this.FirstName} ${this.LastName}`; }
}
