import { Injectable } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { BroadcastService } from '../core/services/broadcast.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppTeamMessageService {

    private readonly defaultDuration = 3000;

    constructor(private messageService: MessageService,
                private broadcastService: BroadcastService,
                private confirmationService: ConfirmationService)
    { }

    public add(message: Message, durationInSec?: number)
    {
        durationInSec = durationInSec || 3;
        const durationInMs = durationInSec * 1000;

        this.broadcastService.changeGrowlDuration.next(durationInMs);
        setTimeout(() =>
        {
            this.messageService.add(message);

            setTimeout(() => { this.broadcastService.changeGrowlDuration.next(this.defaultDuration); }, durationInMs);
        }, 250);
    }

    public standardDirtyPrompt(): Observable<boolean>
    {
        return Observable.create(observer =>
        {
            this.confirmationService.confirm({
                header: 'Continue?',
                message: `You will lose your changes if you continue. Are you sure you want to continue?<br /><br />Please go back and click SAVE to keep your edits.`,
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: () => { observer.next(true); observer.complete(); },
                reject: () => { observer.next(false); observer.complete(); }
            });
        });
    }

    public standardDeletePrompt(numberOfItems: number = 0): Observable<boolean>
    {
        return Observable.create(observer =>
        {
            const itemText = (numberOfItems > 0 ? `${numberOfItems} items` : 'item');
            this.confirmationService.confirm({
                header: `Delete ${itemText}?`,
                message: `This will be permanent and cannot be undone.<br /><br />Are you sure you want to delete the ${itemText}?`,
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: () => { observer.next(true); observer.complete(); },
                reject: () => { observer.next(false); observer.complete(); }
            });
        });
    }
}
