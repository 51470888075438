import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceManagerBase } from '../common/service-manager-base';
import { BroadcastService } from '../../core/services/broadcast.service';

import { UploadFileResult } from '../images/image-manager.service';
import { Folder } from './folder.model';
import { FolderApiService } from './folder-api.service';
import { PdfAsset } from '../pdfs/pdf-asset-model';
import { FolderEditModel } from './folder-edit.model';

@Injectable({
    providedIn: 'root'
})
export class FolderManagerService extends ServiceManagerBase
{
    constructor(private folderApiService: FolderApiService,
                protected broadcastService: BroadcastService)
    {
        super(broadcastService);
    }

    clearCache()
    {
    }

    // Note: parentAlbumId = 0 for top-level
    getFolder(id: number): Observable<Folder>
    {
        return this.folderApiService.getFolder(id);
    }

    createFolder(parentFolderId: number, folder: FolderEditModel): Observable<Folder>
    {
        return this.folderApiService.createFolder(parentFolderId, folder);
    }

    updateFolder(parentFolderId: number, folder: FolderEditModel): Observable<Folder>
    {
        return this.folderApiService.updateFolder(parentFolderId, folder);
    }

    addPdfToFolder(folder: Folder, uploadResult: UploadFileResult): Observable<PdfAsset>
    {
        return this.folderApiService.addPdfToFolder(folder.Id, uploadResult);
    }

    deleteItems(folder: Folder, foldersToDelete: Folder[], pdfsToDelete: PdfAsset[]): Observable<Folder>
    {
        return this.folderApiService.deleteItems(folder.Id, foldersToDelete.map(f => f.Id), pdfsToDelete.map(p => p.Id));
    }

    moveItems(destinationFolder: Folder, foldersToMove: Folder[], pdfsToMove: PdfAsset[]): Observable<Folder>
    {
        return this.folderApiService.moveItems(destinationFolder.Id, foldersToMove.map(f => f.Id), pdfsToMove.map(p => p.Id));
    }
}
