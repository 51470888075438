import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


export interface ICanComponentDeactivate
{
    canDeactivate: () => Observable<boolean>;
}

@Injectable()
export class ComponentEditGuard implements CanDeactivate<ICanComponentDeactivate>
{
    canDeactivate(component: ICanComponentDeactivate,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        return component.canDeactivate();
    }
}
