import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from './core/services/broadcast.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { untilDestroyed } from 'ngx-take-until-destroy';


@Component({
    selector: 'myapp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy
{
    @Input() showHeader = false;

    growlDuration = 3000;

    constructor(private router: Router,
                private broadcastService: BroadcastService,
                private deviceDetector: DeviceDetectorService) { }

    @HostListener('window:resize')
    onResize()
    {
        // Set up the host listener to listen for resize events to trigger hiding/showing
        // of hamburger menu and responsive design of navigation pane.
        this.broadcastService.windowResize.next({ width: window.innerWidth, height: window.innerHeight } );
    }

    ngOnInit()
    {
        this.setBrowserAndPlatform();

        this.broadcastService.showHeader.pipe(untilDestroyed(this)).subscribe(show => setTimeout(() => this.showHeader = show, 1));

        // this.showLoadingOverlaySubscription = this.broadcastService.showLoadingOverlay
        //     .subscribe(block =>
        //     {
        //         setTimeout(() =>
        //         {
        //             this.displayLoadingOverlay = block;
        //         }, 1);
        //     });

        this.broadcastService.changeGrowlDuration.pipe(untilDestroyed(this)).subscribe(value => this.growlDuration = value);
    }

    ngOnDestroy()
    {
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Helper Methods
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    private setBrowserAndPlatform()
    {
        const el = document.getElementsByTagName('html')[0];

        let attr = document.createAttribute('browser-name');
        attr.value = this.deviceDetector.getDeviceInfo().browser.toLowerCase();
        el.attributes.setNamedItem(attr);

        attr = document.createAttribute('browser-version');
        attr.value = this.deviceDetector.getDeviceInfo().browser_version;
        el.attributes.setNamedItem(attr);

        attr = document.createAttribute('platform');
        attr.value = this.deviceDetector.getDeviceInfo().os;
        el.attributes.setNamedItem(attr);

        attr = document.createAttribute('is-mobile');
        attr.value = this.deviceDetector.isMobile() || this.deviceDetector.isTablet() ? 'true' : 'false';
        el.attributes.setNamedItem(attr);
    }
}
