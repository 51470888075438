import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { SharedUiModule } from '../shared-ui/shared-ui-module';

import { SectionsComponent } from './sections.component';
import { SectionsEditGuard } from './sections-edit-guard.service';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedUiModule,
    ],
    declarations: [
        SectionsComponent,
    ],
    exports: [
        SectionsComponent,
    ],
    providers: [
        SectionsEditGuard,
    ]
})
export class SectionsModule {}
