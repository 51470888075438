import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MyApp } from '../../shared-api/common/enumerations';
import { SectionDetailInfo } from '../../sections/section-detail-info.model';
import { ICanComponentDeactivate } from '../../sections/sections-edit-guard.service';
import { ModifiedItemModel } from '../../shared-ui/modified-item.model';
import { Organization } from '../../shared-api/organization/organization.model';
import { CachedCollection } from '../../shared-api/common/cached-collection.model';

@Injectable({
    providedIn: 'root'
})
export class BroadcastService
{
    // Subjects
    showHeader = new Subject<boolean>();                                                    // Sent to show/hide header component.
    changeGrowlDuration = new Subject<number>();                                            // Sent to change the duration of the PrimeNg growl.
    // showLoadingOverlay = new Subject<boolean>();

    windowResize = new Subject<any>();                                                      // Sent whenever the app Viewport is resized.
    toggleHamburgerMenu = new Subject();                                                    // Sent when 'hamburger menu' icon is pressed during 'mobile form factor' usage.
    organizationChanged = new Subject<Organization>();                                      // Sent when Global Admin selects an Organization from header drop-down.
    organizationListChanged = new Subject();                                                // Sent when organization list is updated

    dataRefreshRequest = new Subject<MyApp.AppSection>();                                   // Sent when refresh button on section header is pressed.
    dataRefreshComplete = new Subject<MyApp.AppSection>();                                  // Sent in response to dataRefreshRequest once appropriate section data has been retrieved.

    showSectionDetailPane = new Subject<SectionDetailInfo>();                               // Sent when Section edit pane should be displayed.
    tryShowSectionDetailPane = new Subject<SectionDetailInfo>();                            // Sent when Section edit pane should be displayed but checks for dirty.
    closedDetailPane = new Subject<any>();                                                  // Sent when detail pane is closed
    closeDetailPaneRequested = new Subject();                                               // Send by a component when it wants to close the detail pane.
    componentDetail = new Subject<ICanComponentDeactivate>();                               // Sent by detail components that want to handle canDeactivate
    showDetailPaneBusy = new Subject<boolean>();                                            // Sent by detail components to show (true) or hide (false) "busy" overlay

    itemCreated = new Subject<ModifiedItemModel>();                                         // Sent when a new item has been created. ModifiedItemModel.data is the item
    itemUpdated = new Subject<ModifiedItemModel>();                                         // Sent when an item has been updated. ModifiedItemModel.data is the item
    itemDeleted = new Subject<ModifiedItemModel>();                                         // Sent when an item has been deleted. ModifiedItemModel.data is the item
    itemMoved = new Subject<ModifiedItemModel>();                                           // Sent when an item has been moved. ModifiedItemModel.data is the item

    userSignedOut = new Subject<string>();                                                  // Called when user signs out. Allows subscribers to take appropriate action (e.g., clear caches)

    constructor()
    {
    }
}
