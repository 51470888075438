import { MyApp } from '../../../shared-api/common/enumerations';
import NotificationType = MyApp.NotificationType;
import RegistrationFilterType = MyApp.RegistrationFilterType;
import { NotificationSendInfo } from './notification-send-info.model';
import { AppTeamUtil } from '../../../core/app-team-util';

export class NotificationItem
{
    static TitleMaxLength = 150;
    static EmailTextMaxLength = 10000;
    static PushTextMaxLength = 250;
    static SmsTextMaxLength = 160;

    Id = 0;
    ScheduledDate: Date;
    SentDate: Date;
    CategoryIds: number[] = [];
    RegistrationFilter = RegistrationFilterType.All;
    Type = NotificationType.Email;
    Title = '';
    Text = '';
    HtmlText = '';
    MediaUrl: string; // This only has a value if the notification has already been saved (see the PhotoFile property)
    PdfUrl: string; // This only has a value if the notification has already been saved (see the PdfFile property)
    PhotoFile: File; // Contains File to upload - MediaUrl during the save process
    PdfFile: File; // Contains File to upload - PdfUrl during the save process
    Sound: string;
    IsAssociatedWithEvent: boolean;

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            this.ScheduledDate = json.SendInfos.length === 0 ? null : AppTeamUtil.convertApiDateStringToDate(json.SendInfos[0].ScheduledDateTime);
            this.SentDate = json.SendInfos.length === 0 ? null : AppTeamUtil.convertApiDateStringToDate(json.SendInfos[0].SentDateTime);

            // Eliminate stuff we don't want
            delete this['OrganizationId'];
            delete this['SendInfos'];
        }
    }

    get IsPublished(): boolean { return this.SentDate != null; }

    static createCopy(item: NotificationItem)
    {
        const copy = new NotificationItem();
        Object.assign(copy, item);
        return copy;
    }

    static typeFromString(str: string): NotificationType
    {
        return str.toString() === '1' ? NotificationType.Email : str.toString() === '2' ? NotificationType.Push : NotificationType.Sms;
    }
}
