import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlbumSelectDialogComponent } from './album-select-dialog/album-select-dialog.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { EditNotificationComponent } from './edit-notification/edit-notification.component';
import { FillPipe } from './fill.pipe';
import { ImageAssetComponent } from './image-asset/image-asset.component';
import { SelectImagesComponent } from './image-asset/select-images.component';
import { DualPaneComponent } from './dual-pane/dual-pane.component';

import {
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    DataScrollerModule,
    DropdownModule,
    EditorModule,
    InputTextModule,
    MenubarModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    SelectButtonModule,
    TooltipModule,
    TreeModule,
} from 'primeng/primeng';
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import { ChartModule } from 'primeng/chart';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CategoriesSelectDialogComponent } from './categories-select-dialog/categories-select-dialog.component';
import { SelectFolderOrPdfComponent } from './select-folder-or-pdf/select-folder-or-pdf.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        AccordionModule,
        BlockUIModule,
        ChartModule,
        CalendarModule,
        ColorPickerModule,
        DropdownModule,
        DialogModule,
        DragDropModule,
        EditorModule,
        KeyFilterModule,
        ListboxModule,
        FileUploadModule,
        MultiSelectModule,
        PanelModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        TooltipModule,
        TreeModule,
    ],
    declarations: [
        FillPipe,
        ColorPickerComponent,
        DateTimePickerComponent,
        AlbumSelectDialogComponent,
        DualPaneComponent,
        EditNotificationComponent,
        ImageAssetComponent,
        SelectImagesComponent,
        CategoriesSelectDialogComponent,
        SelectFolderOrPdfComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonModule,
        CalendarModule,
        ChartModule,
        CheckboxModule,
        ConfirmDialogModule,
        DataScrollerModule,
        DropdownModule,
        EditorModule,
        InputTextModule,
        MenubarModule,
        MultiSelectModule,
        PanelModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        SelectButtonModule,
        TooltipModule,
        TreeModule,

        AccordionModule,
        BlockUIModule,
        ColorPickerModule,
        DialogModule,
        DragDropModule,
        KeyFilterModule,
        ListboxModule,
        FileUploadModule,
        TabMenuModule,
        TableModule,

        InfiniteScrollModule,

        AlbumSelectDialogComponent,
        CategoriesSelectDialogComponent,
        SelectFolderOrPdfComponent,
        ColorPickerComponent,
        DateTimePickerComponent,
        DualPaneComponent,
        EditNotificationComponent,
        FillPipe,
        ImageAssetComponent,
        SelectImagesComponent,
    ],
    providers: [
        DatePipe,
    ]
})
export class SharedUiModule {}
