import { Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface IDateTimePickerHost
{
    detailPaneClosed();
}
@Component({
    selector: 'myapp-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: [ './date-time-picker.component.less' ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true
        }
    ]
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor
{
    @Input() label = 'Date:&nbsp;';
    @Input() placeholder: string;
    // noinspection TsLint
    @Input('value') _value: Date;
    @Output() dateChanged = new EventEmitter<Date>();
    @ViewChild('calendar', {static: false}) calendar: any;

    private dateBlurred = false;
    private timeBlurred = false;
    private defaultTimeString = '1:00 PM';
    private gotSelectionSinceBlur = false;

    date: Date;
    time: string;
    isDisabled = false;

    availableEventTimes = [];
    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private deviceDetector: DeviceDetectorService) { }

    ngOnInit()
    {
        this.initializeEventTimes();
        if (this.placeholder == null) { this.placeholder = this.label; }
    }

    get value()
    {
        return this._value;
    }

    set value(val)
    {
        this._value = val;
        this.date = val;
        this.time = val == null ? null : moment(val).format('h:mm A');
        this.onChange(val);
        this.onTouched();
    }

    onDateChanged()
    {
        if (this.date === this._value) { return; }
        setTimeout(() => this.calculateAndSendUpdate(false), 300);
    }

    onDateSelected()
    {
        setTimeout(() => this.calculateAndSendUpdate(false), 300);
    }

    onTimeChanged()
    {
        setTimeout(() => this.calculateAndSendUpdate(false));
    }

    private calculateAndSendUpdate(setInternalOnly: boolean)
    {
        const previousWasNull = this._value == null;
        this.calculateDate(setInternalOnly);
        if (this._value == null && previousWasNull) { return; }
        this.onChange(this._value);
        this.dateChanged.emit(this._value);
    }

    private calculateDate(setInternalOnly: boolean)
    {
        if (this.date == null)
        {
            if (setInternalOnly) { this._value = null; }
            else { this.value = null; }
            return;
        }

        const time = moment(this.time == null ? this.defaultTimeString : this.time, 'h:mm A');
        const dateTime = moment(moment(this.date).format('MM-DD-YYYY') + time.format(' HH:mm'), 'MM-DD-YYYY HH:mm');
        if (setInternalOnly)
        {
            this._value = dateTime.toDate();
            this.date = this._value;
            this.time = time.format('h:mm A');
        }
        else { this.value = dateTime.toDate(); }
    }

    private initializeEventTimes()
    {
        const startOfDay = moment().startOf('day').add(-30, 'minutes');
        for (let iHalfHour = 0; iHalfHour < 48; iHalfHour++)
        {
            const timeString = startOfDay.add(30, 'minutes').format('h:mm A');
            this.availableEventTimes.push({ label: timeString, value: timeString });
        }
    }

    registerOnChange(fn: any): void
    {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void
    {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void
    {
        this.isDisabled = isDisabled;
    }

    writeValue(value: any): void
    {
        this.value = value;
    }

    @HostListener('window:keydown', ['$event'])
    keyboardInput(event: any)
    {
        if (this.calendar.inputfieldViewChild.nativeElement === event.target && event.key === 'Enter')
        {
            this.calendar.overlayVisible = false;
            this.onDateChanged();
        }
    }
}
