
export class ItemsCollection<T>
{
    constructor(count: number, items: T[])
    {
        this.Count = count;
        this.Items = items;
    }

    public Count: number;
    public Items: T[];
}
