import { CategoryItem } from './category-item.model';

export class MembershipType
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            this.CategoryItems = [];
            if (json['CategoryItems'])
            {
                json['CategoryItems'].forEach(item => {
                    this.CategoryItems.push(new CategoryItem(item));
                });
                this.CategoryItems.sort((ci1: CategoryItem, ci2: CategoryItem) => ci1.Name.localeCompare(ci2.Name));
            }
        }
    }

    Id: number;
    Name: string;
    CanUploadMedia = false;
    UploadAlbumId: number;
    CategoryItems: CategoryItem[];
}
