import { MyApp } from './enumerations';

export class ApiEndpointInfo
{
    environment: MyApp.Environment;
    baseUrl: string;

    constructor(environment: MyApp.Environment, baseUrl: string)
    {
        this.environment = environment;
        this.baseUrl = baseUrl;
    }
}
