export class ImageAssetItem
{
    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Handle any custom mappings here
        }
    }

    Id: number;
    Uri: string;
    Width: number;
    Height: number;
    IsOriginal = false;
}
