import { LocationHour } from './location-hour.model';
import { LocationEditModel } from '../../sections/locations/api/location-edit.model';

export class Location
{
    Id: number;
    Name: string;
    StreetAddress: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
    Longitude: number;
    Latitude: number;
    LocationText: string;
    PhoneNumber: string;
    IsMainLocation: boolean;
    HideInApp: boolean;
    LocationHours: LocationHour[] = [];

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            this.LocationHours = [];
            if (json['LocationHours'])
            {
                json.LocationHours.forEach(hour => {
                    this.LocationHours.push(new LocationHour(hour));
                });
            }
        }
    }

    updateFromModel(model: LocationEditModel)
    {
        Object.assign(this, model);
    }
}
