import { Injectable } from '@angular/core';
import { ImageApiService } from './image-api.service';
import { Observable, of, throwError } from 'rxjs';
import * as loadImage from '../../../../node_modules/blueimp-load-image/js/index.js';
import { SasTokenModel } from './sas-token.model';
import heic2any from 'heic2any';

export class UploadFileResult
{
    constructor(fileOrUrl: any, width: number, height: number)
    {
        this.fileOrUrl = fileOrUrl;
        this.width = width;
        this.height = height;
    }

    fileOrUrl: any;
    width: number;
    height: number;
}

export class ImageSizeResult
{
    constructor(width: number, height: number)
    {
        this.width = width;
        this.height = height;
    }
    width: number;
    height: number;
}

@Injectable({
    providedIn: 'root'
})
export class ImageManagerService
{
    private static MAX_IMAGE_SIZE = 2000;
    private static MAX_IMAGE_SIZE_REDUCED = 750;

    constructor(private imageApiService: ImageApiService)
    {}

    public static resetOrientation(srcBase64, srcOrientation, callback)
    {
        const img = new Image();

        img.onload = function ()
        {
            const width = img.width;
            const height = img.height;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // set proper canvas dimensions before transform & export
            if (4 < srcOrientation && srcOrientation < 9)
            {
                // noinspection JSSuspiciousNameCombination
                canvas.width = height;
                // noinspection JSSuspiciousNameCombination
                canvas.height = width;
            }
            else
            {
                canvas.width = width;
                canvas.height = height;
            }

            // transform context before drawing image
            switch (srcOrientation)
            {
                case 2:
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break;
                case 3:
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break;
                case 4:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                case 5:
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                case 6:
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                case 7:
                    ctx.transform(0, -1, -1, 0, height, width);
                    break;
                case 8:
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                default:
                    break;
            }

            // draw image
            ctx.drawImage(img, 0, 0);

            // export base64
            callback(canvas.toDataURL());
        };

        img.src = srcBase64;
    }

    private static dataURLToBlob(dataURL)
    {
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) === -1)
        {
            const parts2 = dataURL.split(',');
            const contentType2 = parts2[ 0 ].split(':')[ 1 ];
            const raw2 = parts2[ 1 ];

            return new Blob([ raw2 ], { type: contentType2 });
        }

        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[ 0 ].split(':')[ 1 ];
        const raw = window.atob(parts[ 1 ]);
        const rawLength = raw.length;

        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i)
        {
            uInt8Array[ i ] = raw.charCodeAt(i);
        }

        return new Blob([ uInt8Array ], { type: contentType });
    }

    public uploadFile(file: File, isReduced = false): Observable<UploadFileResult>
    {
        if (file == null) return of(new UploadFileResult(null, 0, 0));

        const cleanFileName = file.name.toLowerCase().replace(/ /g, '_');
        return Observable.create(observer =>
        {
            return this.getSasTokenUrl(cleanFileName).subscribe((model: SasTokenModel) =>
            {
                if (model == null || model.Token == null) return throwError('Unable to get upload URL.');
                let uploadResult;
                this.uploadFileWithSasToken(file, model.Token, isReduced).subscribe(result => uploadResult = result, error => observer.error(error), () =>
                {
                    observer.next(new UploadFileResult(model.Token, uploadResult.width, uploadResult.height));
                    observer.complete();
                });
            }, error => observer.error(error));
        });
    }

    private getSasTokenUrl(filename: string)
    {
        return this.imageApiService.getSasTokenUrl(filename);
    }

    private uploadFileWithSasToken(file: File, sasToken: string, isReduced = false): Observable<UploadFileResult>
    {
        return Observable.create(observer =>
        {
            this.getResizedFile(file, isReduced)
                .subscribe(uploadFileResult =>
                {
                    this.imageApiService.uploadFile(uploadFileResult.fileOrUrl, sasToken)
                        .subscribe(null,
                            error => observer.error(error),
                            () =>
                            {
                                observer.next(uploadFileResult);
                                observer.complete();
                            }
                        );
                }, error => observer.error(error)
                );
        });
    }

    downloadImageFromUrl(url: string)
    {
        return this.imageApiService.downloadFile(url);
    }

    getImageWithSize(imageId: number, width: number, height: number)
    {
        return this.imageApiService.getImageWithSize(imageId, width, height);
    }

    updateCaption(id: number, caption: string)
    {
        return this.imageApiService.updateCaption(id, caption);
    }

    checkImageSize(file: File): Observable<ImageSizeResult>
    {
        return Observable.create(observer =>
        {
            const img = new Image();

            img.onload = function ()
            {
                const width = img.width;
                const height = img.height;
                observer.next(new ImageSizeResult(width, height));
                observer.complete();
            };

            img.src = window.URL.createObjectURL(file);
        });
    }

    private getResizedFile(file: File, isReduced = false): Observable<UploadFileResult>
    {
        if (!file.type.match(/image.*/)) return of(new UploadFileResult(file, 0, 0));

        return Observable.create(async observer =>
        {
            if (file.type === 'image/heic')
            {
                const blobUrl = URL.createObjectURL(file);
                const blobRes = await fetch(blobUrl);
                const blob = await blobRes.blob();
                const jpgBlob = await heic2any({ blob, toType: 'image/jpeg', quality: 0.7 });
                const blobToFile: any = jpgBlob;
                blobToFile.lastModifiedDate = new Date();
                blobToFile.name = 'resized';
                file = <File>blobToFile;
            }
            loadImage.parseMetaData(file, function (data)
            {
                // const orientation = data && data.exif ? data.exif.get('Orientation') : 1;
                loadImage(file, function (canvas)
                    {
                        const dataUrl = canvas.toDataURL(file.type, 0.7);

                        // Seems like trying to rotate images using orientation no longer works...leaving previous code commented out.

                        // if (orientation === 1)
                        // {
                        const resizedImage = ImageManagerService.dataURLToBlob(dataUrl);
                        observer.next(new UploadFileResult(resizedImage, canvas.width, canvas.height));
                        observer.complete();
                        return;
                        // }
                        //
                        // ImageManagerService.resetOrientation(dataUrl, orientation, function(fixedDataUrl)
                        // {
                        //     const resizedImage = ImageManagerService.dataURLToBlob(fixedDataUrl);
                        //     observer.next(new UploadFileResult(resizedImage, canvas.width, canvas.height));
                        //     observer.complete();
                        // });
                    },
                    {
                        maxWidth: isReduced ? ImageManagerService.MAX_IMAGE_SIZE_REDUCED : ImageManagerService.MAX_IMAGE_SIZE,
                        maxHeight: isReduced ? ImageManagerService.MAX_IMAGE_SIZE_REDUCED : ImageManagerService.MAX_IMAGE_SIZE,
                        orientation: 1,
                        canvas: true
                    }
                );
            });
        });
    }
}
