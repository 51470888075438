import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable} from 'rxjs';
import { Constants } from './constants';
import { timeout } from 'rxjs/internal/operators';

export enum HttpMethod
{
    Get,
    Post,
    Put,
    Patch,
    Delete
}

@Injectable({
    providedIn: 'root'
})
export class ApiService
{
    public static defaultPageSize = Constants.defaultDataPageSize;

    private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(protected http: HttpClient)
    {
    }

    public static getMessage(error: HttpErrorResponse): string
    {
        if (error)
        {
            if (error.error && Object.keys(error.error).length > 0)
            {
                const keys = Object.keys(error.error);
                return error.error[ keys[ 0 ] ];
            }

            if (error.message) return error.message;
        }

        return 'Unknown error';
    }

    static isValidUploadFilename(filename: string): boolean
    {
        const forbiddenChars = /^[^\\/:\*\?"<>\|\^]+$/;
        return forbiddenChars.test(filename);
    }

    apiRequest<T>(url: string, httpMethod: HttpMethod, httpBody?: string): Observable<T>
    {
        return this.http.request<T>(HttpMethod[httpMethod], url, { headers: this.headers, body: httpBody });
    }

    apiGetRequestForBlob<T>(url: string): Observable<T>
    {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').append('Accept', 'image/jpeg');
        return this.http.get<T>(url, { headers: headers, responseType: 'blob' as 'json' })
            .pipe(
                timeout(15000));
    }

    apiGetRequestForCsv<T>(url: string): Observable<T>
    {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').append('Accept', 'text/csv');
        return this.http.get<T>(url, { headers: headers, responseType: 'blob' as 'json' })
            .pipe(
                timeout(15000));
    }

    apiUploadRequest<T>(url: string, formData: FormData, timeoutInSecs: number)
    {
        return this.http.post<T>(url, formData)
            .pipe(
                timeout(timeoutInSecs * 1000));
    }

    apiRequestWithProgress(url: string, httpMethod: HttpMethod, httpBody?: string): Observable<any>
    {
        const req = new HttpRequest(HttpMethod[httpMethod], url, httpBody, { headers: this.headers, reportProgress: true });
        return this.http.request(req);
    }
}
