export class NavbarElement
{
    lookupName: string;
    displayName: string;
    routerLink: string;
    icon: string;
    isOnMobileMenu = false;
    queryParams: {};
    hidden = false;

    constructor(lookupName: string, displayName: string, icon: string, routerLink: string, isOnMobileMenu = false, queryParams = null)
    {
        this.lookupName = lookupName;
        this.displayName = displayName;
        this.icon = icon;
        this.routerLink = routerLink;
        this.isOnMobileMenu = isOnMobileMenu;
        this.queryParams = queryParams;
    }
}
