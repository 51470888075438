import { Component, Input, OnInit } from '@angular/core';
import { MyApp } from '../../../shared-api/common/enumerations';

@Component({
    selector: 'myapp-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.less']
})
export class DocumentViewerComponent implements OnInit
{
    DocumentType = MyApp.DocumentType;

    @Input() documentType: MyApp.DocumentType;

    constructor()
    {
    }

    ngOnInit()
    {

    }

}
