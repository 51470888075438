import { BroadcastService } from '../../core/services/broadcast.service';
import { OnDestroy } from '@angular/core';
import { Constants } from './constants';
import { untilDestroyed } from 'ngx-take-until-destroy';

export abstract class ServiceManagerBase implements OnDestroy
{
    protected defaultPageSize: number = Constants.defaultDataPageSize;
    protected retrievingData = false;

    protected constructor(protected broadcastService: BroadcastService)
    {
        this.broadcastService.userSignedOut.pipe(untilDestroyed(this)).subscribe(() => this.clearCache());
        this.broadcastService.organizationChanged.pipe(untilDestroyed(this)).subscribe(() => this.clearCache());
    }

    ngOnDestroy()
    {
    }

    protected abstract clearCache();
}
