import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../../../shared-api/common/service-manager-base';
import { BroadcastService } from '../../../core/services/broadcast.service';
import { CachedCollection } from '../../../shared-api/common/cached-collection.model';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { ItemsCollection } from '../../../shared-api/common/items-collection.model';
import { ThemeApiService } from './theme-api.service';
import { ThemeEditModel } from './theme-edit.model';
import { NewsItem } from '../../news/api/news-item.model';
import { Theme } from '../../../shared-api/theme/theme.model';

@Injectable({
    providedIn: 'root'
})
export class ThemeManagerService extends ServiceManagerBase
{
    public static NEUTRAL_THEME_BACKGROUND_COLOR = '#B0E0E6';

    public get NEUTRAL_THEME_LIGHT_BACKGROUND_COLOR() { return this.shadeColor(.15, ThemeManagerService.NEUTRAL_THEME_BACKGROUND_COLOR); }
    public get NEUTRAL_THEME_DARK_BACKGROUND_COLOR() { return this.shadeColor(-.30, ThemeManagerService.NEUTRAL_THEME_BACKGROUND_COLOR); }

    private pSBCr: any;

    // Cache Containers
    private _themesCollection: CachedCollection;

    constructor(private themeApiService: ThemeApiService,
                protected broadcastService: BroadcastService)
    {
        super(broadcastService);
    }

    clearCache()
    {
        if (this.retrievingData) { return; }
        this._themesCollection = null;
    }

    getThemes(bypassCache = false): Observable<ItemsCollection<Theme>>
    {
        if (!bypassCache && this._themesCollection && !this._themesCollection.isExpired)
        {
            return of(this._themesCollection.collection);
        }

        this.retrievingData = true;
        return this.themeApiService.getThemes()
            .pipe(
                tap(response =>
                {
                    this._themesCollection = new CachedCollection(response);
                    this.sortList();
                    this.retrievingData = false;
                })
            );
    }

    getTheme(id: number): Theme
    {
        if (!this._themesCollection) { return null; }
        return this._themesCollection.collection.Items.find(t => t.Id === id);
    }

    saveTheme(themeEditModel: ThemeEditModel): Observable<Theme>
    {
        if (themeEditModel.Id === 0)
        {
            return this.themeApiService.createTheme(themeEditModel).pipe(tap(createdItem =>
            {
                if (this._themesCollection == null)
                {
                    this._themesCollection = new CachedCollection(new ItemsCollection<NewsItem>(0, []));
                }
                themeEditModel.Id = createdItem.Id;
                this._themesCollection.addItem(new Theme(createdItem));
                this.sortList();
            }));
        }

        return this.themeApiService.updateTheme(themeEditModel).pipe(tap(updatedItem =>
        {
            const itemIndex = this._themesCollection.collection.Items.findIndex(n => n.Id === themeEditModel.Id);
            this._themesCollection.collection.Items[itemIndex] = new Theme(updatedItem);
            this.sortList();
        }));
    }

    deleteTheme(theme: Theme): Observable<any>
    {
        return this.themeApiService.deleteTheme(theme)
            .pipe(
                tap(() =>
                {
                    if (this._themesCollection != null)
                    {
                        this._themesCollection.removeItem(this._themesCollection.collection.Items.find(n => n.Id === theme.Id));
                        this.sortList();
                    }
                })
            );
    }

    private sortList()
    {
        this._themesCollection.collection.Items.sort((theme1: Theme, theme2: Theme) => theme1.Name.localeCompare(theme2.Name));
    }


    // Examples:
    // c = '#ff0635' OR '#F63' OR 'rgb(20,60,200)' OR 'rgba(20,60,200,0.67423)'
    // p = .40 to lighten by 40 %
    // p = -.40 to darken by 40$
    public shadeColor = (p, c0, c1 = null, l = null) =>
    {
        let r, g, b, P, f, t, h;
        const i = parseInt, m = Math.round;
        let a: any = typeof (c1) === 'string';
        if (typeof (p) !== 'number' || p < -1 || p > 1 || typeof (c0) !== 'string' || (c0[ 0 ] !== 'r' && c0[ 0 ] !== '#') || (c1 && !a)) { return null; }
        if (!this.pSBCr)
        {
            this.pSBCr = (d) =>
            {
                let n = d.length;
                const x: any = {};
                if (n > 9)
                {
                    [ r, g, b, a ] = d = d.split(','), n = d.length;
                    if (n < 3 || n > 4) { return null; }
                    x.r = i(r[ 3 ] === 'a' ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1;
                }
                else
                {
                    if (n === 8 || n === 6 || n < 4) { return null; }
                    if (n < 6) { d = '#' + d[ 1 ] + d[ 1 ] + d[ 2 ] + d[ 2 ] + d[ 3 ] + d[ 3 ] + (n > 4 ? d[ 4 ] + d[ 4 ] : ''); }
                    d = i(d.slice(1), 16);
                    if (n === 9 || n === 5)
                    {
                        // tslint:disable-next-line:no-bitwise
                        x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
                    }
                    else
                    {
                        // tslint:disable-next-line:no-bitwise
                        x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1;
                    }
                }
                return x;
            };
        }
        h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 === 'c' ? !h : false : h, f = this.pSBCr(c0), P = p < 0, t = c1 && c1 !== 'c' ? this.pSBCr(c1) : P ? {
            r: 0,
            g: 0,
            b: 0,
            a: -1
        } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
        if (!f || !t) { return null; }
        if (l)
        {
            r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
        }
        else
        {
            r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
        }
        a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
        if (h)
        {
            return 'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')';
        }
        else
        {
            return '#' + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
        }
    }
}
