import * as moment from 'moment';

export abstract class CachedObject
{
    private readonly cacheDuration = 5;

    constructor()
    {
        this.timestamp = new Date();
    }

    timestamp: Date;

    public get isExpired(): boolean
    {
        return moment(new Date()).diff(moment(this.timestamp), 'minutes') > this.cacheDuration;
    }
}
