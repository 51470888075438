import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ApiService, HttpMethod } from '../common/api.service';
import { ApiUrlService } from '../common/api-url.service';
import { SasTokenModel } from './sas-token.model';
import { catchError, map } from 'rxjs/internal/operators';
import { Observable, throwError } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ImageApiService
{
    protected apiService: ApiService;

    private pendingCalls: any[] = [];
    private MAX_CONCURRENT_IMAGE_REQUESTS = 1;

    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        this.apiService = new ApiService(http);
    }

    getSasTokenUrl(filename: string)
    {
        return this.apiService.apiRequest<SasTokenModel>(this.apiUrlService.getSasTokenUrl(filename), HttpMethod.Get)
            .pipe(
                map(response => new SasTokenModel(response))
            );
    }

    uploadFile(file: File, sasUrl: string)
    {
        const headers = new HttpHeaders()
            .set('x-ms-blob-type', 'BlockBlob')
            .set('x-ms-blob-content-type', file.type)
            .set('Content-Type', file.type);
        const req = new HttpRequest('put', sasUrl, file, { headers: headers, reportProgress: false });
        return this.http.request(req)
            .pipe(
                catchError( err =>
                {
                    let errorMessage = '';
                    if (err.error instanceof ErrorEvent)
                    {
                        errorMessage = `Error: ${err.error.message}`;
                    }
                    else
                    {
                        errorMessage = `Server returned ${err.status} with message ${err.message}`;
                    }
                    return throwError(errorMessage);
                })
            );
    }

    downloadFile(url: string)
    {
        // const headers = new HttpHeaders()
        //     .set('x-ms-blob-type', 'BlockBlob')
        //     .set('Content-Type', 'image/*');
        return this.apiService.apiGetRequestForBlob(url);
        // return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' });
    }

    getImageWithSize(imageId: number, width: number, height: number)
    {
        const pendingCall = { imageId: imageId, width: width, height: height, processing: false, observer: null };
        this.pendingCalls.push(pendingCall);
        return Observable.create(observer =>
        {
            pendingCall.observer = observer;
            if (this.pendingCalls.length > this.MAX_CONCURRENT_IMAGE_REQUESTS) { return; } // We will process later

            this.processPendingCall(pendingCall);
        });
    }

    updateCaption(id: number, caption: string)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateImageCaption(id), HttpMethod.Put, JSON.stringify( {
            Id: id,
            Caption: caption
        }));
    }

    private processPendingCall(pendingCall: any)
    {
        pendingCall.processing = true;
        this.apiService.apiRequest<any>(this.apiUrlService.getImageUrlWithSizeUrl(pendingCall.imageId, pendingCall.width, pendingCall.height), HttpMethod.Get)
            .subscribe(response =>
            {
                this.pendingCalls = this.pendingCalls.filter(call => call.imageId !== pendingCall.imageId || call.width !== pendingCall.width || call.height !== pendingCall.height);
                pendingCall.observer.next(response);
                pendingCall.observer.complete();

                this.checkForPendingCall();
            }, error =>
            {
                this.pendingCalls = this.pendingCalls.filter(call => call.imageId !== pendingCall.imageId || call.width !== pendingCall.width || call.height !== pendingCall.height);
                pendingCall.observer.error(error);
                this.checkForPendingCall();
            });
    }

    private checkForPendingCall()
    {
        // Get the next one that is pending
        const nextCall = this.pendingCalls.find(call => !call.processing);
        if (nextCall != null)
        {
            this.processPendingCall(nextCall);
        }
    }
}
