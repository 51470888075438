import { Injectable } from '@angular/core';
import { ApiService, HttpMethod } from '../common/api.service';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '../common/api-url.service';
import { map } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { Product } from './product.model';
import { ItemsCollection } from '../common/items-collection.model';
import { Tenant } from './tenant.model';

@Injectable({
    providedIn: 'root'
})
export class TenantApiService extends ApiService
{
   constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        super(http);
    }

    getAllTenants(): Observable<ItemsCollection<Tenant>>
    {
        return this.apiRequest(this.apiUrlService.getAllTenantsUrl, HttpMethod.Get).pipe(map((tenantsCollection: ItemsCollection<Tenant>) =>
        {
            const tenantItems = tenantsCollection.Items.map(json => new Tenant(json));
            return new ItemsCollection<Tenant>(tenantsCollection.Count, tenantItems);
        }));
    }

    getAllProducts(): Observable<ItemsCollection<Product>>
    {
        return this.apiRequest(this.apiUrlService.getAllProductsUrl, HttpMethod.Get).pipe(map((productsCollection: ItemsCollection<Product>) =>
        {
            const productItems = productsCollection.Items.map(json => new Product(json));
            return new ItemsCollection<Product>(productsCollection.Count, productItems);
        }));
    }

    getProduct(): Observable<Product>
    {
        return this.apiRequest(this.apiUrlService.getProductUrl, HttpMethod.Get).pipe(map((response: Product) =>
        {
            return new Product(response);
        }));
    }
}
