import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';

export class AppTeamUtil
{
    public static tryParseErrorText(error: HttpErrorResponse): {hasError: boolean, errorText: string}
    {
        let errorText = '';

        if (error.error && error.error.Error_1 && error.error.Error_1[0])
        {
            errorText =  error.error.Error_1[0];
        }

        return { hasError: errorText !== '', errorText: errorText };
    }

    public static getUtcDate(dateString: string)
    {
        if (!dateString) { return null; }

        return new Date(dateString.endsWith('Z') ? dateString : `${dateString}Z`);
    }

    public static sortByName(items: any[])
    {
        return items.sort((a, b) =>
        {
            const aName = a.name || '';
            const bName = b.name || '';
            return aName !== bName ? (aName.toLowerCase() < bName.toLowerCase() ? -1 : 1) : 0;
        });
    }

    public static getParsedFloat(numberString: string)
    {
        const cleanValue = numberString.replace(/[^0-9.]/g, '');
        return parseFloat(cleanValue);
    }

    public static capitalizeWord(word: string)
    {
        if (word.length === 0) { return word; }
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    public static disableLastpass(parentElement: ElementRef)
    {
        const inputElements = parentElement.nativeElement.getElementsByTagName('input');
        for (const element of inputElements)
        {
            element.setAttribute('data-lpignore', 'true');
        }
    }

    public static convertDateToApiDate(date)
    {
        if (date == null) { return null; }

        const newdate = new Date(date);
        const hourTimezoneDifference = -newdate.getTimezoneOffset() / 60;
        newdate.setHours(newdate.getHours() + hourTimezoneDifference);
        return newdate;
    }

    public static convertApiDateToDate(date)
    {
        if (date == null) { return null; }

        const newdate = new Date(date);
        const hourTimezoneDifference = newdate.getTimezoneOffset() / 60;
        newdate.setHours(newdate.getHours() + hourTimezoneDifference);
        return newdate;
    }

    public static convertApiDateStringToDate(dateString: string): Date
    {
        if (dateString == null) { return null; }
        return new Date(dateString.replace(/-/g, '/').replace('T', ' ').replace(/\..*|\+.*/, ''));
    }

    public static groupBy(list, keyGetter): Map<any, any>
    {
        const map = new Map();
        list.forEach((item) =>
        {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection)
            {
                map.set(key, [ item ]);
            }
            else
            {
                collection.push(item);
            }
        });
        return map;
    }
}
