import { JwtHelperService } from '@auth0/angular-jwt';

export class AuthenticatedUser
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Map Pascal Cased property names to camel case property names to support compatibility with existing code.
            this.token = json['Token'];
            this.expiration = json['Expiration'];
            this.refreshToken = json['RefreshToken'];
            this.refreshTokenExpiration = json['RefreshTokenExpiration'];
        }

        // Handle any custom mappings here or use getters and setters. Object.assign will call
        // property setters.
    }

    public token: string;
    public refreshToken: string;

    private _expiration: Date;
    public get expiration(): Date
    {
        return this._expiration;
    }

    public set expiration(value: Date)
    {
        this._expiration = new Date(value);
    }

    private _refreshTokenExpiration: Date;
    public get refreshTokenExpiration(): Date
    {
        return this._refreshTokenExpiration;
    }

    public set refreshTokenExpiration(value: Date)
    {
        this._refreshTokenExpiration = new Date(value);
    }

    private _sid: string;
    public get sid(): string
    {
        if (!this._sid)
        {
            this._sid = this.parseTokenValue('sid');
        }
        return this._sid;
    }

    private _sub: string;
    public get sub(): string
    {
        if (!this._sub)
        {
            this._sub = this.parseTokenValue('sub');
        }
        return this._sub;
    }

    private _firstName: string;
    public get firstName(): string
    {
        if (!this._firstName)
        {
            this._firstName = this.parseTokenValue('given_name');
        }
        return this._firstName;
    }

    private _lastName: string;
    public get lastName(): string
    {
        if (!this._lastName)
        {
            this._lastName = this.parseTokenValue('family_name');
        }
        return this._lastName;
    }

    public get fullName(): string
    {
        return `${this.firstName} ${this.lastName}`;
    }

    private _uniqueName: string;
    public get uniqueName(): string
    {
        if (!this._uniqueName)
        {
            this._uniqueName = this.parseTokenValue('unique_name');
        }
        return this._uniqueName;
    }

    private _email: string;
    public get email(): string
    {
        if (!this._email)
        {
            this._email = this.parseTokenValue('email');
        }
        return this._email;
    }

    private _tenantId: string;
    public get tenantId(): string
    {
        if (!this._tenantId)
        {
            this._tenantId = this.parseTokenValue('TenantId');
        }
        return this._tenantId;
    }

    private _isGlobalAdmin: boolean;
    public get isGlobalAdmin(): boolean
    {
        if (!this._isGlobalAdmin)
        {
            this._isGlobalAdmin = this.parseTokenValue('IsGlobalAdmin').toLowerCase() === 'true';
        }
        return this._isGlobalAdmin;
    }

    private _isOrgAdmin: boolean;
    public get isOrgAdmin(): boolean
    {
        if (!this._isOrgAdmin)
        {
            this._isOrgAdmin = this.parseTokenValue('IsOrgAdmin').toLowerCase() === 'true';
        }
        return this._isOrgAdmin;
    }

    private _isMember: boolean;
    public get isMember(): boolean
    {
        if (!this._isMember)
        {
            this._isMember = this.parseTokenValue('IsMember').toLowerCase() === 'true';
        }
        return this._isMember;
    }

    private _organizationId: string;
    public get organizationId(): string
    {
        if (!this._organizationId)
        {
            this._organizationId = this.parseTokenValue('OrganizationId');
        }
        return this._organizationId;
    }

    private _accessInfo: string;
    public get accessInfo(): any
    {
        if (!this._accessInfo)
        {
            this._accessInfo = this.parseTokenValue('AccessInfo');
            this._accessInfo = this._accessInfo = null || this._accessInfo.length === 0 ? null : JSON.parse(this._accessInfo);
        }
        return this._accessInfo;
    }

    // GlobalAdmin, OrgAdmin, Member
    private _roles: string;
    public get roles(): string
    {
        if (!this._roles)
        {
            this._roles = this.parseTokenValue('roles');
        }
        return this._roles;
    }

    // private _claims;
    // public get claims(): UserClaims
    // {
    //     if (!this._claims)
    //     {
    //         this._claims = new UserClaims(this.decodedToken);
    //     }
    //     return this._claims;
    // }

    public isLoggedIn()
    {
        return this.token && this.token.length > 0 && this.organizationId != null && this.refreshTokenExpiration >= new Date();
    }

    // public canAccessRoute(url: string): boolean
    // {
    //     switch (url)
    //     {
    //         // Dashboard
    //         // case '/dashboard/main':
    //         //     return true;
    //
    //         case '/dashboard/all':
    //             return !this.isJapan ? true : false;
    //
    //         case '/dashboard/inventory':
    //             return this.isOrgAdmin || this.claims.viewOpenProducts;
    //
    //         case '/dashboard/offered':
    //             return this.isOrgAdmin || this.claims.viewOfferedProducts;
    //
    //         case '/dashboard/assigned':
    //             return this.isOrgAdmin || this.claims.viewAssignedProducts;
    //
    //         case '/dashboard/shipment':
    //             return this.isOrgAdmin || this.claims.viewShippedProducts;
    //
    //         case '/dashboard/pending':
    //             return this.isOrgAdmin || this.claims.viewPendingProducts;
    //
    //         case '/dashboard/receivables':
    //             return this.isOrgAdmin || this.claims.viewReceivables;
    //
    //         case '/dashboard/payables':
    //             return this.isOrgAdmin || this.claims.viewPayables;
    //
    //         case '/dashboard/settled':
    //             return this.isOrgAdmin || this.claims.viewSettledProducts;
    //
    //         case '/dashboard/products':
    //             return this.isOrgAdmin || this.claims.canEditProducts;
    //
    //         case '/dashboard/users':
    //             return this.isOrgAdmin || this.claims.canEditUsers;
    //
    //         case '/dashboard/ports':
    //             return this.isOrgAdmin || this.claims.canEditPorts;
    //
    //         case '/dashboard/vessels':
    //             return this.isOrgAdmin || this.claims.canEditVessels;
    //
    //         case '/dashboard/shippers':
    //             return this.isOrgAdmin || this.claims.canEditShippers;
    //
    //         case '/dashboard/packing-plants':
    //             return this.isOrgAdmin ||  this.claims.canEditPackingPlants;
    //
    //         case '/dashboard/reports':
    //             return true;
    //
    //         case '/dashboard/settings':
    //             return true;
    //
    //         case '/dashboard/api-test':
    //             return this.isOrgAdmin;
    //
    //         default:
    //             return false;
    //     }
    // }


    // ======================================================================================================
    // Helper Methods
    // ======================================================================================================
    private get decodedToken(): any
    {
        return this.token ? new JwtHelperService().decodeToken(this.token) : null;
    }

    private parseTokenValue(value: string): string
    {
        return (this.decodedToken && this.decodedToken[value]) ? this.decodedToken[value] : '';
    }

}
