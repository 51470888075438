export class MemberCountWithCategoriesResult
{
    TotalMembers: number;
    MembersMatchingCategories: number;

    constructor(json: object = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
