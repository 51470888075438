import { ThemeColors } from './theme-colors.model';

export class Theme
{
    Id: number;
    Name: string;
    Description: string;
    Colors: ThemeColors;

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            if (json['Values'])
            {
                const values = json['Values'];
                if (values['Colors'] || values['colors']) this.Colors = new ThemeColors(values['Colors'] || values['colors']);

                delete this['Values'];
            }

            delete this['SampleUrls'];
        }
    }
}
