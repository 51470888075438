export class OrganizationListItem
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Handle any custom mappings here

        }
    }

    Id: number;
    Name: string;
    TenantId: string;
}
