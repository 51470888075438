import { CachedObject } from './cached-object.model';
import { ItemsCollection } from './items-collection.model';

export class CachedCollection extends CachedObject
{
    constructor(collection: ItemsCollection<any>)
    {
        super();

        this.collection = collection;
    }

    collection: ItemsCollection<any>;

    addItem(item: any)
    {
        this.collection.Count = this.collection.Count + 1;
        this.collection.Items.unshift(item);
    }

    removeItem(item: any)
    {
        const index = this.collection.Items.indexOf(item);
        if (index === -1) return;
        this.collection.Count = this.collection.Count - 1;
        this.collection.Items.splice(index, 1);
    }
}
