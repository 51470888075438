import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Constants } from '../../shared-api/common/constants';
import { IDualPaneHost } from './dual-pane-host';
import { BroadcastService } from '../../core/services/broadcast.service';
import { AuthManagerService } from '../../shared-api/auth/auth-manager.service';
import { OrganizationManagerService } from '../../shared-api/organization/organization-manager.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'myapp-dual-pane',
    templateUrl: './dual-pane.component.html',
    styleUrls: [ './dual-pane.component.less' ]
})
export class DualPaneComponent implements OnInit, OnDestroy, AfterViewInit
{
    @Input() hostComponent: IDualPaneHost;
    @Input() hostComponentName = '';
    @Input() leftPaneBody: TemplateRef<any>;
    @Input() rightPaneBody: TemplateRef<any>;
    @Input() showRefreshButton = true;
    @Input() showCloseButtonOnRightPane = true;
    @Input() suppressAutoDetail = false;
    @ViewChild('dualPaneContainer', {static: false}) dualPaneContainer: ElementRef;

    isDetailPaneDisplayed = false;
    isWideEnoughForDualPane = true;
    isRightPaneOnly = false;
    rightPaneBusy = false;
    private baseRoute = '';
    private orgId: number;

    constructor(private broadcastService: BroadcastService,
                private router: Router,
                private route: ActivatedRoute,
                private authManager: AuthManagerService,
                private organizationService: OrganizationManagerService) { }

    ngOnInit()
    {
        this.baseRoute = this.route.snapshot.data.BaseRoute;

        // Delay setting up anything until we get an initial organization "ready"
        this.broadcastService.organizationChanged.pipe(untilDestroyed(this)).subscribe(org =>
        {
            if (this.authManager.authenticatedUser.isGlobalAdmin && this.authManager.authenticatedUser.organizationId == null) { return; }
            this.handleOrganizationChanged(org.Id);
        });

        // If this is global admin and there is already an org - we won't get the initial one above
        this.organizationService.currentOrganization.pipe(take(1)).subscribe(org =>
        {
            this.handleOrganizationChanged(org.Id);
        });

        if (!this.suppressAutoDetail)
        {
            // Watch for changes in the "detail" child route on behalf of our host components
            this.router.events.subscribe(event =>
            {
                if (!(event instanceof NavigationEnd)) { return; }
                const navigationEnd = event as NavigationEnd;
                this.handleUrlChange(navigationEnd.url);
            });
        }

        // Handle resize by adjusting the detail pane if it is currently being displayed
        this.broadcastService.windowResize.pipe(untilDestroyed(this)).subscribe(() =>
        {
            if (this.isDetailPaneDisplayed) { this.showDetailPane(); }
        });
    }

    ngOnDestroy(): void
    {
    }

    ngAfterViewInit(): void
    {
    }

    private handleOrganizationChanged(orgId: number)
    {
        const hadExistingOrg = this.orgId != null;

        // Close the details pane if we are switching orgs
        if (this.orgId != null && this.isDetailPaneDisplayed) { this.closeDetailPane(); }

        // Reload data
        this.onRefreshData();
        this.orgId = orgId;

        // Check for detail pane needing to be displayed on behalf of our host components
        if (!hadExistingOrg)
        {
            this.handleUrlChange(this.router.url);
        }
    }

    onRefreshData()
    {
        if (this.hostComponent.refreshData == null) { return; }
        this.hostComponent.refreshData();
    }

    onCloseDetailPane()
    {
        // Make sure we can close it (see if it is "dirty") then close it if we can
        if (!this.hostComponent || !this.hostComponent.canDeactivate) { this.closeDetailPane(); }
        this.hostComponent.canDeactivate().subscribe(deactivate =>
        {
            if (deactivate) { this.closeDetailPane(); }
        });
    }

    showDetailPane()
    {
        const width = this.dualPaneContainer.nativeElement.offsetWidth;

        this.isWideEnoughForDualPane = width >= Constants.MinWidthForDualPaneEdit;
        setTimeout(() =>
        {
            this.isRightPaneOnly = !this.isWideEnoughForDualPane;
            this.isDetailPaneDisplayed = true;
        }, 10);
    }

    closeDetailPane()
    {
        this.isWideEnoughForDualPane = false;
        this.isRightPaneOnly = false;
        this.isDetailPaneDisplayed = false;

        this.hostComponent.detailPaneClosed();
        if (!this.suppressAutoDetail)
        {
            this.router.navigate([this.baseRoute]);
        }
    }

    handleUrlChange(url: string)
    {
        if (!this.isDetailPaneDisplayed && url.toLowerCase().indexOf('detail') !== -1)
        {
            this.showDetailPane();
        }
    }
}
