export class Product
{
    Id: number;
    Name: string;
    OrganizationTypeName: string;
    Description: string;
    FeatureFlags: number;
    FeatureJson: string;

    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Handle any custom mappings here

        }
    }

    getFeatureValue(key: string)
    {
        return JSON.parse(this.FeatureJson)[key];
    }
}
