import { ImageAssetItem } from './image-asset-item.model';
import { ImageAssetUser } from './image-asset-user.model';
import { MyApp } from '../common/enumerations';
import { AppTeamUtil } from '../../core/app-team-util';

export class ImageAsset
{
    private static readonly MAX_PERCENT_DIFF = 0.4;
    private static readonly MIN_IMAGE_SIZE_TO_RETRIEVE = 100;

    AssetType = MyApp.AssetType.Image;
    Caption: string = null;
    CreatedOn: Date;
    Id: number;
    Images: ImageAssetItem[];
    MediaURL: string;
    ThumbnailURL: string;
    User: ImageAssetUser = null;

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Handle any custom mappings here
            if (json['Images'])
            {
                this.Images = [];
                json.Images.forEach(image => {
                    this.Images.push(new ImageAssetItem(image));
                });
            }

            this.CreatedOn = AppTeamUtil.convertApiDateStringToDate(json['CreatedOn']);
        }
    }

    get hasImage(): boolean
    {
        return this.ThumbnailURL != null || (this.Images != null && this.Images.length > 0);
    }

    getUrlForSize(width: number, height: number, useClosest: boolean = false)
    {
        if (this.Images == null || this.Images.length === 0)
        {
            if (this.ThumbnailURL != null)
            {
                return this.encodeUrl(this.ThumbnailURL);
            }
            return null;
        }

        if (width === 0 || height === 0)
        {
            return this.encodeUrl(this.Images.find(i => i.IsOriginal).Uri);
        }

        let minSizeDiff = 999999;
        let closestItem: ImageAssetItem = null;
        this.Images.forEach(function (item)
        {
            const sizeDiff = Math.min(Math.abs(item.Width - width), Math.abs(item.Height - height));
            if (sizeDiff < minSizeDiff)
            {
                closestItem = item;
                minSizeDiff = sizeDiff;
            }
        });

        // Use the closest if any of the following are true:
        //  1. useClosest flag is true
        //  2. We are requesting something larger than the original image
        //  3. The closest image size is within MAX_PERCENT_DIFF of the requested size
        //  4. We are requesting something really small and we already have a reasonably small image
        // noinspection JSObjectNullOrUndefined
        if (useClosest ||
            (closestItem.IsOriginal && (closestItem.Width < width || closestItem.Height < height)) ||
            (Math.abs(closestItem.Width - width) / width) <= ImageAsset.MAX_PERCENT_DIFF ||
            (Math.abs(closestItem.Height - height) / height) <= ImageAsset.MAX_PERCENT_DIFF ||
            ((width < ImageAsset.MIN_IMAGE_SIZE_TO_RETRIEVE || height < ImageAsset.MIN_IMAGE_SIZE_TO_RETRIEVE) &&
                (closestItem.Width < ImageAsset.MIN_IMAGE_SIZE_TO_RETRIEVE * 2 && closestItem.Height <= ImageAsset.MIN_IMAGE_SIZE_TO_RETRIEVE * 2)))
        {
            return this.encodeUrl(closestItem.Uri);
        }

        return null;
    }

    private encodeUrl(url: string): string
    {
        return url.replace(/\(/g, '%28').replace(/\)/g, '%29');
    }
}
