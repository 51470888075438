import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginGuardService } from '../core/services/login-guard.service';

const coreUiRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivateChild: [LoginGuardService],
        children: [
            // { path: '', pathMatch: 'full', redirectTo: 'news' },
            { path: 'news', loadChildren: () => import('../sections/news/news.module').then(m => m.NewsModule), data: { BaseRoute: 'dashboard/news' } },
            { path: 'events', loadChildren: () => import('../sections/events/events.module').then(m => m.EventsModule), data: { BaseRoute: 'dashboard/events' } },
            { path: 'doc-center', loadChildren: () => import('../sections/doc-center/doc-center.module').then(m => m.DocCenterModule), data: { BaseRoute: 'dashboard/doc-center' } },
            { path: 'notifications', loadChildren: () => import('../sections/notifications/notifications.module').then(m => m.NotificationsModule), data: { BaseRoute: 'dashboard/notifications' } },
            { path: 'members', loadChildren: () => import('../sections/members/members.module').then(m => m.MembersModule), data: { BaseRoute: 'dashboard/members' } },
            { path: 'photos', loadChildren: () => import('../sections/albums/albums.module').then(m => m.AlbumsModule), data: { BaseRoute: 'dashboard/photos' } },
            { path: 'contacts', loadChildren: () => import('../sections/contacts/contacts.module').then(m => m.ContactsModule), data: { BaseRoute: 'dashboard/contacts' } },
            { path: 'categories', loadChildren: () => import('../sections/categories/categories.module').then(m => m.CategoriesModule), data: { BaseRoute: 'dashboard/categories' } },
            { path: 'organizationInfo', loadChildren: () => import('../sections/organization-info/organization.module').then(m => m.OrganizationModule), data: { BaseRoute: 'dashboard/organizationInfo' } },
            { path: 'locations', loadChildren: () => import('../sections/locations/locations.module').then(m => m.LocationsModule), data: { BaseRoute: 'dashboard/locations' } },
            { path: 'sponsors', loadChildren: () => import('../sections/sponsors/sponsors.module').then(m => m.SponsorsModule), data: { BaseRoute: 'dashboard/sponsors' } },
            { path: 'themes', loadChildren: () => import('../sections/themes/themes.module').then(m => m.ThemesModule), data: { BaseRoute: 'dashboard/themes' } },
            { path: 'admin', loadChildren: () => import('../sections/admin/admin.module').then(m => m.AdminModule), data: { BaseRoute: 'dashboard/admin' } },
            { path: 'reports', loadChildren: () => import('../sections/reports/reports.module').then(m => m.ReportsModule), data: { BaseRoute: 'dashboard/reports' } },
        ]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forChild(coreUiRoutes)
    ],
    exports: [
        RouterModule,
    ],
    declarations: []
})
export class CoreUiRoutingModule {}
