import { CategoryEditModel } from '../../sections/categories/api/category-edit.model';
import { MyApp } from '../common/enumerations';

export class CategoryItem
{
    Id: number;
    Name: string;
    Description: string;
    CategoryItemType: MyApp.CategoryItemType;
    ShowInApp = true;
    MemberTypeIds: number[] = [];

    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }
    }

    updateFromModel(model: CategoryEditModel)
    {
        this.Name = model.Name;
        this.Description = model.Description;
        this.CategoryItemType = model.CategoryItemType;
        this.ShowInApp = model.ShowInApp;
        this.MemberTypeIds = model.MemberTypeIds;
    }
}
