import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ServiceManagerBase } from '../common/service-manager-base';
import { BroadcastService } from '../../core/services/broadcast.service';
import { MyApp } from '../common/enumerations';

import { Album } from './album.model';
import { AlbumApiService } from './album-api.service';
import { AlbumEditModel } from './album-edit.model';
import { ImageManagerService, UploadFileResult } from '../images/image-manager.service';
import { ImageAsset } from '../images/image-asset-model';

@Injectable({
    providedIn: 'root'
})
export class AlbumManagerService extends ServiceManagerBase
{
    constructor(private albumApiService: AlbumApiService,
                protected broadcastService: BroadcastService,
                private imageManager: ImageManagerService)
    {
        super(broadcastService);
    }

    clearCache()
    {
    }

    // Note: parentAlbumId = 0 for top-level
    getAlbum(id: number): Observable<Album>
    {
        return this.albumApiService.getAlbum(id);
    }

    saveAlbum(albumEditModel: AlbumEditModel, previousThumbnail: ImageAsset, file: File): Observable<Album>
    {
        if (file == null)
        {
            return this.saveAlbumInternal(albumEditModel, previousThumbnail, null);
        }

        // Upload the image first
        const cleanFileName = file.name.toLowerCase().replace(/ /g, '_');

        return Observable.create(observer =>
        {
            return this.imageManager.uploadFile(file).subscribe(result =>
            {
                this.saveAlbumInternal(albumEditModel, previousThumbnail, result.fileOrUrl).subscribe(savedItem =>
                {
                    observer.next(savedItem);
                    observer.complete();
                }, error =>
                {
                    observer.error(error);
                });
            });
        });
    }

    private saveAlbumInternal(albumEditModel: AlbumEditModel, previousThumbnail: ImageAsset, sasToken: string): Observable<Album>
    {
        return Observable.create(observer =>
        {
            if (albumEditModel.Id === 0)
            {
                return this.albumApiService.createAlbum(albumEditModel)
                    .subscribe((createdAlbum: Album) =>
                        {
                            albumEditModel.Id = createdAlbum.Id;
                            this.setImageForAlbum(albumEditModel, previousThumbnail, sasToken)
                                .subscribe((imageAsset: ImageAsset) =>
                                {
                                    createdAlbum.Thumbnail = imageAsset || createdAlbum.Thumbnail;
                                    createdAlbum.PhotoAutoAssigned = createdAlbum.Thumbnail == null;
                                    observer.next(createdAlbum);
                                    observer.complete();
                                }, error =>
                                {
                                    observer.error(error);
                                });
                        }
                    );
            }

            return this.albumApiService.updateAlbum(albumEditModel)
                .subscribe((updatedAlbum: Album) =>
                {
                    this.setImageForAlbum(albumEditModel, previousThumbnail, sasToken)
                        .subscribe((imageAsset: ImageAsset) =>
                        {
                            updatedAlbum.Thumbnail = imageAsset || (albumEditModel.ThumbnailId === -1 ? null : updatedAlbum.Thumbnail);
                            updatedAlbum.Photo  = updatedAlbum.Thumbnail;
                            updatedAlbum.PhotoAutoAssigned = updatedAlbum.Thumbnail == null;
                            observer.next(updatedAlbum);
                            observer.complete();
                        }, error =>
                        {
                            observer.error(error);
                        });
                });
        });
    }

    addMediaToAlbum(album: Album, type: MyApp.AssetType, uploadResult: UploadFileResult)
    {
        return this.albumApiService.addMediaToAlbum(album.Id, type, uploadResult);
    }

    deleteMediaFromAlbum(album: Album, imageAssetIds: number[])
    {
        return this.albumApiService.deleteMediaFromAlbum(album.Id, imageAssetIds);
    }

    moveAlbum(fromParentId: number, fromChildAlbumIds: number[], toParentId: number, toChildAlbumIds: number[])
    {
        return this.albumApiService.moveAlbum(fromParentId, fromChildAlbumIds, toParentId, toChildAlbumIds);
    }

    deleteAlbum(album: Album): Observable<any>
    {
        return this.albumApiService.deleteAlbum(album);
    }

    setImageForAlbum(albumEditModel: AlbumEditModel, previousThumbnail: ImageAsset, uploadedImageUrl: string): Observable<ImageAsset>
    {
        // Possibly adding/replacing image
        if (uploadedImageUrl != null) { return this.albumApiService.setImageForAlbum(albumEditModel.Id, uploadedImageUrl); }

        // See if the image is being removed
        if (previousThumbnail != null && albumEditModel.ThumbnailId === -1) { return this.albumApiService.deleteImageForAlbum(albumEditModel.Id); }

        return of(null);
    }

    moveMediaToAlbum(fromAlbum: Album, toAlbum: Album, imageAssetIds: number[])
    {
        return this.albumApiService.moveMediaToAlbum(fromAlbum.Id, toAlbum.Id, imageAssetIds);
    }
}
