import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ImageAsset } from '../../shared-api/images/image-asset-model';
import { ImageManagerService } from '../../shared-api/images/image-manager.service';
import { Constants } from '../../shared-api/common/constants';
import { take } from 'rxjs/operators';

@Component({
    selector: 'myapp-image-asset',
    templateUrl: './image-asset.component.html',
    styleUrls: ['./image-asset.component.less']
})
export class ImageAssetComponent implements OnInit, OnChanges, OnDestroy
{
    @Input() imageAsset: ImageAsset;
    @Input() width = 50;
    @Input() height = 50;
    @Input() usePlaceholder = false;
    @ViewChild('image', {static: false}) image;

    url = null;
    hasImage = false;
    loading = false;
    private loadAttempts = 0;
    private MAX_LOAD_ATTEMPTS = 3;



    constructor(private imageManager: ImageManagerService)
    {
    }

    ngOnInit()
    {
    }

    ngOnDestroy(): void
    {
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.imageAsset == null || !this.imageAsset.hasImage)
        {
            this.hasImage = this.usePlaceholder;
            if (this.usePlaceholder) {  this.url = Constants.MissingImageUrl; }
            return;
        }

        this.hasImage = true;
        this.url = this.imageAsset.getUrlForSize(this.width, this.height);
        if (this.url !== null) { return; }

        // Use closest but request in background so it will be loaded for the future
        this.url = this.imageAsset.getUrlForSize(this.width, this.height, true);
        // this.imageManager.getImageWithSize(this.imageAsset.Id, this.width, this.height).pipe(take(1)).subscribe();
        // this.loadImage();
    }

    loadImage()
    {
        this.loading = true;
        this.loadAttempts++;
        this.imageManager.getImageWithSize(this.imageAsset.Id, this.width, this.height).pipe(take(1))
            .subscribe(response =>
            {
                this.url = response.Url;
                this.loading = false;
            }, () =>
            {
                if (this.loadAttempts === this.MAX_LOAD_ATTEMPTS)
                {
                    this.loading = false;
                    return;
                    // No image for you! :(
                }

                // Try again
                this.loadImage();
            });
    }

    setSpinnerClass()
    {
        return this.width > 75 ? 'regular-spinner-size' : 'compact-spinner-size';
    }

    onImageLoadError()
    {
        if (this.usePlaceholder)
        {
            this.url = Constants.MissingImageUrl;
        }
    }
}
