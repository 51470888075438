export class UserLoginInfo
{
    constructor(username: string, password: string)
    {
        this.username = username;
        this.password = password;
    }

    grantType = 'password';
    username: string;
    password: string;
}
