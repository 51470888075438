import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'myapp-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: [ './color-picker.component.less' ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true
        }
    ]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor
{
    @Input() colorName: string;
    @Input() description: string;

    isDisabled = false;
    private color: string;

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor() { }

    ngOnInit()
    {
    }

    get value()
    {
        return this.color;
    }

    set value(val)
    {
        this.color = val;
        if (this.color === 'white') this.color = 'ffffff';
        if (this.color === 'black') this.color = '000000';
        if (this.color === 'lightgray') this.color = 'D3D3D3';
        if (this.color === 'darkgray') this.color = 'A9A9A9';
        if (this.color.indexOf('#') === 0) this.color = this.color.substr(1);
        this.color = this.color.toUpperCase();
        this.onChange(this.color);
        this.onTouched();
    }

    registerOnChange(fn: any): void
    {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void
    {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void
    {
        this.isDisabled = isDisabled;
    }

    writeValue(value: any): void
    {
        if (value)
        {
            this.value = value;
        }
    }
}
