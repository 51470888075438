/* tslint:disable:no-input-rename */
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationItem } from '../../sections/notifications/api/notification-item.model';
import { MyApp } from '../../shared-api/common/enumerations';
import { AppTeamMessageService } from '../app-team-message.service';
import { Organization } from '../../shared-api/organization/organization.model';
import { CategoryItem } from '../../shared-api/organization/category-item.model';
import { SelectItem } from 'primeng/api';
import { SelectImagesComponent } from '../image-asset/select-images.component';
import { Editor } from 'primeng/editor';
import { ImageManagerService } from '../../shared-api/images/image-manager.service';
import { take } from 'rxjs/operators';
import RegistrationFilterType = MyApp.RegistrationFilterType;
import NotificationType = MyApp.NotificationType;

@Component({
    selector: 'myapp-edit-notification',
    templateUrl: './edit-notification.component.html',
    styleUrls: [ './edit-notification.component.less' ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditNotificationComponent),
            multi: true
        }
    ]
})
export class EditNotificationComponent implements OnInit, ControlValueAccessor, OnChanges
{
    @ViewChild('selectPhoto', {static: false}) selectPhoto: SelectImagesComponent;
    @ViewChild('selectPdf', {static: false}) selectPdf: SelectImagesComponent;
    @ViewChild('htmlEditor', {static: false}) htmlEditor: Editor;
    @ViewChild('fileInput', {static: false}) fileInput: any;

    // noinspection TsLint
    @Input('value') notification: NotificationItem;
    @Input('organization') organization: Organization;
    @Input('hideCategories') hideCategories = false;
    @Input('allowSms') allowSms = true;
    @Input('class') class: string;
    @Output() notificationTypeChanged = new EventEmitter<EditNotificationComponent>();

    // emailText: string;
    isSelectCategoriesVisible = false;
    notificationAlreadySent = false;
    selectedCategories: CategoryItem[] = [];
    sounds: SelectItem[];
    registeredFilterTypes: SelectItem[];
    textMaxLength = 0;
    titleMaxLength = 150;

    SubjectValidationMessage: string;
    TextValidationMessage: string;
    ImageValidationMessage: string;

    private disabled = false;
    private soundPath = 'https://generalmyappstorage.blob.core.windows.net/sounds/';
    private quill: any;

    NotificationItem = NotificationItem;
    RegistrationFilterType = RegistrationFilterType;

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private messageService: AppTeamMessageService,
                private imageManager: ImageManagerService) { }

    ngOnInit()
    {
        this.notification = new NotificationItem();
        this.setMaxTextLength();
        this.sounds = [
            { label: 'Phone default', value: 'default' },
            { label: 'Camera', value: 'camera.wav' },
            { label: 'Ding', value: 'harp.wav' },
            { label: 'Yay!', value: 'yay.wav' },
        ];
        this.registeredFilterTypes = [
            { label: 'All', value: RegistrationFilterType.All },
            { label: 'Registered', value: RegistrationFilterType.Registered },
            { label: 'Unregistered', value: RegistrationFilterType.Unregistered },
        ];
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.notification == null) { return; }
        this.notificationAlreadySent = this.notification.SentDate != null;
        this.onChange(this.notification);
    }

    get value()
    {
        return this.notification;
    }

    set value(val)
    {
        this.notification = NotificationItem.createCopy(val);
        if (this.notification.Id === 0) { this.notification.Sound = 'default'; }
        this.setMaxTextLength();
        this.notificationAlreadySent = this.notification.SentDate != null;
        this.selectedCategories = this.organization.CategoryItems.filter(cat => this.notification.CategoryIds.some(noteCat => noteCat === cat.Id));
        this.onChange(this.notification);
        this.notificationTypeChanged.emit(this);
        if (this.notification.MediaUrl != null) this.selectPhoto.updatePhotoUrl(this.notification.MediaUrl);
        if (this.notification.PdfUrl != null) this.selectPdf.updatePdfUrl(this.notification.PdfUrl);
        setTimeout(() =>
        {
            // tslint:disable-next-line:triple-equals
            if (this.notification.Type == NotificationType.Email)
            {
                this.quill = this.htmlEditor.getQuill();
                const toolbar = this.quill.getModule('toolbar');
                toolbar.addHandler('image', this.onSelectImage.bind(this));
            }
        });
        this.onTouched();
    }

    onNotificationTypeChanged()
    {
        setTimeout(() =>
        {
            if (this.notification.Type === NotificationType.Email) {
                const lines = this.notification.Text.split('\n');
                const htmlLines = lines.map(line => `<p>${line}</p>`);
                this.notification.HtmlText = htmlLines.join('');
            }
            this.setMaxTextLength();
            this.onChange(this.notification);
            this.notificationTypeChanged.emit(this);
        });
    }

    onCategoriesChanged()
    {
        this.notification.CategoryIds = this.selectedCategories.map(cat => cat.Id);
        this.onChange(this.notification);
    }

    onStartDateChanged()
    {
        console.log('EditNotification onStartDateChanged');
        this.onChange(this.notification);
    }

    onPlaySound()
    {
        const audio = new Audio(`${this.soundPath}${this.notification.Sound}`);
        audio.play();
    }

    onSelectImage(): void
    {
        this.fileInput.nativeElement.click();
    }

    onUploadImage(event)
    {
        if (event == null || event.target == null || event.target.files == null || event.target.files.length === 0) { return; }

        const file = event.target.files[0];
        this.imageManager.checkImageSize(file).pipe(take(1)).subscribe(size =>
        {
            if (size.width > 800 || size.height > 800) {
                this.ImageValidationMessage = 'Email images must be no more that 800 pixels by 800 pixels.';
                return;
            }
            this.ImageValidationMessage = null;
            this.imageManager.uploadFile(file).subscribe(result =>
            {
                const html = `<img src="${result.fileOrUrl.substring(0, result.fileOrUrl.indexOf('?'))}" style="max-width: 600px" \/>`;
                const range = this.quill.getSelection();
                this.quill.clipboard.dangerouslyPasteHTML(range.index, html, 'user');
            }, () =>
            {
            });
        });
    }

    registerOnChange(fn: any): void
    {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void
    {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void
    {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void
    {
        if (value)
        {
            this.value = value;
        }
    }

    onControlChanged()
    {
        this.onChange(this.notification);
    }

    onEmailTextChanged(event: any)
    {
        this.value.Text = event.textValue;
        this.onControlChanged();
    }

    getExtraClasses()
    {
        return this.class ? [ this.class ] : null;
    }

    setTitleAndText(title: string, text: string)
    {
        setTimeout(() =>
        {
            if (this.notification.Title === title && this.notification.Text === text) { return; }
            this.notification.Title = title;
            this.notification.Text = text;

            if (title.length === this.titleMaxLength) { this.messageService.add({ severity: 'warn', summary: 'Max length reached', detail: 'The notification title is at its maximum length.' }, 5); }
            if (text.length === this.textMaxLength) { this.messageService.add({ severity: 'warn', summary: 'Max length reached', detail: 'The notification text is at its maximum length.' }, 5); }
        });
    }

    private setMaxTextLength()
    {
        // tslint:disable-next-line:triple-equals
        this.textMaxLength = this.notification.Type == NotificationType.Email ? NotificationItem.EmailTextMaxLength :
            // tslint:disable-next-line:triple-equals
            (this.notification.Type == NotificationType.Push ? NotificationItem.PushTextMaxLength :
                NotificationItem.SmsTextMaxLength - this.organization.Name.length - 2);
    }

    onSelectCategories()
    {
        this.isSelectCategoriesVisible = true;
    }

    onCategoriesSelected(categoryIds: number[])
    {
        this.isSelectCategoriesVisible = false;
        if (categoryIds == null) { return; } // Cancel
        this.notification.CategoryIds = categoryIds;
        this.selectedCategories = this.organization.CategoryItems.filter(cat => categoryIds.some(noteCat => noteCat === cat.Id));
        this.onChange(this.notification);
    }

    onPhotoSelected(files: File[])
    {
        this.notification.PhotoFile = files != null && files.length > 0 ? files[0] : null;
        this.onChange(this.notification);
    }

    onPdfSelected(files: File[])
    {
        this.notification.PdfFile = files != null && files.length > 0 ? files[0] : null;
        this.notification.PdfUrl = null;
        this.onChange(this.notification);
    }

    onDocumentSelected(url: string): void
    {
        this.notification.PdfUrl = url;
        this.notification.PdfFile = null;
        this.onChange(this.notification);
    }
}
