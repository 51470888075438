export namespace MyApp
{
    export enum Environment
    {
        Prod,
        Staging,
        Dev,
        Test,
        MacDev
    }

    export enum HttpResponseStatusCode
    {
        Offline = 0,
        BadRequest = 400,
        Unauthorized = 401
    }

    export enum SortOrder
    {
        Ascending,
        Descending
    }

    export enum AppSection
    {
        Unknown,
        News,
        Events,
        Notifications,
        Members,
        Albums,
        Contacts,
        Categories,
        OrganizationInfo,
        Locations,
        Sponsors,
        Themes,
        DocumentCenter,
        TextMessages,
    }

    export enum CategoryItemType
    {
        Other,
        Group,
        Session,
        Bus,
        Location,
    }

    export function getCategoryItemTypeString(type: CategoryItemType): string
    {
        switch (type)
        {
            case CategoryItemType.Other: return 'Other';
            case CategoryItemType.Group: return 'Group';
            case CategoryItemType.Session: return 'Session';
            case CategoryItemType.Bus: return 'Bus';
            case MyApp.CategoryItemType.Location: return 'Location';
        }
    }

    export function getCategoryItemTypeColor(type: CategoryItemType): string
    {
        switch (type)
        {
            case CategoryItemType.Other: return '#AEE5CF';
            case CategoryItemType.Group: return '#AEC4E5';
            case CategoryItemType.Session: return '#E5AEC4';
            case CategoryItemType.Bus: return '#DFE5AE';
            case CategoryItemType.Location: return '#AEDFE5';
        }
    }

    export function isCategoryItemTypeSortedBefore(categoryItemType: MyApp.CategoryItemType, otherCategoryItemType: MyApp.CategoryItemType): number
    {
        if (categoryItemType === otherCategoryItemType) { return 0; }
        switch (categoryItemType)
        {
            case MyApp.CategoryItemType.Session: return -1; // Session is first
            case MyApp.CategoryItemType.Group: return otherCategoryItemType === MyApp.CategoryItemType.Session ? 1 : -1; // Group is second
            case MyApp.CategoryItemType.Bus: return otherCategoryItemType === MyApp.CategoryItemType.Group || otherCategoryItemType === MyApp.CategoryItemType.Session ? 1 : -1; // Bus is third
            case MyApp.CategoryItemType.Location: return otherCategoryItemType === MyApp.CategoryItemType.Group ||
            otherCategoryItemType === MyApp.CategoryItemType.Session || otherCategoryItemType === MyApp.CategoryItemType.Bus ? 1 : -1; // Location is forth
            case MyApp.CategoryItemType.Other: return 1; // Other is last
        }
    }

    export enum DocumentType
    {
        None,
        TermsOfService,
        PrivacyPolicy,
        FAQ,
    }

    export enum AssetType
    {
        Image = 1,
        Video = 2
    }

    export enum RecurrenceType
    {
        Never = -1,
        Daily = 0,
        Weekly = 1,
        Monthly = 2,
        Yearly = 3
    }

    export enum NotificationType
    {
        Email = 1,
        Push = 2,
        Sms = 3,
    }

    export enum RegistrationFilterType
    {
        All,
        Registered,
        Unregistered
    }

    export enum SocialMediaAccountType
    {
        Facebook = 1,
        Twitter = 2,
        Instagram = 5,
    }

    export enum ProductFeatureFlags
    {
        ProfessionalInfo = 0x01,
        Sponsor = 0x02,
        Staff = 0x04,
        HoursOfOperation = 0x08,
        GuestLoginPassword = 0x10,
        GuestLoginAnonymous = 0x20
    }

    export enum UserReportType
    {
        Registrations = 1,
        Logins = 2,
    }

    export enum UserActionType
    {
        Unknown,
        Login,                // AdditionalDataId = LoginType, AdditionalDataJson = { DeviceType: DeviceType }
        ViewSelected,        // AdditionalDataId = ViewType
        AlbumViewed,         // AdditionalDataId = Album Id
        ContentShared,       // AdditionalDataId = ContentType, AdditionalDataJson = { SharedTo: ShareToType }
        OrgInfoRequested,    // AdditionalDataId = OrgInfoType
        MoreInfoSelected,    // AdditionalDataId = MoreInfoType
        SponsorViewed,        // AdditionalDataId = Sponsor Id
        SponsorLinkViewed,    // AdditionalDataId = Sponsor Id
        MemberCommunication,    // AdditionalDataId = Member Id, AdditionalDataJson = { ContactType: ContactType }
        ContactCommunication,    // AdditionalDataId = Contact Id, AdditionalDataJson = { ContactType: ContactType }
        UserRegistration,
        NewsItemViewed,     // AdditionalDataId: News Item Id
        EventViewed         // AdditionalDataId: Event Id
    }

    export enum TextMessageType
    {
        FromMember = 0,
        FromOrganization = 1
    }

    export function getUserActionTypeString(actionType: UserActionType): string
    {
        switch (actionType)
        {
            case UserActionType.Login: return 'Login';
            case UserActionType.ViewSelected: return 'View Selected';
            case UserActionType.AlbumViewed: return 'Viewed Photo Album';
            case UserActionType.ContentShared: return 'Shared Content';
            case UserActionType.OrgInfoRequested: return 'Requested Camp Info';
            case UserActionType.MoreInfoSelected: return 'Requested More Info';
            case UserActionType.SponsorViewed: return 'Viewed Sponsor';
            case UserActionType.SponsorLinkViewed: return 'Clicked Sponsor Link';
            case UserActionType.MemberCommunication: return 'Contacted Other Member';
            case UserActionType.ContactCommunication: return 'Contacted Camp';
            case UserActionType.UserRegistration: return 'Registered';
            default: return 'Unknown';
        }
    }

    export enum ViewType
    {
        Unknown,
        News,
        Events,
        Photos,
        Contacts,
        More,
        NotificationList
    }

    export function getViewTypeString(viewType: ViewType): string
    {
        switch (viewType)
        {
            case ViewType.News: return 'News';
            case ViewType.Events: return 'Calendar';
            case ViewType.Photos: return 'Photos';
            case ViewType.Contacts: return 'Contacts';
            case ViewType.More: return 'More';
            case ViewType.NotificationList: return 'NotificationList';
            default: return 'Unknown';
        }
    }

    export enum MoreInfoType
    {
        Unknown,
        OrgInfo,
        Profile,
        Settings,
        Sponsor,
        Location,
        Link
    }

    export function getMoreInfoTypeString(moreInfoType: MoreInfoType): string
    {
        switch (moreInfoType)
        {
            case MoreInfoType.OrgInfo: return 'Camp Info';
            case MoreInfoType.Profile: return 'Profile';
            case MoreInfoType.Settings: return 'Settings';
            case MoreInfoType.Sponsor: return 'Sponsor';
            case MoreInfoType.Location: return 'Location';
            case MoreInfoType.Link: return 'Link';
            default: return 'Unknown';
        }
    }
}
