import { AppTeamUtil } from '../../core/app-team-util';

export class PdfAsset
{
    Id: number;
    Date: Date;
    Url: string;
    Selected = false; // For UI selection purposes only

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }
    }

    get simpleName(): string
    {
        const filenameStart = this.Url.lastIndexOf('/');
        const underscoreIndex = this.Url.search(/_[0-9a-f]{32}.pdf$/);
        return this.Url.substring(filenameStart + 1, underscoreIndex);
    }
}
