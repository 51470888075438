import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

import { ApiService, HttpMethod } from '../common/api.service';
import { ApiUrlService } from '../common/api-url.service';
import { MyApp } from '../common/enumerations';
import { ItemsCollection } from '../common/items-collection.model';
import { OrganizationListItem } from './organization-list-Item.model';
import { Organization, TimeZone } from './organization.model';
import { MembershipTypeEditModel } from '../../sections/members/api/member-edit.model';
import { OrganizationEditModel } from '../../sections/organization-info/api/organization-edit.model';
import { OrganizationAdminEditModel, OrganizationCreateModel } from '../../sections/organization-info/api/organization-create.model';
import { OrganizationAdmin } from './organization-admin.model';
import { OrganizationPhotoLimitResult } from '../../sections/organization-info/api/organization-photo-limit.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationApiService
{
    protected apiService: ApiService;

    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        this.apiService = new ApiService(http);
    }

    getOrganizations(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = ApiService.defaultPageSize): Observable<ItemsCollection<OrganizationListItem>>
    {
        return this.apiService.apiRequest(this.apiUrlService.getOrganizationsUrl(sortField, sortOrder, pageIndex, pageSize), HttpMethod.Get)
            .pipe(
                map((organizationsCollection: any) =>
                {
                    const organizationItems: OrganizationListItem[] = [];

                    organizationsCollection.Organizations.forEach(item =>
                    {
                        organizationItems.push(new OrganizationListItem(item));
                    });

                    return new ItemsCollection<OrganizationListItem>(organizationsCollection.Count, organizationItems);
                })
            );

    }

    getCurrentOrganization()
    {
        return this.apiService.apiRequest(this.apiUrlService.currentOrganizationUrl(), HttpMethod.Get)
            .pipe(
                map((organization: Organization) => new Organization(organization))
            );
    }

    getCurrentOrganizationImages()
    {
        return this.apiService.apiRequest(this.apiUrlService.currentOrganizationImagesUrl(), HttpMethod.Get);
    }

    createOrganization(model: OrganizationCreateModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createOrganizationUrl, HttpMethod.Post, JSON.stringify(model));
    }

    getOrganizationAdmins(): Observable<OrganizationAdmin[]>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.getOrganizationAdminsUrl, HttpMethod.Get)
            .pipe(map(json => json.map(item => new OrganizationAdmin(item))));
    }

    createOrganizationAdmin(model: OrganizationAdminEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createOrganizationAdminUrl, HttpMethod.Post, JSON.stringify(model));
    }

    updateOrganizationAdmin(model: OrganizationAdminEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationAdminUrl, HttpMethod.Put, JSON.stringify(model));
    }

    updateInfo(model: OrganizationEditModel): Observable<Organization>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationInfoUrl(), HttpMethod.Put, JSON.stringify(model))
            .pipe(map( result => new Organization(result)));
    }

    updateRestrictedSettings(org: Organization): Observable<boolean>
    {
        const settings = {
            MaxPhotoCount: org.MaxPhotoCount,
            SmsYearlyLimit: org.SmsYearlyLimit,
            MaxMembers: org.MaxMembers,
            AuthorizationCode: org.AuthorizationCode,
            DefaultMemberType: org.DefaultMemberType,
            OptedOutForSignupConfirmationEmail: org.OptedOutForSignupConfirmationEmail,
            IsFaceRecognitionEnabled: org.IsFaceRecognitionEnabled,
            IsMMSEnabled: org.IsMMSEnabled,
            IsDocumentCenterEnabled: org.IsDocumentCenterEnabled,
        };
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationRestrictedSettingsUrl(), HttpMethod.Put, JSON.stringify(settings))
            .pipe(map( () => true));
    }

    setTheme(themeId: number)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationThemeUrl(), HttpMethod.Post, JSON.stringify({ ThemeId: themeId }));
    }

    setLogo(url: string)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationLogoUrl(), HttpMethod.Post, JSON.stringify({ Url: url }));
    }

    deleteLogo()
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationLogoUrl(), HttpMethod.Delete, null);
    }

    setPhoto(url: string)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationPhotoUrl(), HttpMethod.Post, JSON.stringify({ Url: url }));
    }

    deletePhoto()
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationPhotoUrl(), HttpMethod.Delete, null);
    }

    setWelcomeImage(url: string)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationWelcomeImageUrl(), HttpMethod.Post, JSON.stringify({ Url: url }));
    }

    deleteWelcomeImage()
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrganizationWelcomeImageUrl(), HttpMethod.Delete, null);
    }

    createMembershipType(model: MembershipTypeEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createMembershipTypeUrl(), HttpMethod.Post, JSON.stringify(model));
    }

    updateMembershipType(model: MembershipTypeEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrDeleteMembershipTypeUrl(model.Id), HttpMethod.Put, JSON.stringify(model));
    }

    deleteMembershipType(id: number)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrDeleteMembershipTypeUrl(id), HttpMethod.Delete, null);
    }

    getTimezonesList(): Observable<TimeZone[]>
    {
        return this.apiService.apiRequest(this.apiUrlService.getTimezonesUrl(), HttpMethod.Get).pipe(map((timezoneList: any[]) =>
        {
            return timezoneList.map(json => new TimeZone(json));
        }));
    }

    checkPhotoLimit(sendEmail: boolean): Observable<OrganizationPhotoLimitResult>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.checkPhotoLimitUrl(sendEmail), HttpMethod.Get);
    }
}
