import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthApiService } from './auth/auth-api.service';
import { ImageApiService } from './images/image-api.service';
import { OrganizationApiService } from './organization/organization-api.service';
import { TenantApiService } from './tenant/tenant-api.service';
import { ApiService } from './common/api.service';
import { ComponentEditGuard } from '../shared-ui/component-edit-guard.service';


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        ApiService,
        AuthApiService,
        OrganizationApiService,
        ImageApiService,
        TenantApiService,
        ComponentEditGuard,
    ]
})
export class SharedApiModule { }
