import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'myapp-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.less']
})
export class LoadingComponent implements OnInit
{
    @Input() text = 'Loading';

    constructor()
    {
    }

    ngOnInit()
    {
    }

}
