import { Injectable } from '@angular/core';
import { ApiService, HttpMethod } from '../common/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

import { ApiUrlService } from '../common/api-url.service';
import { Folder } from './folder.model';
import { UploadFileResult } from '../images/image-manager.service';
import { PdfAsset } from '../pdfs/pdf-asset-model';
import { FolderEditModel } from './folder-edit.model';

@Injectable({
    providedIn: 'root'
})
export class FolderApiService
{
    protected apiService: ApiService;

    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        this.apiService = new ApiService(http);
    }

    getFolder(id: number): Observable<Folder>
    {
        return this.apiService.apiRequest(this.apiUrlService.getFolderUrl(id), HttpMethod.Get)
            .pipe(map(json => new Folder(json)));
    }

    createFolder(parentFolderId: number, folder: FolderEditModel): Observable<Folder>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createFolderUrl, HttpMethod.Post, JSON.stringify({
            Name: folder.Name,
            IsPublished: folder.IsPublished,
            ParentId: parentFolderId,
            CategoryIds: folder.CategoryIds,
        })).pipe(map(json => new Folder(json)));
    }

    updateFolder(parentFolderId: number, folder: FolderEditModel): Observable<Folder>
    {
        const params = { ParentId: parentFolderId, Id: folder.Id, Name: folder.Name, IsPublished: folder.IsPublished, CategoryIds: folder.CategoryIds };
        return this.apiService.apiRequest<any>(this.apiUrlService.updateFolderUrl(folder.Id), HttpMethod.Put, JSON.stringify(params))
            .pipe(map(json => new Folder(json)));
    }

    addPdfToFolder(folderId: number, uploadResult: UploadFileResult): Observable<PdfAsset>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.addPdfToFolderUrl, HttpMethod.Post, JSON.stringify({
            FolderId: folderId,
            Url: uploadResult.fileOrUrl,
        })).pipe(map(json => new PdfAsset(json)));
    }

    deleteItems(folderId: number, folderIdsToDelete: number[], pdfIdsToDelete: number[]): Observable<Folder>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.deleteItemsUrl, HttpMethod.Delete, JSON.stringify({
            FolderId: folderId,
            SubFolderIds: folderIdsToDelete,
            PdfIds: pdfIdsToDelete,
        })).pipe(map(json => new Folder(json)));
    }

    moveItems(destinationFolderId: number, folderIdsToMove: number[], pdfIdsToMove: number[]): Observable<Folder>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.moveItemsUrl, HttpMethod.Post, JSON.stringify({
            FolderId: destinationFolderId,
            SubFolderIds: folderIdsToMove,
            PdfIds: pdfIdsToMove,
        })).pipe(map(json => new Folder(json)));
    }
}
