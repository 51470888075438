import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Album } from '../../shared-api/albums/album.model';
import { AlbumManagerService } from '../../shared-api/albums/album-manager.service';
import { TreeNode } from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'myapp-album-select-dialog',
    templateUrl: './album-select-dialog.component.html',
    styleUrls: [ './album-select-dialog.component.less' ]
})
export class AlbumSelectDialogComponent implements OnInit
{
    @Input() supportCanAcceptMedia = false;
    @Input() excludedAlbumId: number;
    @Output() albumSelected = new EventEmitter<Album>();

    selectedAlbumNode: TreeNode;
    albums: TreeNode[];
    loading = false;
    visible = true;

    constructor(private albumManager: AlbumManagerService,
                public deviceDetector: DeviceDetectorService)
    {
    }

    ngOnInit()
    {
        this.loadAlbum();
    }

    private loadAlbum(parentTreeNode: TreeNode = null)
    {
        this.loading = true;
        this.albumManager.getAlbum(parentTreeNode == null ? 0 : parentTreeNode.data.Id)
            .subscribe( (parentAlbum: Album) =>
            {
                if (this.excludedAlbumId != null)
                {
                    parentAlbum.ChildAlbums = parentAlbum.ChildAlbums.filter(album => album.Id !== this.excludedAlbumId);
                }
                const newNodes = parentAlbum.ChildAlbums.map(album =>
                {
                    return this.createTreeNodeFromAlbum(album);
                });
                if (parentTreeNode == null) this.albums = newNodes;
                else parentTreeNode.children = newNodes;
                this.loading = false;
            });
    }

    onAlbumSelected(event)
    {
        const album = event.node.data;
        if (this.supportCanAcceptMedia && album.IsFolder) return;
        this.selectedAlbumNode = event.node;
    }

    onAlbumExpanded(event)
    {
        if (event.node.children != null && event.node.children.length > 0 && event.node.children[0] != null) return;

        this.loading = true;
        this.loadAlbum(event.node);
    }

    onOk()
    {
        this.albumSelected.emit(this.selectedAlbumNode == null ? null : this.selectedAlbumNode.data);
    }

    onHide()
    {
        this.albumSelected.emit(null);
    }

    private createTreeNodeFromAlbum(album: Album): TreeNode
    {
        return <TreeNode>{
            data: album,
            label: album.Name,
            expanded: false,
            children: album.IsFolder ? [null] : null,
            leaf: !album.IsFolder,
            styleClass: this.supportCanAcceptMedia && album.IsFolder ? 'album-not-selectable' : 'album-selectable',
        };
    }
}
