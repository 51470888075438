export class FolderBreadcrumb
{
    Id: number;
    Name: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
