import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from '../shared-api/common/constants';
import { MenuItem, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManagerService } from '../shared-api/auth/auth-manager.service';
import { BroadcastService } from '../core/services/broadcast.service';
import { OrganizationManagerService } from '../shared-api/organization/organization-manager.service';
import { MyApp } from '../shared-api/common/enumerations';
import { ItemsCollection } from '../shared-api/common/items-collection.model';
import { SelectItemOption } from '../shared-api/common/select-item-option.model';
import { TenantManagerService } from '../shared-api/tenant/tenant-manager.service';
import { Product } from '../shared-api/tenant/product.model';
import { OrganizationListItem } from '../shared-api/organization/organization-list-Item.model';
import { LocalStorageManagerService } from '../core/managers/local-storage-manager.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Organization } from '../shared-api/organization/organization.model';

@Component({
    selector: 'myapp-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy
{
    // Constants
    Constants = Constants;

    // Member vars
    menuItems: MenuItem[];
    isMobileFormFactor = false;
    fullLogo = false;
    logoImage: string;
    previousLogoImage = this.logoImage;
    hideImage = false;
    applyFadeIn = false;
    showOrganizationSelector = false;
    organizations: SelectItem[];
    organization: OrganizationListItem;
    fullOrganization: Organization;

    logoClass: string;
    logoImageUrl = Constants.MyAppLogoUrl;


    constructor(private router: Router,
                private route: ActivatedRoute,
                private organizationManager: OrganizationManagerService,
                private orgManager: OrganizationManagerService,
                private tenantManager: TenantManagerService,
                private broadcastService: BroadcastService,
                private localStorageManager: LocalStorageManagerService,
                private authManager: AuthManagerService,
                private deviceDetector: DeviceDetectorService) { }

    ngOnInit()
    {
        this.initializeHeader();

        this.broadcastService.windowResize.pipe(untilDestroyed(this)).subscribe(() => this.setLogoImage());
        this.isMobileFormFactor = this.deviceDetector.isMobile();
        this.broadcastService.organizationListChanged.pipe(untilDestroyed(this)).subscribe(() => this.setupOrganizationSelector());
    }

    ngOnDestroy(): void
    {
    }

    toggleHamburgerMenu()
    {
        this.broadcastService.toggleHamburgerMenu.next();
    }

    onOrganizationChange(event: any)
    {
        this.organization = <OrganizationListItem>event.value;
        this.setOrganizationForGlobalAdmin();
    }

    onSignOut()
    {
        this.authManager.signOut();
    }

    private setOrganizationForGlobalAdmin()
    {
        this.authManager.signInWithRefreshToken(this.organization.Id)
            .subscribe(() =>
            {
                this.getOrganizationAndProduct();
            });
    }

    private getOrganizationAndProduct()
    {
        this.orgManager.switchingOrganizations();
        this.tenantManager.switchingOrganizations();
        this.orgManager.currentOrganization
            .subscribe(org =>
            {
                this.fullOrganization = org;
                this.broadcastService.organizationChanged.next(org);
                this.tenantManager.currentProduct
                    .subscribe((product: Product) =>
                    {
                        this.localStorageManager.currentOrganization = org;

                        switch (product.Name.toLowerCase())
                        {
                            case 'myconnectedapp':
                                this.logoClass = 'my-connected-app';
                                this.logoImageUrl = Constants.MyConnectedAppLogoUrl;
                                break;
                            case 'myclubapp':
                                this.logoClass = 'my-club-app';
                                this.logoImageUrl = Constants.MyClubAppLogoUrl;
                                break;
                            case 'mycampapp':
                                this.logoClass = 'my-camp-app';
                                this.logoImageUrl = Constants.MyCampAppLogoUrl;
                                break;
                            default:
                                this.logoClass = 'my-unknown-app';
                                this.logoImageUrl = Constants.MyConnectedAppLogoUrl;
                        }

                        this.setLogoImage();
                    });
            });
    }

    private initializeHeader()
    {
        // Construct Menu
        this.menuItems = [
            {
                label: this.authManager.authenticatedUser.fullName,
                items: [
                    // {label: 'Account Settings'},
                    // {label: 'Billing Information'},
                    // {separator: true},
                    {
                        label: 'Change Password', command: (event) =>
                        {
                            this.router.navigate(['/account/changepassword', this.router.url]);
                        },
                    },
                ]
            },
        ];

        // Set proper logo
        this.setLogoImage();

        // Set proper layout
        this.isMobileFormFactor = this.deviceDetector.isMobile();

        if (!this.authManager.authenticatedUser.isGlobalAdmin)
        {
            this.getOrganizationAndProduct();
        }
        else
        {
            // If global admin, show organization selector, fetch Tenant Id and set Product.
            this.setupOrganizationSelector();
        }
    }

    private setupOrganizationSelector()
    {
        this.showOrganizationSelector = this.authManager.authenticatedUser.isGlobalAdmin;

        if (!this.showOrganizationSelector) { return; }

        this.organizationManager.getOrganizations('name', MyApp.SortOrder.Ascending, 0, 1000, true)
            .subscribe((organizationsCollection: ItemsCollection<OrganizationListItem>) =>
            {
                if (organizationsCollection.Count < 1)
                {
                    this.showOrganizationSelector = false;
                    return;
                }

                this.organizations = [];
                organizationsCollection.Items.forEach(organization =>
                {
                    this.organizations.push(new SelectItemOption(`${organization.Name}`, organization));
                });

                // Set the currently selected organization either to last selected or first in list.
                if (this.localStorageManager.currentOrganization != null)
                {
                    const foundItem = this.organizations.find(item => item.value.Id === this.localStorageManager.currentOrganization.Id);
                    if (foundItem != null) this.organization = foundItem.value;
                }
                if (this.organization == null)
                {
                    this.organization = this.organizations[0].value;
                }
                this.setOrganizationForGlobalAdmin();
            });
    }

    private setLogoImage()
    {
        const width = window.innerWidth;

        if (width <= Constants.MinWidthForFullNavbar && width > Constants.MobileWidth)
        {
            this.fullLogo = false;
            this.swapImageIfNotSame(Constants.MyAppLogoUrl);
            return;
        }

        this.fullLogo = true;
        this.swapImageIfNotSame(this.logoImageUrl);
    }

    private swapImageIfNotSame(newImageUrl: string)
    {
        if (this.previousLogoImage !== newImageUrl)
        {
            // Hide the old image
            this.applyFadeIn = false;
            this.hideImage = true;

            // Change the image
            this.logoImage = newImageUrl;
            this.previousLogoImage = this.logoImage;

            // Fade it in
            setTimeout(() =>
            {
                this.hideImage = false;
                this.applyFadeIn = true;
            }, 10);
        }
    }
}
