import { CategoryItem } from '../../../shared-api/organization/category-item.model';
import { AppTeamUtil } from '../../../core/app-team-util';

export class MemberInfo
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            if (json['CategoryItems'])
            {
                this.CategoryItems = [];
                json['CategoryItems'].forEach(item => {
                    this.CategoryItems.push(new CategoryItem(item));
                });
            }
            this.StartDate = AppTeamUtil.convertApiDateStringToDate(json['StartDate']);
            this.RenewalDate = AppTeamUtil.convertApiDateStringToDate(json['RenewalDate']);

            delete this['MemberId'];
            delete this['MyEvents'];
        }
    }

    Id: number;
    CanUploadMedia: boolean;
    MembershipNumber: string;
    MembershipTypeId: number;
    StartDate: Date;
    RenewalDate: Date;
    ShouldDisplayInDirectory: boolean;
    ShouldReceivePushNotifications: boolean;
    ShouldReceiveEmailNotifications: boolean;
    ShouldReceiveSmsNotifications: boolean;
    ShouldViewSocialMedia: boolean;
    CategoryItems: CategoryItem[] = [];
}
