import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ImageAsset } from '../../shared-api/images/image-asset-model';
import { ImageManagerService } from '../../shared-api/images/image-manager.service';
import { take } from 'rxjs/operators';
import { PdfAsset } from '../../shared-api/pdfs/pdf-asset-model';
import { ApiService } from '../../shared-api/common/api.service';
import { AppTeamMessageService } from '../app-team-message.service';
import { OrganizationManagerService } from '../../shared-api/organization/organization-manager.service';
import { Organization } from '../../shared-api/organization/organization.model';

declare var loadImage: any;

@Component({
    selector: 'myapp-select-images',
    templateUrl: './select-images.component.html',
    styleUrls: [ './image-asset.component.less', './select-images.component.less' ]
})
export class SelectImagesComponent implements OnInit
{
    @Output() imagesSelected = new EventEmitter<File []>();
    @Output() documentSelected = new EventEmitter<string>();
    @Output() imageSelectionError = new EventEmitter<string>();
    @Input() photo: ImageAsset;
    @Input() photoUrl: string; // As an alternative to photo: ImageAsset
    @Input() pdf: PdfAsset;
    @Input() pdfUrl: string; // As an alternative to pdf: PdfAsset
    @Input() width = 200;
    @Input() height = 200;
    @Input() placeholderText = 'Add Album Cover here.';
    @Input() sizeText: string;
    @Input() minWidth = -1;
    @Input() recommendedWidth = -1;
    @Input() minHeight = -1;
    @Input() recommendedHeight = -1;
    @Input() disabled = false;
    @Input() allowRemove = true;
    @Input() pdfMode = false;
    @ViewChild('pdfViewer', {static: false}) pdfViewer: any;

    private uploadFile: any;
    hasFile = false;
    uploadSrc: any;
    acceptedFiles: string;
    filename: string;
    pdfUrlSafe: SafeResourceUrl;
    isSelectPdfVisible = false;
    organization: Organization;

    constructor(private imageManager: ImageManagerService,
                private orgManager: OrganizationManagerService,
                private messageService: AppTeamMessageService,
                public sanitizer: DomSanitizer) { }

    ngOnInit()
    {
        this.orgManager.currentOrganization.subscribe(org =>
        {
            this.organization = org;
        })
        this.acceptedFiles = this.pdfMode ? '.pdf' : '.jpg,.jpeg,.png';
        this.hasFile = (this.pdfMode && this.pdf && this.pdf.Url != null) || (!this.pdfMode && this.photo && this.photo.hasImage);
        if (this.pdfMode)
        {
            this.filename = this.pdf == null ? null : this.pdf.Url.substring(this.pdf.Url.lastIndexOf('/') + 1);
        }
        else
        {
            this.filename = null;
        }

        if (this.minWidth > 0 && this.minHeight > 0) { this.sizeText = `Minimum size: ${this.minWidth} pixels by ${this.minHeight} pixels`; }
        else if (this.recommendedWidth > 0 && this.recommendedHeight > 0) { this.sizeText = `Suggested: ${this.recommendedWidth} pixels by ${this.recommendedHeight} pixels`; }
    }

    updatePhoto(photo: ImageAsset)
    {
        this.photo = photo;
        this.photoUrl = null;
        this.uploadSrc = null;
        this.hasFile = this.photo && this.photo.hasImage;
    }

    updatePhotoUrl(url: string)
    {
        this.photoUrl = url;
        this.photo = null;
        this.uploadSrc = null;
        this.hasFile = url != null;
    }

    updatePdf(pdf: PdfAsset)
    {
        this.pdf = pdf;
        this.hasFile = this.pdf && this.pdf.Url != null;
        this.filename = this.pdf == null ? null : this.pdf.simpleName;
        this.updatePdfUrl(pdf == null ? null : pdf.Url);
    }

    updatePdfUrl(url: string)
    {
        this.pdfUrl = url;
        this.hasFile = url != null;
        setTimeout(() =>
        {
            this.pdfUrlSafe = url == null ? null : this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${url}&embedded=true`);
        }, 0);
    }

    filesSelected(event)
    {
        if (event == null) { return; }
        this.uploadFile = event.files[0];
        if (!ApiService.isValidUploadFilename(this.uploadFile.name))
        {
            this.messageService.add({ severity: 'error', summary: 'File error', detail: 'The filename cannot contain special characters. Please use only letters, numbers, underscores, and dashes.' }, 10);
            return;
        }

        const files = [];
        for (let iFile = 0; iFile < event.files.length; iFile++) { files.unshift(event.files[iFile]); }

        if (this.pdfMode)
        {
            this.uploadSrc = URL.createObjectURL(this.uploadFile);
            this.filename = this.uploadFile.name.substring(this.uploadFile.name.lastIndexOf('/') + 1);
            this.hasFile = true;
            this.imagesSelected.emit(files);
            return;
        }

        if (this.minWidth < 0 || this.minHeight < 0)
        {
            this.checkOrientation(files);
            return;
        }

        this.imageManager.checkImageSize(this.uploadFile).pipe(take(1)).subscribe(result =>
        {
            if (result.width < this.minWidth || result.height < this.minHeight)
            {
                this.imageSelectionError.emit(`Image is too small: ${result.width} pixels by ${result.height} pixels. It needs to be at least ${this.minWidth} by ${this.minHeight}.`);
                return;
            }

            this.checkOrientation(files);
        });
    }

    get hasImageOrPdf(): boolean
    {
        return this.uploadSrc != null ||
            this.hasFile ||
            (!this.pdfMode && ((this.photo != null && this.photo.hasImage) || this.photoUrl != null)) ||
            (this.pdfMode && this.pdfUrlSafe != null);
    }

    onPlaceholderClicked()
    {
    }

    onRemoveImage()
    {
        this.uploadSrc = null;
        this.pdf = null;
        this.photo = null;
        this.photoUrl = null;
        this.updatePdfUrl(null);
        this.hasFile = false;
        this.filename = null;
        this.imagesSelected.emit(null);
    }

    private checkOrientation(files: File[])
    {
        const that = this;
        loadImage.parseMetaData(this.uploadFile, function(data)
        {
            const orientation = data && data.exif ? data.exif.get('Orientation') : 1;
            if (orientation === 1)
            {
                that.uploadSrc = that.uploadFile.objectURL;
                that.hasFile = true;
                that.imagesSelected.emit(files);
                return;
            }

            // Need to rotate it
            loadImage(that.uploadFile, function (canvas)
                {
                    const dataUrl = canvas.toDataURL(that.uploadFile.type);
                    ImageManagerService.resetOrientation(dataUrl, orientation, function (fixedDataUrl)
                    {
                        that.uploadSrc = fixedDataUrl;
                        that.hasFile = true;
                        that.imagesSelected.emit(files);
                    });
                },
                {
                    canvas: true
                });
        });
    }

    onChooseExistingPDF(): void
    {
        this.isSelectPdfVisible = true;
    }

    onPdfSelected(pdf: PdfAsset)
    {
        this.isSelectPdfVisible = false;
        this.updatePdf(pdf);
        this.pdfUrlSafe = null; // Not displayable yet
        this.documentSelected.emit(pdf.Url);
    }
}
