import { ImageAsset } from '../../../shared-api/images/image-asset-model';
import { MemberInfo } from './member-info.model';
import { MemberBasicEditModel } from './member-edit.model';

export class Member
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            // Custom mappings
            if (json['Photo'] != null) this.Photo = new ImageAsset(json['Photo']);
            if (json['MemberInfo'] != null) this.MemberInfo = new MemberInfo(json['MemberInfo']);
            return;
        }

        this.MemberInfo = new MemberInfo();
    }

    Id: number;
    FirstName: string;
    LastName: string;
    PhoneNumber: string;
    IsRegistered: boolean;
    Email: string;
    UserId: string;
    Photo: ImageAsset;
    ProfessionalInfo: any;
    MemberInfo: MemberInfo;
    MembershipTypeId: number; // Note: this is in MemberInfo when full details are loaded
    CategoryIds: number[];  // Note: this is listed in CategoryItems in MemberInfo when full details are loaded
    NeedsSmsReplyFromOrg = false;

    get hasPhoneNumber(): boolean { return this.PhoneNumber != null && this.PhoneNumber.length > 0}
}
