import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

import { ApiService, HttpMethod } from '../../../shared-api/common/api.service';
import { ApiUrlService } from '../../../shared-api/common/api-url.service';
import { ItemsCollection } from '../../../shared-api/common/items-collection.model';
import { MyApp } from '../../../shared-api/common/enumerations';
import { ImageAsset } from '../../../shared-api/images/image-asset-model';
import { Member } from './member.model';
import { MemberBasicEditModel, MemberFullEditModel } from './member-edit.model';
import { MemberUploadResult } from './member-upload-result.model';
import { MemberCountWithCategoriesResult } from './member-count-with-categories-result.model';
import { TextMessage } from './text-message.model';
import { NewsItem } from '../../news/api/news-item.model';

@Injectable({
    providedIn: 'root'
})
export class MemberApiService
{
    protected apiService: ApiService;

    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        this.apiService = new ApiService(http);
    }

    getMembers(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = ApiService.defaultPageSize): Observable<ItemsCollection<Member>>
    {
        return this.apiService.apiRequest(this.apiUrlService.getMembersUrl(sortField, sortOrder, pageIndex, pageSize), HttpMethod.Get)
            .pipe(
                map((membersCollection: any) => // Really a collection with a count and a Members array
                    {
                        const members: Member[] = [];

                        membersCollection.Members.forEach(item =>
                        {
                            members.push(new Member(item));
                        });

                        return new ItemsCollection<Member>(membersCollection.Count, members);
                    }
                )
            );
    }

    getMember(id: number): Observable<Member>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.getMemberUrl(id), HttpMethod.Get)
            .pipe(
                map(json => new Member(json))
            );
    }

    createMember(model: MemberFullEditModel): Observable<Member>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createMemberUrl(), HttpMethod.Post, JSON.stringify(model))
            .pipe(
                map(json => new Member(json))
            );
    }

    updateMember(model: MemberFullEditModel): Observable<Member>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateMemberFullMemberInfoUrl(model.Id), HttpMethod.Put, JSON.stringify(model))
            .pipe(
                map(json => new Member(json))
            );
    }

    updateMemberBasic(model: MemberBasicEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateBasicMemberInfoUrl(model.Id), HttpMethod.Put, JSON.stringify(model));
    }

    deleteMember(member: Member)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.deleteMemberUrl(member.Id), HttpMethod.Delete, null);
    }

    deleteMembers(memberIds: number[])
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.deleteMembersUrl, HttpMethod.Delete, JSON.stringify({ MemberIds: memberIds }));
    }

    setImageForMember(id: number, url: string): Observable<ImageAsset>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.setOrDeleteMemberImageUrl(id), HttpMethod.Post, '{ "Url": "' + url + '"}')
        .pipe(
            map((photo: ImageAsset) => new ImageAsset(photo))
        );
    }

    deleteImageForMember(id: number)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.setOrDeleteMemberImageUrl(id), HttpMethod.Delete, null);
    }

    uploadMemberList(file: File, overwriteExistingMembers: boolean): Observable<MemberUploadResult>
    {
        const formData = new FormData();
        formData.append('0', file, file.name);
        return this.apiService.apiUploadRequest<any>(this.apiUrlService.uploadMemberListUrl(overwriteExistingMembers), formData, 600)
            .pipe(map(result => new MemberUploadResult(result)));
    }

    downloadMemberList(): Observable<Response>
    {
        return this.apiService.apiGetRequestForCsv(this.apiUrlService.downloadMemberListUrl);
    }

    getMemberCountForCategories(categoriesInGroups: any): Observable<MemberCountWithCategoriesResult>
    {
        const params = { filter: categoriesInGroups };
        return this.apiService.apiRequest<any>(this.apiUrlService.getMemberCountForCategoriesUrl(), HttpMethod.Put, JSON.stringify(params))
            .pipe(
                map(json => new MemberCountWithCategoriesResult(json))
            );
    }

    getConversation(id: number): Observable<TextMessage[]>
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.getConversationUrl(id), HttpMethod.Get)
            .pipe(map(list => list.map(json => new TextMessage(json))));
    }

    sendSmsToMember(memberId: number, message: string): Observable<TextMessage>
    {
        const json = {
            memberId: memberId,
            message: message,
        };
        return this.apiService.apiRequest<any>(this.apiUrlService.sendSmsToMemberUrl, HttpMethod.Post, JSON.stringify(json));
    }
}
