
import { MyApp } from '../../../shared-api/common/enumerations';

export class TextMessage
{
    TextMessageType: MyApp.TextMessageType;
    Body: string;
    Date: Date;

    constructor(json: object = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
