import { ImageAsset } from '../images/image-asset-model';
import { CategoryItem} from './category-item.model';
import { Location } from '../location/location.model';
import { MembershipType } from './membership-type-item.model';
import { MyApp } from '../common/enumerations';
import SocialMediaAccountType = MyApp.SocialMediaAccountType;
import { OrganizationEditModel } from '../../sections/organization-info/api/organization-edit.model';
import { Theme } from '../theme/theme.model';
import { AppTeamUtil } from '../../core/app-team-util';

export class SocialMediaAccount
{
    Type: SocialMediaAccountType;
    AccountInfo: string;

    constructor(json: object = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}

export class TimeZone
{
    Id: string;
    DisplayName: string;

    constructor(json: object = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }

    get OffsetAndName(): string
    {
        return this.DisplayName.length > 12 || this.DisplayName === 'UTC' || this.DisplayName === 'GMT' ?  this.DisplayName : `(${this.DisplayName}) ${this.Id}`;
    }
}

export class OrganizationLink
{
    Id: number;
    Name: string;
    Url: string;
    CategoryIds: number[] = [];

    constructor(json: object = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}

export class Organization
{
    CategoryItems: CategoryItem[];
    DefaultMemberType: string;
    EmailFrom: string;
    FeatureJson: string;
    Id: number;
    IsFaceRecognitionEnabled = false;
    IsMMSEnabled = false;
    IsDocumentCenterEnabled = false;
    IsTwoWaySmsEnabled = false;
    Links: OrganizationLink[] = [];
    Locations: Location[];
    Logo: ImageAsset;
    LongDescription: string;
    MaxMembers = -1;
    MaxPhotoCount: number;
    MembershipTypes: MembershipType[];
    Name: string;
    OptedOutForSignupConfirmationEmail = false;
    Photo: ImageAsset;
    AuthorizationCode: string;
    ShortDescription: string;
    SmsYearlyLimit = -1;
    SocialMediaAccounts: SocialMediaAccount[] = [];
    TagLine: string;
    Theme: Theme;
    TimeZoneId: string;
    WebSiteUrl: string;
    WelcomeImage: ImageAsset;

    constructor(json: any = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
            if (json.Logo) this.Logo = new ImageAsset(json.Logo);
            if (json.Photo) this.Photo = new ImageAsset(json.Photo);
            if (json.WelcomeImage) this.WelcomeImage = new ImageAsset(json.WelcomeImage);

            // Handle any custom mappings here
            if (json['CategoryItems'])
            {
                this.CategoryItems = [];
                json['CategoryItems'].forEach(item => {
                    this.CategoryItems.push(new CategoryItem(item));
                });
            }
            if (json['MembershipTypes'])
            {
                this.MembershipTypes = [];
                json['MembershipTypes'].forEach(item => {
                    this.MembershipTypes.push(new MembershipType(item));
                });
            }
            if (json['Locations'])
            {
                this.Locations = [];
                json['Locations'].forEach(item => {
                    this.Locations.push(new Location(item));
                });
            }
            if (json['SocialMediaAccounts'])
            {
                this.SocialMediaAccounts = [];
                json['SocialMediaAccounts'].forEach(item => {
                    this.SocialMediaAccounts.push(new SocialMediaAccount(item));
                });
            }
            if (json['Links'])
            {
                this.Links = [];
                json['Links'].forEach(linksJson => this.Links.push(new OrganizationLink(linksJson)));
            }
            if (json['Theme'] != null) { this.Theme = new Theme(json['Theme']); }
        }
    }

    updateFromModel(model: OrganizationEditModel)
    {
        this.Name = model.Name;
        this.WebSiteUrl = model.WebSiteUrl;
        this.EmailFrom = model.EmailFrom;
        this.ShortDescription = model.ShortDescription;
        this.LongDescription = model.LongDescription;
        this.TagLine = model.TagLine;
        this.TimeZoneId = model.TimeZoneId;
        this.SocialMediaAccounts = model.SocialMediaAccounts;
        this.Links = model.Links;
    }

    getCategoryWithMemberTypes(categoryId: number)
    {
        const category = this.CategoryItems.find(ci => ci.Id === categoryId);
        if (category.MemberTypeIds.length > 0) { return category; }
        category.MemberTypeIds = this.MembershipTypes.filter(mt => mt.CategoryItems.find(mtCi => mtCi.Id === category.Id) != null).map(mt => mt.Id);
        return category;
    }

    getCategoriesWithMemberTypes(): CategoryItem[]
    {
        const that = this;
        return this.CategoryItems.map(category =>
        {
            if (category.MemberTypeIds.length > 0) { return category; }
            category.MemberTypeIds = that.MembershipTypes.filter(mt => mt.CategoryItems.find(mtCat => mtCat.Id === category.Id) != null).map(mt => mt.Id);
            return category;
        });
    }

    getCategoryTypesForMembershipType(membershipTypeId: number): CategoryItem[]
    {
        const membershipType = this.MembershipTypes.find(mt => mt.Id === membershipTypeId);
        return membershipType == null ? [] : membershipType.CategoryItems;
    }

    getCategoryNamesList(categories: CategoryItem[]): string
    {
        if (categories == null) { return ''; }
        let result = '';
        const groupedCategories = AppTeamUtil.groupBy(categories, ci => ci.CategoryItemType);
        for (const categoryType of groupedCategories.keys())
        {
            const categoriesInGroup = groupedCategories.get(categoryType);
            if (categoriesInGroup.length === this.CategoryItems.filter(cat => cat.CategoryItemType === categoryType).length)
            {
                let categoryName = MyApp.getCategoryItemTypeString(categoryType);
                categoryName += categoryName.endsWith('s') ? 'es' : 's';
                result += (result.length === 0 ? '' : ', ') + `All ${categoryName}`;
            }
            else
            {
                result += (result.length === 0 ? '' : ', ') + categoriesInGroup.map(cat => cat.Name).join(', ');
            }
        }

        return result;
    }
}
