import { Injectable } from '@angular/core';
import { ApiService, HttpMethod } from '../../../shared-api/common/api.service';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '../../../shared-api/common/api-url.service';
import { ItemsCollection } from '../../../shared-api/common/items-collection.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { ThemeEditModel } from './theme-edit.model';
import { Theme } from '../../../shared-api/theme/theme.model';

@Injectable({
    providedIn: 'root'
})
export class ThemeApiService
{
    protected apiService: ApiService;

    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        this.apiService = new ApiService(http);
    }

    getThemes(): Observable<ItemsCollection<Theme>>
    {
        return this.apiService.apiRequest(this.apiUrlService.getThemesUrl(), HttpMethod.Get)
            .pipe(
                map((themesResult: any[]) =>
                    {
                        const themes = themesResult.map(json => new Theme(json));
                        return new ItemsCollection<Theme>(themes.length, themes);
                    }
                )
            );
    }

    getTheme(id: number)
    {
        return this.apiService.apiRequest(this.apiUrlService.getThemeUrl(id), HttpMethod.Get)
            .pipe(
                map(json => new Theme(json))
            );
    }

    createTheme(model: ThemeEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createThemeUrl(), HttpMethod.Post, JSON.stringify(model))
            .pipe(
                map(json => new Theme(json))
            );
    }

    updateTheme(model: ThemeEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrDeleteThemeUrl(model.Id), HttpMethod.Put, JSON.stringify(model));
    }

    deleteTheme(theme: Theme)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrDeleteThemeUrl(theme.Id), HttpMethod.Delete, null);
    }
}
