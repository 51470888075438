import { Injectable } from '@angular/core';
import { UserLoginResult } from '../../shared-api/auth/user-login-result.model';
import { Organization } from '../../shared-api/organization/organization.model';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageManagerService
{
    private keys = {
        ApiEnvironment: 'ApiEnvironment',
        UserLoginResult: 'UserLoginResult',
        AccessToken: 'AccessToken',
        RefreshToken: 'RefreshToken',
        // UserId: 'UserId',
        RememberMe: 'RememberMe',
        UserName: 'UserName',
        CurrentRoute: 'CurrentRoute',
        ShowPhotoDetails: 'ShowPhotoDetails',

        CurrentOrganization: 'CurrentOrganization'
    };

    constructor() { }


    clearCacheOnSignout()
    {
        this.userLoginResult = null;
        this.accessToken = '';
        this.refreshToken = '';
        this.currentOrganization = null;

        // this.currentOrganization = null;
    }


    // =========================================================================================================================================================
    // Sign In
    // =========================================================================================================================================================
    get userName(): string
    {
        return localStorage[this.keys.UserName] as string;
    }

    set userName(emailAddress: string)
    {
        localStorage[this.keys.UserName] = emailAddress;
    }

    get rememberMe(): boolean
    {
        return localStorage[this.keys.RememberMe] === 'true';
    }

    set rememberMe(value: boolean)
    {
        localStorage[this.keys.RememberMe] = value;
    }

    get showPhotoDetails(): boolean
    {
        return localStorage[this.keys.ShowPhotoDetails] !== 'false';
    }

    set showPhotoDetails(value: boolean)
    {
        localStorage[this.keys.ShowPhotoDetails] = value;
    }

    // Access Token
    get userLoginResult(): UserLoginResult
    {
        const cachedLoginResult = localStorage[this.keys.UserLoginResult];
        return cachedLoginResult ? JSON.parse(cachedLoginResult) : null;
    }

    set userLoginResult(userLoginResult: UserLoginResult)
    {
        localStorage[this.keys.UserLoginResult] = userLoginResult ? JSON.stringify(userLoginResult) : '';
    }

    get accessToken(): string
    {
        const cachedToken: string = localStorage[this.keys.AccessToken] as string;
        return cachedToken ? cachedToken : '';
    }

    set accessToken(token: string)
    {
        localStorage[this.keys.AccessToken] = token;
    }

    // Access Token
    get refreshToken(): string
    {
        const cachedRefreshToken: string = localStorage[this.keys.RefreshToken] as string;
        return cachedRefreshToken ? cachedRefreshToken : '';
    }

    set refreshToken(token: string)
    {
        localStorage[this.keys.RefreshToken] = token;
    }

    // // User Id
    // get userId(): number
    // {
    //     return localStorage[this.keys.UserId] ? localStorage[this.keys.UserId] : null;
    // }
    //
    // set userId(userId: number)
    // {
    //     localStorage[this.keys.UserId] = userId;
    // }


    get currentOrganization(): Organization
    {
        const cachedOrganization = localStorage[this.keys.CurrentOrganization];
        return cachedOrganization ? JSON.parse(cachedOrganization) : null;
    }

    set currentOrganization(organization: Organization)
    {
        localStorage[this.keys.CurrentOrganization] = organization ? JSON.stringify(organization) : '';
    }

}
