export class UserLoginResult
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }

        // Handle any custom mappings here or use getters and setters. Object.assign will call
        // property setters.
    }

    public token: string;
    public expiration: string;
    public refreshToken: string;
    public refreshTokenExpiration: string;
}
