import { Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcastService } from '../../core/services/broadcast.service';
import { NavbarElement } from './models/navbar-element.model';
import { Constants } from '../../shared-api/common/constants';
import { MyApp } from '../../shared-api/common/enumerations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TenantManagerService } from '../../shared-api/tenant/tenant-manager.service';
import { Product } from '../../shared-api/tenant/product.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import ProductFeatureFlags = MyApp.ProductFeatureFlags;
import { AuthManagerService } from '../../shared-api/auth/auth-manager.service';
import { Router } from '@angular/router';
import { LoginGuardService } from '../../core/services/login-guard.service';

@Component({
    selector: 'myapp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnDestroy
{
    Constants = Constants;
    DocumentType = MyApp.DocumentType;

    navbarElements: NavbarElement[] = [];
    mobileNavbarElements: NavbarElement[] = [];
    isMobileFormFactor = false;
    tooltipPlacement = 'none';
    documentType = MyApp.DocumentType.None;
    displayDocument = false;
    documentName = '';
    showHamburgerMenu = false;


    constructor(private router: Router,
                private tenantManager: TenantManagerService,
                private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private deviceDetector: DeviceDetectorService) { }

    ngOnInit()
    {
        this.broadcastService.showHeader.next(true);
        // this.broadcastService.showLoadingOverlay.next(false);

        this.broadcastService.windowResize.pipe(untilDestroyed(this)).subscribe(sizes => this.setTooltipState(sizes.width));
        this.broadcastService.toggleHamburgerMenu.pipe(untilDestroyed(this)).subscribe(() => this.showHamburgerMenu = !this.showHamburgerMenu);

        this.broadcastService.organizationChanged.pipe(untilDestroyed(this)).subscribe(organization =>
        {
            this.setupNavigation();

            this.tenantManager.currentProduct
                .subscribe((product: Product) =>
                {
                    // Add/remove Sponsors tab based on whether it is supported in the Product for the organization.
                    const sponsorsIndex = this.navbarElements.findIndex(ne => ne.lookupName === 'sponsors');
                    // tslint:disable-next-line:no-bitwise
                    const sponsorsVisible = (product.FeatureFlags & ProductFeatureFlags.Sponsor) !== 0;
                    if (sponsorsIndex === -1 && sponsorsVisible)
                    {
                        const insertIndex = this.navbarElements.findIndex(ne => ne.lookupName === 'locations') + 1;
                        this.navbarElements.splice(insertIndex, 0, new NavbarElement('sponsors', 'Sponsors', 'fa fa-fw fa-star', '/dashboard/sponsors'));
                    }
                    else if (sponsorsIndex !== -1 && !sponsorsVisible)
                    {
                        this.navbarElements.splice(sponsorsIndex, 1);
                    }

                    // Hide/show tabs based on user type
                    if (!this.authManager.authenticatedUser.isGlobalAdmin)
                    {
                        const themesIndex = this.navbarElements.findIndex(ne => ne.lookupName === 'themes');
                        if (themesIndex !== -1) this.navbarElements.splice(themesIndex, 1);
                        const adminIndex = this.navbarElements.findIndex(ne => ne.lookupName === 'admin');
                        if (adminIndex !== -1) this.navbarElements.splice(adminIndex, 1);
                    }

                    // Update the labels based on the Product/Organization info
                    const membersNavbar = this.navbarElements.find(ne => ne.lookupName === 'members');
                    membersNavbar.displayName = product.getFeatureValue('MemberType') || 'Members';

                    const orgInfoNavbar = this.navbarElements.find(ne => ne.lookupName === 'organizationInfo');
                    orgInfoNavbar.displayName = (product.getFeatureValue('OrganizationType') || 'Organization') + ' Info';

                    /* tslint:disable:curly */
                    if (!organization.IsDocumentCenterEnabled)
                        this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'doc-center'), 1);

                    if (this.authManager.authenticatedUser.accessInfo)
                    {
                        if (this.authManager.authenticatedUser.accessInfo.News !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'news'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Events !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'events'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.DocumentCenter !== true && this.navbarElements.findIndex(ne => ne.lookupName === 'doc-center') !== -1)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'doc-center'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Notifications !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'notifications'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Members !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'members'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Photos !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'photos'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Contacts !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'contacts'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Categories !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'categories'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.OrganizationInfo !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'organizationInfo'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Locations !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'locations'), 1);
                        if (this.authManager.authenticatedUser.accessInfo.Sponsors !== true)
                            if (sponsorsIndex !== -1) this.navbarElements.splice(sponsorsIndex, 1);
                        if (this.authManager.authenticatedUser.accessInfo.Reports !== true)
                            this.navbarElements.splice(this.navbarElements.findIndex(ne => ne.lookupName === 'reports'), 1);
                    }
                    /* tslint:enable:curly */

                    if (this.router.url.endsWith('/dashboard'))
                    {
                        this.router.navigate([ '/dashboard/' + LoginGuardService.firstAvailableTab(this.authManager.authenticatedUser) ]);
                    }
                });
        });

        this.setupNavigation();
        this.setupMobileNavigation();
        this.setTooltipState(window.innerWidth);
        this.isMobileFormFactor = this.deviceDetector.isMobile();
    }

    ngOnDestroy()
    {
    }

    private setupNavigation()
    {
        this.navbarElements = [];
        this.navbarElements.push(new NavbarElement('news', 'News', 'icon-news', '/dashboard/news', true));
        this.navbarElements.push(new NavbarElement('events', 'Events', 'icon-event', '/dashboard/events', true));
        this.navbarElements.push(new NavbarElement('doc-center', 'Documents', 'far fa-fw fa-file', '/dashboard/doc-center', false));
        this.navbarElements.push(new NavbarElement('notifications', 'Notifications', 'icon-notification', '/dashboard/notifications', true));
        this.navbarElements.push(new NavbarElement('members', 'Members', 'icon-members', '/dashboard/members'));
        this.navbarElements.push(new NavbarElement('photos', 'Photos', 'icon-gallery', '/dashboard/photos'));
        this.navbarElements.push(new NavbarElement('contacts', 'Contacts', 'icon-contacts', '/dashboard/contacts'));
        this.navbarElements.push(new NavbarElement('categories', 'Categories', 'icon-hall_of_fame', '/dashboard/categories'));
        this.navbarElements.push(new NavbarElement('organizationInfo', 'Organization Info', 'icon-club_info', '/dashboard/organizationInfo'));
        this.navbarElements.push(new NavbarElement('locations', 'Locations', 'icon-location', '/dashboard/locations'));
        this.navbarElements.push(new NavbarElement('sponsors', 'Sponsors', 'fa fa-fw fa-star', '/dashboard/sponsors'));
        this.navbarElements.push(new NavbarElement('themes', 'Themes', 'fa fa-fw fa-tint', '/dashboard/themes'));
        this.navbarElements.push(new NavbarElement('admin', 'Admin', 'far fa-fw fa-id-badge', '/dashboard/admin', this.authManager.authenticatedUser.isGlobalAdmin));
        this.navbarElements.push(new NavbarElement('reports', 'Reports', 'far fa-fw fa-flag', '/dashboard/reports'));
    }

    private setupMobileNavigation()
    {
        this.mobileNavbarElements = this.navbarElements.filter(element => element.isOnMobileMenu);
        this.mobileNavbarElements.push(new NavbarElement('logout', 'Logout', 'icon-news', '/account/sign-in', true, {logout: true}));
    }

    private setTooltipState(windowWidth: number)
    {
        this.tooltipPlacement = windowWidth <= Constants.MinWidthForFullNavbar ? 'right' : 'none';
    }

    onShowDocument(docType: MyApp.DocumentType)
    {
        switch (docType)
        {
            case MyApp.DocumentType.PrivacyPolicy:
                this.documentName = 'Privacy Policy';
                break;

            case MyApp.DocumentType.TermsOfService:
                this.documentName = 'Terms and Conditions';
                break;

            case MyApp.DocumentType.FAQ:
                this.documentName = 'FAQ';
                break;

            default:
                this.documentName = '---';
                break;
        }

        this.documentType = docType;
        this.displayDocument = !this.displayDocument;
    }
}
