import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { CoreUiModule } from './core-ui/core-ui.module';
import { SharedApiModule } from './shared-api/shared-api-module';
import { SharedUiModule } from './shared-ui/shared-ui-module';

import { AppComponent } from './app.component';

import { StartComponent } from './start/start.component';
import { HeaderComponent } from './header/header.component';
import { LoadingComponent } from './loading/loading.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';

import { GrowlModule } from 'primeng/growl';
import { ConfirmDialogModule, DropdownModule, MessageService, TreeDragDropService } from 'primeng/primeng';
import { MenubarModule } from 'primeng/menubar';
import { ConfirmationService} from 'primeng/primeng';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        CoreUiModule,
        SharedApiModule,
        SharedUiModule,
        GrowlModule,
        ConfirmDialogModule,
        DropdownModule,
        MenubarModule,
    ],
    declarations: [
        AppComponent,
        StartComponent,
        HeaderComponent,
        LoadingComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ConfirmationService,
        MessageService,
        TreeDragDropService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
