export abstract class Constants
{
    static readonly AppName = 'MyApp';

    static readonly Copyright = '© 1218 Team 2018';
    static readonly AllRightsReserved = 'All rights reserved';

    static readonly MobileWidth = 430;
    static readonly MinWidthForFullNavbar = 700;
    static readonly MinWidthForDualPaneEdit = 1400;

    static readonly defaultDataPageSize = 1000;

    static readonly MyAppLogoUrl = '../../../assets/images/myappicon-white.png';
    static readonly MyConnectedAppLogoUrl = '../../../assets/images/logo-myconnectedapp.png';
    static readonly MyClubAppLogoUrl = '../../../assets/images/logo-myclubapp.png';
    static readonly MyCampAppLogoUrl = '../../../assets/images/logo-mycampapp.png';
    static readonly MissingImageUrl = '../../../assets/images/noImageAvailable.png';

}
