import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CategoryItem } from '../../shared-api/organization/category-item.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Organization } from '../../shared-api/organization/organization.model';
import { AppTeamUtil } from '../../core/app-team-util';
import { MyApp } from '../../shared-api/common/enumerations';
import { ThemeManagerService } from '../../sections/themes/api/theme-manager.service';
import { MemberManagerService } from '../../sections/members/api/member-manager.service';
import { MemberCountWithCategoriesResult } from '../../sections/members/api/member-count-with-categories-result.model';
import { Dialog } from 'primeng/dialog';
import CategoryItemType = MyApp.CategoryItemType;

@Component({
    selector: 'myapp-categories-select-dialog',
    templateUrl: './categories-select-dialog.component.html',
    styleUrls: [ './categories-select-dialog.component.less' ]
})
export class CategoriesSelectDialogComponent implements OnInit
{
    @ViewChild('dialog', { static: false }) dialog: Dialog;

    @Input() organization: Organization;
    @Input() selectedCategories: CategoryItem[] = [];

    @Output() categoriesSelected = new EventEmitter<number []>();

    chartOptions: any;
    counts: MemberCountWithCategoriesResult;
    usedCategoryTypes: any[] = []; // { categoryType, CategoryItem[] }
    allCategoryTypes: Array<CategoryItem[]> = [];
    // allCategoryTypes: Map<MyApp.CategoryItemType, CategoryItem[]>;
    selectedCategoriesByType: Map<MyApp.CategoryItemType, CategoryItem[]>;
    progressData: any;
    visible = true;

    Math = Math;
    MyApp = MyApp;

    constructor(private themeManager: ThemeManagerService,
                public deviceDetector: DeviceDetectorService,
                private memberManager: MemberManagerService) { }

    ngOnInit()
    {
        const groupedCategoryItems = AppTeamUtil.groupBy(this.organization.CategoryItems
            .sort((c1, c2) => MyApp.getCategoryItemTypeString(c1.CategoryItemType).localeCompare(MyApp.getCategoryItemTypeString(c2.CategoryItemType))),
                ci => ci.CategoryItemType);

        this.selectedCategoriesByType = new Map<MyApp.CategoryItemType, CategoryItem[]>();
        for (const categoryType of groupedCategoryItems.keys())
        {
            this.allCategoryTypes.push(groupedCategoryItems.get(categoryType));
            const selectedCategoriesForType = this.selectedCategories.filter(cat => cat.CategoryItemType === categoryType);
            this.selectedCategoriesByType.set(categoryType, selectedCategoriesForType);
            if (selectedCategoriesForType.length > 0) { this.showCategory(categoryType); }
        }
        this.allCategoryTypes.sort((cat1, cat2) => MyApp.isCategoryItemTypeSortedBefore(cat1[0].CategoryItemType, cat2[0].CategoryItemType));

        this.chartOptions = {
            cutoutPercentage: 80,
            legend: { display: false },
        };

        this.updateUserCount();
    }

    private get selectedCategoryIds(): number[]
    {
        let selectedCategories = [];
        for (const categoryType of this.selectedCategoriesByType.keys())
        {
            selectedCategories = selectedCategories.concat(this.selectedCategoriesByType.get(categoryType));
        }
        return selectedCategories.map(cat => cat.Id);
    }

    getSelectedCategoriesByType(categoryType: MyApp.CategoryItemType)
    {
        return this.selectedCategoriesByType.get(categoryType);
    }

    isCategoryUsed(categoryType: MyApp.CategoryItemType)
    {
        return this.usedCategoryTypes.some(x => x.categoryType === categoryType);
    }

    onOk()
    {
        this.categoriesSelected.emit(this.selectedCategoryIds); // this.selectedAlbumNode == null ? null : this.selectedAlbumNode.data);
    }

    onHide()
    {
        this.categoriesSelected.emit(null);
    }

    onCategoryTypeClicked(type: CategoryItemType)
    {
        this.showCategory(type);
        setTimeout(() => this.dialog.onWindowResize(), 100);
    }

    onCategoriesChanged(type: CategoryItemType, event: any)
    {
        const { value } = event;
        this.selectedCategoriesByType.set(type, value);
        this.updateUserCount();
        setTimeout(() => this.dialog.onWindowResize(), 100);
    }

    onDeleteSelectedCategory(selectedCategory: CategoryItem)
    {
        this.selectedCategoriesByType.set(selectedCategory.CategoryItemType,
            this.selectedCategoriesByType.get(selectedCategory.CategoryItemType).filter(cat => cat.Id !== selectedCategory.Id));
        this.updateUserCount();
    }

    private updateUserCount()
    {
        this.memberManager.getMemberCountForCategories(this.selectedCategoryIds).subscribe(result =>
        {
            this.counts = result;
            this.progressData = {
                labels: [ 'Selected', 'Not Selected' ],
                datasets: [ {
                    data: [ result.MembersMatchingCategories, result.TotalMembers - result.MembersMatchingCategories ],
                    backgroundColor: [ this.themeManager.NEUTRAL_THEME_DARK_BACKGROUND_COLOR, this.themeManager.NEUTRAL_THEME_LIGHT_BACKGROUND_COLOR ],
                } ]
            };
        });
    }

    private showCategory(type: MyApp.CategoryItemType)
    {
        const categories = this.allCategoryTypes.find(list => list[0].CategoryItemType === type);
        this.usedCategoryTypes.push({ categoryType: type, categories: categories });
    }

    getCategoryItemTypeIcon(type: MyApp.CategoryItemType)
    {
        switch (type)
        {
            case MyApp.CategoryItemType.Bus: return 'fas fa-bus-alt';
            case MyApp.CategoryItemType.Group: return 'fas fa-user-friends';
            case MyApp.CategoryItemType.Other: return 'fas fa-tags';
            case MyApp.CategoryItemType.Session: return 'fas fa-calendar-week';
            case MyApp.CategoryItemType.Location: return 'fas fa-map-marked-alt';
        }
    }
}
