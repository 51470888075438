import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../../../shared-api/common/service-manager-base';
import { BroadcastService } from '../../../core/services/broadcast.service';
import { MyApp } from '../../../shared-api/common/enumerations';
import { CachedCollection } from '../../../shared-api/common/cached-collection.model';
import { Observable, of } from 'rxjs';
import { MemberApiService } from './member-api.service';
import { map, tap } from 'rxjs/internal/operators';
import { ItemsCollection } from '../../../shared-api/common/items-collection.model';
import { Member } from './member.model';
import { MemberBasicEditModel, MemberFullEditModel } from './member-edit.model';
import { ImageAsset } from '../../../shared-api/images/image-asset-model';
import { MemberCountWithCategoriesResult } from './member-count-with-categories-result.model';
import { TextMessage } from './text-message.model';


@Injectable({
    providedIn: 'root'
})
export class MemberManagerService extends ServiceManagerBase
{
    // Cache Containers
    private _membersCollection: CachedCollection;


    constructor(private memberApiService: MemberApiService,
                protected broadcastService: BroadcastService)
    {
        super(broadcastService);
    }

    clearCache()
    {
        if (this.retrievingData) return;
        this._membersCollection = null;
    }

    getMembers(sortField?: string, sortOrder?: MyApp.SortOrder, pageIndex = 0, pageSize = 10000, bypassCache = false): Observable<ItemsCollection<Member>>
    {
        if (!bypassCache && this._membersCollection && !this._membersCollection.isExpired)
        {
            return of(this._membersCollection.collection);
        }

        this.retrievingData = true;
        return this.memberApiService.getMembers(sortField, sortOrder, pageIndex, pageSize).pipe(tap(response =>
        {
            this._membersCollection = new CachedCollection(response);
            this.retrievingData = false;
        }));
    }

    getMember(memberId: number): Observable<Member>
    {
        return this.memberApiService.getMember(memberId);
    }

    saveMember(memberEditModel: MemberFullEditModel): Observable<Member>
    {
        if (memberEditModel.Id === 0)
        {
            return this.memberApiService.createMember(memberEditModel)
                .pipe(
                    tap(createdMember =>
                    {
                        if (this._membersCollection == null)
                        {
                            this._membersCollection = new CachedCollection(new ItemsCollection<Member>(0, []));
                        }
                        memberEditModel.Id = createdMember.Id;
                        this._membersCollection.addItem(this.updateListMemberWithFullMember(createdMember));
                    })
                );
        }

        return this.memberApiService.updateMember(memberEditModel)
            .pipe(
                map(updatedMember =>
                {
                    const item: Member = this._membersCollection.collection.Items.find(member => member.Id === memberEditModel.Id);
                    Object.assign(item, this.updateListMemberWithFullMember(updatedMember));
                    return item;
                })
            );
    }

    deleteMember(member: Member): Observable<any>
    {
        return this.memberApiService.deleteMember(member)
            .pipe(tap(() =>
                {
                    if (this._membersCollection == null) return;
                    this._membersCollection.removeItem(this._membersCollection.collection.Items.find(m => m.Id === member.Id));
                })
            );
    }

    deleteMembers(memberIds: number[]): Observable<any>
    {
        return this.memberApiService.deleteMembers(memberIds)
            .pipe(tap(() =>
                {
                    if (this._membersCollection == null) return;

                    memberIds.forEach(id => this._membersCollection.removeItem(this._membersCollection.collection.Items.find(m => m.Id === id)));
                })
            );
    }

    setImageForMember(memberEditModel: MemberBasicEditModel, previousPhoto: ImageAsset, uploadedImageUrl: string): Observable<ImageAsset>
    {
        // See if the image is being removed
        if (previousPhoto != null && memberEditModel.PhotoId === -1)
        {
            return this.memberApiService.deleteImageForMember(memberEditModel.Id)
                .pipe(
                    tap( () =>
                    {
                        if (this._membersCollection != null)
                        {
                            const item = this._membersCollection.collection.Items.find(member => member.Id === memberEditModel.Id);
                            item.Photo = null;
                        }
                    })
                );
        }

        // Possibly adding image
        if (uploadedImageUrl == null) return of(null);
        return this.memberApiService.setImageForMember(memberEditModel.Id, uploadedImageUrl)
            .pipe(
                tap( (photo: ImageAsset) =>
                {
                    if (this._membersCollection != null)
                    {
                        const item = this._membersCollection.collection.Items.find(member => member.Id === memberEditModel.Id);
                        item.Photo = photo;
                    }
                })
            );
    }

    uploadMemberList(file: File, overwriteExistingMembers: boolean)
    {
        return this.memberApiService.uploadMemberList(file, overwriteExistingMembers);
    }

    downloadMemberList(): Observable<any>
    {
        return this.memberApiService.downloadMemberList();
    }

    getMemberCountForCategories(categoriesInGroups: any): Observable<MemberCountWithCategoriesResult>
    {
        return this.memberApiService.getMemberCountForCategories(categoriesInGroups);
    }

    getConversation(memberId: number): Observable<TextMessage[]>
    {
        return this.memberApiService.getConversation(memberId);
    }

    sendSmsToMember(memberId: number, message: string): Observable<TextMessage>
    {
        return this.memberApiService.sendSmsToMember(memberId, message).pipe(map(json => new TextMessage(json)));
    }

    private updateListMemberWithFullMember(member: Member)
    {
        const listMember = {...member};
        listMember.MembershipTypeId = listMember.MemberInfo.MembershipTypeId;
        listMember.CategoryIds = listMember.MemberInfo.CategoryItems.map(ci => ci.Id);
        return listMember;
    }
}
