export class LocationHour
{
    constructor(json: object = null)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);
        }
    }

    DayText: string;
    TimeText: string;
}
