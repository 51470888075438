import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggingService
{

    constructor() { }

    static log(message: any, callerInfo?: object)
    {
        const callerName = callerInfo ? callerInfo.constructor.name : null;

        if (typeof message === 'string')
        {
            console.log(`${callerName ? '[' + callerName + ']: ' : ''}${message}`);
        }
        else
        {
            if (callerInfo)
            {
                console.log(`[${callerName}]: `);
            }
            console.log(message);
        }
    }

    static error(message: any, callerInfo?: object)
    {
        const callerName = callerInfo ? callerInfo.constructor.name : null;

        if (typeof message === 'string')
        {
            console.error(`${callerName ? '[' + callerName + ']: ' : ''}${message}`);
        }
        else
        {
            if (callerInfo)
            {
                console.error(`[${callerName}]: `);
            }
            console.error(message);
        }
    }

}
