import { Injectable } from '@angular/core';
import { ApiService, HttpMethod } from '../common/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

import { Album } from './album.model';
import { AlbumEditModel } from './album-edit.model';
import { ApiUrlService } from '../common/api-url.service';
import { ImageAsset } from '../images/image-asset-model';
import { MyApp } from '../common/enumerations';
import { UploadFileResult } from '../images/image-manager.service';

@Injectable({
    providedIn: 'root'
})
export class AlbumApiService
{
    protected apiService: ApiService;

    constructor(protected http: HttpClient,
                protected apiUrlService: ApiUrlService)
    {
        this.apiService = new ApiService(http);
    }

    getAlbum(id: number): Observable<Album>
    {
        return this.apiService.apiRequest(this.apiUrlService.getAlbumsUrl(id), HttpMethod.Get)
            .pipe(
                map((album: Album) => new Album(album))
            );
    }

    createAlbum(model: AlbumEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.createAlbumUrl(), HttpMethod.Post, JSON.stringify(model))
            .pipe(
                map(json => new Album(json) )
            );
    }

    updateAlbum(model: AlbumEditModel)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrDeleteAlbumUrl(model.Id), HttpMethod.Put, JSON.stringify(model))
            .pipe(map(json => new Album(json)));
    }

    moveAlbum(fromParentId: number, fromChildAlbumIds: number[], toParentId: number, toChildAlbumIds: number[])
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.moveAlbumUrl(), HttpMethod.Put, JSON.stringify({
            FromParentId: fromParentId,
            FromParentChildAlbumIds: fromChildAlbumIds,
            ToParentId: toParentId,
            ToParentChildAlbumIds: toChildAlbumIds,
        }));
    }

    addMediaToAlbum(id: number, type: MyApp.AssetType, uploadResult: UploadFileResult)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.addMediaToAlbumUrl(id), HttpMethod.Post, JSON.stringify({
            AssetType: type,
            Url: uploadResult.fileOrUrl,
            width: uploadResult.width,
            height: uploadResult.height
        }))
            .pipe(
                map((photo: ImageAsset) => new ImageAsset(photo))
            );
    }

    deleteMediaFromAlbum(id: number, imageAssetIds: number[])
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.deleteMediaFromAlbumUrl(id), HttpMethod.Delete, JSON.stringify({
            ImageAssetIds: imageAssetIds
        }));
    }

    moveMediaToAlbum(fromId: number, toId: number, imageAssetIds: number[])
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.moveMediaTolbumUrl(), HttpMethod.Put, JSON.stringify({
            FromAlbumId: fromId,
            ToAlbumId: toId,
            ImageAssetIds: imageAssetIds
        }));
    }

    deleteAlbum(album: Album)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.updateOrDeleteAlbumUrl(album.Id), HttpMethod.Delete, null);
    }

    setImageForAlbum(id: number, url: string)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.setOrDeleteAlbumImageUrl(id), HttpMethod.Post, '{ "Url": "' + url + '"}')
        .pipe(
            map((photo: ImageAsset) => new ImageAsset(photo))
        );
    }

    deleteImageForAlbum(id: number)
    {
        return this.apiService.apiRequest<any>(this.apiUrlService.setOrDeleteAlbumImageUrl(id), HttpMethod.Delete, null);
    }
}
