import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BroadcastService } from '../../core/services/broadcast.service';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticatedUser } from './authenticated-user.model';
import { AuthApiService } from './auth-api.service';
import { LocalStorageManagerService } from '../../core/managers/local-storage-manager.service';
import { UserRegistrationInfo } from './user-registration-info.model';

@Injectable({
    providedIn: 'root'
})
export class AuthManagerService
{
    private _authenticatedUser: AuthenticatedUser;
    public redirectUrl = '/dashboard';

    constructor(private authApiService: AuthApiService,
                private router: Router,
                private broadcastService: BroadcastService,
                private localStorageManager: LocalStorageManagerService)
    {
        // Create the authenticatedUser from the last userLoginResult if it exists (i.e., the user has not logged out).
        if (this.localStorageManager.userLoginResult && this.localStorageManager.rememberMe)
        {
            this.authenticatedUser = new AuthenticatedUser(this.localStorageManager.userLoginResult);
        }
    }

    get authenticatedUser(): AuthenticatedUser
    {
        return this._authenticatedUser;
    }

    set authenticatedUser(value: AuthenticatedUser)
    {
        this._authenticatedUser = value;
    }

    signIn(userName: string, password: string): Observable<AuthenticatedUser>
    {
        return this.authApiService.signIn(userName, password)
            .pipe(
                tap(authenticatedUser =>
                    {
                        this.authenticatedUser = authenticatedUser;
                        this.localStorageManager.accessToken = authenticatedUser.token;
                        this.localStorageManager.refreshToken = authenticatedUser.refreshToken;
                    })
            );
    }

    signInWithRefreshToken(organizationId?: number)
    {
        const orgId = organizationId == null && this.authenticatedUser.organizationId != null ? +this.authenticatedUser.organizationId : organizationId;
        if (!this.localStorageManager.refreshToken || orgId == null)
        {
            return throwError(new HttpErrorResponse({status: 400, statusText: 'no refresh token found'}));
        }

        return this.authApiService.signInWithRefreshToken(this.localStorageManager.refreshToken, orgId)
            .pipe(
                tap((authenticatedUser: AuthenticatedUser) =>
                {
                    this.authenticatedUser = authenticatedUser;
                    this.localStorageManager.accessToken = authenticatedUser.token;
                    this.localStorageManager.refreshToken = authenticatedUser.refreshToken;
                })
            );
    }

    signOut()
    {
        this.doSignOut();
    }

    private doSignOut()
    {
        this.localStorageManager.clearCacheOnSignout();

        this.redirectUrl = '/dashboard';

        this.broadcastService.userSignedOut.next(this.authenticatedUser.sid);
        this.authenticatedUser = null;

        this.router.navigate(['/account/sign-in']);
    }

    changePassword(oldPassword: string, newPassword: string)
    {
        return this.authApiService.changePassword(oldPassword, newPassword);
    }

    forgotPassword(userName: string)
    {
        return this.authApiService.forgotPassword(userName);
    }

    resetPassword(userId: string, token: string, password: string)
    {
        return this.authApiService.resetPassword(userId, token, password);
    }

    registerNewUser(userRegistrationInfo: UserRegistrationInfo)
    {
        return this.authApiService.registerNewUser(userRegistrationInfo);
    }

    confirmEmail(confirmInfo: { userId: string, token: string })
    {
        return this.authApiService.confirmEmail(confirmInfo);
    }

    confirmUserEmail(email: string): Observable<any>
    {
        return this.authApiService.confirmUserEmail(email);
    }

    setUserPassword(email: string, password: string): Observable<any>
    {
        return this.authApiService.setUserPassword(email, password);
    }
}
